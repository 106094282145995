import React, { Component } from "react";
import * as Icon from "react-feather";
import posed from "react-pose";
import { withTranslation, Trans } from "react-i18next";

import UtilityService from "../../../../services/UtilityService";
import ChatbotService from "../../../../services/ChatbotService";
import { KEYS } from "../../../../constants/Messenger";
import Alert from "../../../../components/common/Alert";

import "./chatbots.css";

const Box = posed.div({
	visible: {
		y: 40,
		opacity: 1
	},
	hidden: {
		y: 600,
		opacity: 0
	}
});

class Chatbots extends Component {
	constructor(props) {
		super(props);

		let { locationId, convo } = this.props;

		this.state = {
			locationId: locationId,
			convo: convo,
			chatbotsModal: null,
			chatbots: [],
			show: false,
			searchInput: "",
			selectedIndex: 0,
			showChatbotInitiateAlert: false,
			chatbotInitiateAlertText: ""
		};

		this.searchInput = null;
		this.current = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		this.resetComponent();
		document.addEventListener("keydown", this.onKey);
		document.addEventListener("mousedown", this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKey);
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = e => {
		if (this.chatbotsModal && this.chatbotsModal.contains(e.target)) {
			// the click is in the component
			return;
		}
		this.onClose();
	};

	onKey = e => {
		if (e.keyCode === KEYS.esc) {
			e.preventDefault();
			this.onClose();
		}
	};

	onChatbotSelected = async chatbot => {
		let { t } = this.props;

		await this.update({
			selectedChatbotId: chatbot.id,
			showChatbotInitiateAlert: true,
			chatbotInitiateAlertText: t("Click 'Yes' to initiate the chatbot {{chatbotName}} or 'No' to go back", { chatbotName: chatbot.name })
		});
	};

	async initiateChatbot() {
		try {
			let { convo, selectedChatbotId } = this.state;
			await ChatbotService.initiateConversation(convo.contact_id, selectedChatbotId);
			this.onClose();
		} catch (err) {
			console.error(err);
		}
	}

	componentDidUpdate(prevProps) {
		let { locationId, convo, show } = this.props;

		if (!prevProps.convo || prevProps.convo !== convo || prevProps.locationId !== locationId || prevProps.show !== show) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { locationId, convo, show } = this.props;

		await this.update({
			locationId: locationId,
			convo: convo,
			show: show,
			searchInput: ""
		});
		if (show) {
			await this.fetchChatbots();
		}
	}

	async fetchChatbots() {
		try {
			let chatbots = await ChatbotService.fetchActiveChatbots();
			await this.update({
				chatbots: chatbots
			});
		} catch (err) {
			console.log(err);
		}
	}

	onClose = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	render() {
		let { chatbots, show, showChatbotInitiateAlert, chatbotInitiateAlertText } = this.state;
		let { t } = this.props;

		let isNavigationCollapsed = UtilityService.isNavigationCollapsed();
		return (
			<div>
				<Box
					ref={ref => (this.chatbotsModal = ref)}
					className={`mb-chatbots ${isNavigationCollapsed ? "mb-chatbots--collapsed" : "mb-chatbots--expanded"}`}
					pose={show ? "visible" : "hidden"}
				>
					<div className="mb-chatbots-header">
						<div className="mb-chatbots-header-title">{t("Chatbots")}</div>
						<div className="mb-chatbots-header-x" onClick={this.onClose}>
							<Icon.X size="18" />
						</div>
					</div>
					<div className="mb-chatbots-list">
						{chatbots.length > 0 ? (
							chatbots.map(chatbot => {
								return (
									<div key={chatbot.id} className="mb-chatbots-list-item">
										<div id="mb-chatbots-list-item-content" onClick={() => this.onChatbotSelected(chatbot)}>
											<div className="mb-mentions-list-item-content-name">{chatbot.name}</div>
											<div
												id="mb-chatbots-list-item-content-text"
												style={{ overflow: "hidden", display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical" }}
												className={`mb-mentions-list-item-content-text`}
												title={chatbot.description}
											>
												{chatbot.description}
											</div>
										</div>
									</div>
								);
							})
						) : (
							<div className="mb-chatbots-no-items">{t("Please contact your DemandHub Representative for more information.")}</div>
						)}
					</div>
				</Box>
				<Alert
					type="warning"
					show={showChatbotInitiateAlert}
					title={t("Are you sure?")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={async confirmed => {
						await this.update({ showChatbotInitiateAlert: false });
						if (confirmed) {
							this.initiateChatbot();
						}
					}}
				>
					<div>{chatbotInitiateAlertText}</div>
				</Alert>
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(Chatbots);
