import React, { Component } from "react";
import UserService from "../../services/UserService";
import Kichiri from "../../services/KichiriService";
import TemplateService from "../../services/TemplateService";
import BooleanService from "../../services/BooleanService";
import GAService from "../../services/GAService";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import PreferenceService from "../../services/PreferenceService";
import StarSelection from "../../components/common/StarSelection";
import Alert from "../../components/common/Alert";
import withLocation from "../../components/common/WithLocation";
import { withTranslation } from "react-i18next";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Action from "../../components/common/Action";
import Filters from "../../components/common/Filters";
import List from "../../components/common/List";
import SearchInput from "../../components/common/SearchInput";
import EditTemplateModal from "../../components/common/EditTemplateModal";

import { ADMIN_TEMPLATE_COLUMNS, ADMIN_TEMPLATE_FILTERS } from "../../constants/TemplateConstants";
import { SORT_ORDER } from "../../constants/CommonConstants";

import "../../styles/css/components/commons/react-table.css";
import "../../styles/css/scenes/admin-templates.css";

class AdminTemplates extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			loading: true,
			loadedAll: true,
			searchTerm: "",
			sortField: ADMIN_TEMPLATE_COLUMNS.name.id,
			sortOrder: SORT_ORDER.asc,
			limit: 50,
			pageSize: 50,
			isSelectedFilter: PreferenceService.get("show_auto_review_response_templates")
				? ADMIN_TEMPLATE_FILTERS.autoResponses.id
				: ADMIN_TEMPLATE_FILTERS.other.id,
			showEditCreateModal: false,
			deleteAlertOpen: false,
			deleteSuccess: false,
			deleteError: false,
			toEditTemplate: {},
			showNewTemplateModel: false,
			toDeleteTemplateId: 0,
			showAutoReviewResponseTemplates: PreferenceService.get("show_auto_review_response_templates") ? true : false,
			currentMinStarsAutoResponse: 1,
			currentMaxStarsAutoResponse: 5
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchData();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = location => {
		this.fetchData();
	};

	async fetchData() {
		const locationId = UserService.getActiveLocation().id;

		let { searchTerm, sortField, sortOrder, limit } = this.state;

		this.setState({ loading: true });

		let data = await TemplateService.fetchTemplates({
			locationId: locationId,
			types: [],
			searchTerm: searchTerm,
			showAutoResponseTemplatesOnly: this.state.showAutoReviewResponseTemplates,
			autoResponseMinStar: this.state.currentMinStarsAutoResponse,
			autoResponseMaxStar: this.state.currentMaxStarsAutoResponse,
			sortField: sortField,
			sortOrder: sortOrder,
			limit: limit
		});

		if (!data) {
			await this.update({ loading: false });
			return;
		}

		await this.update({ data: data, loading: false, loadedAll: data.length < limit });
	}

	confirmDelete(template) {
		this.setState({
			toDeleteTemplateId: template.id,
			deleteAlertOpen: true
		});
	}

	async handleOnDeleteClick() {
		const { toDeleteTemplateId } = this.state;

		let success = await TemplateService.deleteTemplateById(toDeleteTemplateId);

		if (!success) {
			this.setState({ deleteError: true });
			return;
		}

		await this.update({ deleteSuccess: true });

		this.fetchData();
	}

	onTemplateEdited = () => {
		this.handleEditModalOnHide();
		this.fetchData();
	};

	handleEditModalOnHide() {
		this.setState({ toEditTemplate: {}, showEditCreateModal: false });
	}

	handleOnUpdateClick(template) {
		this.setState({ toEditTemplate: template }, () => this.setState({ showEditCreateModal: true }));
	}

	handleMinStarClick = e => {
		var val = e;
		if (e > this.state.currentMaxStarsAutoResponse) {
			val = this.state.currentMaxStarsAutoResponse;
		}

		this.setState({ currentMinStarsAutoResponse: val }, () => this.fetchData());
	};

	handleMaxStarClick = e => {
		var val = e;
		if (val < this.state.currentMinStarsAutoResponse) {
			val = this.state.currentMinStarsAutoResponse;
		}
		this.setState({ currentMaxStarsAutoResponse: val }, () => this.fetchData());
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchData();
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		await this.fetchData();
	};

	onFilterSelected = async item => {
		let { showAutoReviewResponseTemplates } = this.state;

		// If the other filter is already selected, no need to fetch the data
		if (item.id === ADMIN_TEMPLATE_FILTERS.other.id && !showAutoReviewResponseTemplates) {
			return;
		}
		// If auto review responses is already selected, no need to fetch the data
		if (item.id === ADMIN_TEMPLATE_FILTERS.autoResponses.id && showAutoReviewResponseTemplates) {
			return;
		}

		let showAutoResponseTemplates = item.id === ADMIN_TEMPLATE_FILTERS.other.id ? false : true;
		PreferenceService.set("show_auto_review_response_templates", showAutoResponseTemplates);

		let isSelectedFilter = showAutoResponseTemplates ? ADMIN_TEMPLATE_FILTERS.autoResponses.id : ADMIN_TEMPLATE_FILTERS.other.id;

		await this.update({ isSelectedFilter: isSelectedFilter, showAutoReviewResponseTemplates: showAutoResponseTemplates });
		await this.fetchData();
	};

	isFilterSelected = item => {
		return item === this.state.isSelectedFilter;
	};

	getFilters() {
		let { t } = this.props;

		let adminFilters = Object.keys(ADMIN_TEMPLATE_FILTERS)
			.map(item => {
				return { id: item, value: ADMIN_TEMPLATE_FILTERS[item].display, order: ADMIN_TEMPLATE_FILTERS[item].order };
			})
			.sort((a, b) => a.order - b.order);

		let filters = {
			filters: {
				title: t("Filter"),
				items: adminFilters,
				onClick: this.onFilterSelected,
				isSelected: this.isFilterSelected
			}
		};
		return filters;
	}

	getHeaders = () => {
		let columns = ADMIN_TEMPLATE_COLUMNS;

		let headers = {
			items: columns,
			sortBy: this.sortBy
		};

		return headers;
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ sortField, sortOrder });
		await this.fetchData(null, true);
	};

	renderRecord = recordData => {
		try {
			return [
				recordData.name,
				recordData.msg_text,
				recordData.type,
				BooleanService.getStringFromBoolean(recordData.default_for_type),
				this.renderActions(recordData)
			];
		} catch (error) {
			console.log(error);
		}
		return null;
	};

	renderActions(template) {
		let { t } = this.props;
		const user = UserService.get();

		return (
			<div className="contacts__actions ">
				{user.GroupPermission.update_templates && (
					<Action
						key={`updateTemplate-${template.id}`}
						id={`updateTemplate-${template.id}`}
						label={t("Edit")}
						icon={Icon.Edit}
						onClick={() => this.handleOnUpdateClick(template)}
					/>
				)}
				{user.GroupPermission.delete_templates && (
					<Action
						key={`deleteTemplate-${template.id}`}
						id={`deleteTemplate-${template.id}`}
						label={t("Delete")}
						icon={Icon.Trash2}
						onClick={() => this.confirmDelete(template)}
					/>
				)}
			</div>
		);
	}

	onRecordClicked = async item => {
		// do nothing for now
	};

	render() {
		let {
			data,
			loading,
			loadedAll,
			sortField,
			sortOrder,
			toEditTemplate,
			showEditCreateModal,
			deleteAlertOpen,
			deleteError,
			deleteSuccess,
			showAutoReviewResponseTemplates
		} = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let allowCreatingTemplates = user.GroupPermission.create_templates;

		return (
			<Page>
				<Header title="Templates">
					{allowCreatingTemplates && (
						<Action
							id="new-template"
							label={t("Create")}
							icon={Icon.Plus}
							onClick={() => this.setState({ showEditCreateModal: true, toEditTemplate: { id: 0 } })}
						/>
					)}
				</Header>
				<div className="Common__search">
					<SearchInput placeholder={t("Search ...")} onChange={this.onSearchChange} />
				</div>
				<Filters filters={this.getFilters()} />
				{showAutoReviewResponseTemplates && (
					<div className="templates__filter-auto-response__stars">
						<div className="stars-flexbox-container" style={{ width: "auto" }}>
							<div id="filter-stars-min" className="stars-flexbox-children">
								<span>
									<i>{t("Minimum")}</i>
								</span>
								<StarSelection
									size={15}
									defaultRating={this.state.currentMinStarsAutoResponse}
									maxRating={5}
									handleStarClick={e => this.handleMinStarClick(e)}
								/>
							</div>
							<div id="filter-stars-max" className="stars-flexbox-children">
								<span>
									<i>{t("Maximum")}</i>
								</span>
								<StarSelection
									size={15}
									defaultRating={this.state.currentMaxStarsAutoResponse}
									maxRating={5}
									handleStarClick={e => this.handleMaxStarClick(e)}
								/>
							</div>
						</div>
					</div>
				)}
				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={t("Looks like there are no templates yet...")}
					noDataIcon={<Icon.AlertCircle />}
				/>
				<Alert
					type="warning"
					show={deleteAlertOpen}
					title={t("Are you sure?")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={confirmed => {
						if (confirmed) {
							this.handleOnDeleteClick();
						}
						this.setState({ deleteAlertOpen: false });
					}}
				>
					<div>{t('Click "Yes" to delete this template or "No" to go back.')}</div>
				</Alert>
				<Alert
					type="error"
					show={deleteError}
					title={t("Failed")}
					confirm={t("Cancel")}
					onClose={() => {
						this.setState({ deleteError: false });
					}}
				>
					<div>{t("Unexpected error occured while deleting.")}</div>
				</Alert>
				<Alert
					type="success"
					show={deleteSuccess}
					title={t("Deleted")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ deleteSuccess: false });
					}}
				>
					<div>{t("The template was deleted successfully.")}</div>
				</Alert>
				<EditTemplateModal
					show={showEditCreateModal}
					templateId={toEditTemplate.id}
					onSubmit={this.onTemplateEdited}
					onHide={() => this.handleEditModalOnHide()}
					typeEditable={true}
				/>
			</Page>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(AdminTemplates)));
