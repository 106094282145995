import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import moment from "moment";
import c3 from "c3";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import * as Icon from "react-feather";
import { CSVLink } from "react-csv";
import ReactTooltip from "react-tooltip";
import { Redirect } from "react-router-dom";

import { CsvService } from "../../services/CsvService";
import GmbService from "../../services/GmbService";
import GmbLocationStatsService from "../../services/GmbLocationStatsService";

import Spinners from "../../components/common/Spinners";
import withLocation from "../../components/common/WithLocation";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import DashboardCard from "../Dashboard/DashboardCard";
import Action from "../../components/common/Action";

import { GMB_STATS } from "../../constants/GmbStats.js";

import "../../App.css";
import "react-day-picker/lib/style.css";
import "../../styles/css/scenes/analytics.css";

const SHOW_AFTER_X_MONTHS = 6;

class GmbLocationStatsAnalytics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAnalyzedStats: true,
			gmbAnalyzedStats: null,
			gmbLocationStats: null,
			loading: false,
			start: moment()
				.subtract(4, "days")
				.subtract(30, "days")
				.toDate(),
			end: moment()
				.subtract(4, "days")
				.toDate(),
			gmbViewSearchStatsCsv: null,
			gmbActionStatsCsv: null,
			gmbActive: true,
			connectNowClicked: false
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchData();
	}

	onLocationChanged = location => {
		this.fetchData();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async fetchData() {
		try {
			this.setState({ loading: true });
			await this.fetchGmbActive();
			await this.decideToShowAnalyzedStats();
			let { showAnalyzedStats } = this.state;
			let promises = [this.fetchGmbLocationStats()];
			if (showAnalyzedStats) {
				promises.push(this.fetchGmbStatsAnalysis());
			}

			await Promise.all(promises);
		} catch (error) {
			console.log(error);
		}
		this.setState({ loading: false });
	}

	async fetchGmbLocationStats() {
		try {
			const { start, end } = this.state;
			let locationId = UserService.getActiveLocation().id;
			let gmbLocationStats = await GmbLocationStatsService.getGmbLocationStats(locationId, start, end);
			this.setState({ gmbLocationStats: gmbLocationStats }, () => this.updateChart());
		} catch (error) {
			throw error;
		}
	}

	async fetchGmbStatsAnalysis() {
		try {
			const response = await GmbLocationStatsService.getGmbLocationStatsAnalysis();
			this.setState({ gmbAnalyzedStats: response });
		} catch (error) {
			throw error;
		}
	}

	async decideToShowAnalyzedStats() {
		let location = UserService.getActiveLocation();

		let showAnalyzedStats = false;

		let joinDate = moment(location.join_date).add(SHOW_AFTER_X_MONTHS, "months");
		if (moment().isSameOrAfter(joinDate, "day")) {
			showAnalyzedStats = true;
		}
		await this.update({ showAnalyzedStats });
	}

	async fetchGmbActive() {
		try {
			let gmbAndFbStatus = await GmbService.checkGmbAndFbStatus();
			this.setState({
				gmbActive: gmbAndFbStatus.gmb_active
			});
		} catch (error) {
			throw error;
		}
	}

	updateChart() {
		this.setState({ loading: false }, () => {
			const xAxis = this.state.gmbLocationStats.map(anInbound => anInbound.date);
			xAxis.unshift("x");

			const viewsMapsY = this.state.gmbLocationStats.map(anInbound => anInbound[GMB_STATS.metric.viewsMaps.id]);
			viewsMapsY.unshift(GMB_STATS.metric.viewsMaps.name);

			const viewsSearchY = this.state.gmbLocationStats.map(anInbound => anInbound[GMB_STATS.metric.viewsSearch.id]);
			viewsSearchY.unshift(GMB_STATS.metric.viewsSearch.name);

			const actionsWebsiteY = this.state.gmbLocationStats.map(anInbound => anInbound[GMB_STATS.metric.actionsWebsite.id]);
			actionsWebsiteY.unshift(GMB_STATS.metric.actionsWebsite.name);

			const actionsPhoneY = this.state.gmbLocationStats.map(anInbound => anInbound[GMB_STATS.metric.actionsPhone.id]);
			actionsPhoneY.unshift(GMB_STATS.metric.actionsPhone.name);

			const actionsDrivingDirectionsY = this.state.gmbLocationStats.map(anInbound => anInbound[GMB_STATS.metric.actionsDrivingDirections.id]);
			actionsDrivingDirectionsY.unshift(GMB_STATS.metric.actionsDrivingDirections.name);

			let dateColumns = [...xAxis];
			dateColumns[0] = "Date";

			let csvData = [dateColumns, viewsMapsY, viewsSearchY];
			csvData = csvData[0].map((col, i) => csvData.map(row => row[i])); // transpose the data

			let actionCsvData = [dateColumns, actionsWebsiteY, actionsPhoneY, actionsDrivingDirectionsY];
			actionCsvData = actionCsvData[0].map((col, i) => actionCsvData.map(row => row[i])); // transpose the data

			this.setState({
				gmbViewSearchStatsCsv: csvData,
				gmbActionStatsCsv: actionCsvData
			});

			this.generateChart("#gmb-stats-searches-chart", [xAxis, viewsMapsY, viewsSearchY]);
			this.generateChart("#gmb-stats-actions-chart", [xAxis, actionsWebsiteY, actionsPhoneY, actionsDrivingDirectionsY]);
		});
	}

	generateChart(id, columns) {
		const { start, end } = this.state;
		let { t } = this.props;

		const isMonth = moment(end).diff(moment(start), "days") >= 90;
		c3.generate({
			bindto: id,
			data: {
				x: "x",
				columns: columns,
				type: "line"
			},
			axis: {
				x: {
					label: t("Day"),
					type: "timeseries",
					tick: {
						format: function(x) {
							if (isMonth) {
								const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
								return "" + x.getFullYear() + " " + monthString[x.getMonth()];
							}
							//return monthString[x.getMonth()];
							return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
						},
						rotate: window.innerWidth > 768 ? 0 : 75,
						multiline: false
						//format: '%Y' // format string is also available for timeseries data
					}
				},
				y: {
					label: t("Counts")
				}
			}
		});
	}

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.setState({ start });
	};
	handleToChange = end => {
		this.setState({ end }, this.showFromMonth);
	};
	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}
	handleUpdateDate(event) {
		event.preventDefault();
		event.stopPropagation();

		this.fetchData();
	}
	calculatePercentChange(oldNumber, newNumber) {
		oldNumber = parseInt(oldNumber);
		newNumber = parseInt(newNumber);

		let percentage = ((oldNumber - newNumber) / oldNumber) * 100.0;
		return Math.round(percentage);
	}

	handleConfigureConnections = () => {
		this.setState({
			connectNowClicked: true
		});
	};

	statColor(pastData, currentData) {
		if (!pastData || pastData.length < 1 || !currentData || currentData.length < 1) {
			return "";
		}
		pastData = parseInt(pastData);
		currentData = parseInt(currentData);

		if (currentData < pastData * 0.8) {
			return "red";
		} else if (currentData < pastData) {
			return "yellow";
		}

		// if currentData >= pastData
		return "green";
	}

	renderMetricDataCard(data) {
		let { t } = this.props;

		return (
			<div key={`${data.metric}-${data.withDemandHub.viewsLastMonth}`} className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
				<div className="GmbLocationStats__metric-data-card ">
					<div className="text-center title">
						<span data-tip data-for={`${data.metric}-tooltip`}>
							{GMB_STATS.metric[data.metric].name} <Icon.Info size="18" />
						</span>
						<ReactTooltip id={`${data.metric}-tooltip`} className="mb-react-tooltip GmbLocationStats__tooltip" type="info" effect="solid" place="top">
							{GMB_STATS.metric[data.metric].description}
						</ReactTooltip>
					</div>
					<div>
						{typeof data.withDemandHub.viewsThisMonth !== "undefined" && (
							<div className="gmb-stat">
								<span>{t("This Month")}</span>
								<span className="statistic-number">
									<span>{data.withDemandHub.viewsThisMonth}</span>
								</span>
							</div>
						)}
						{typeof data.withDemandHub.viewsLastMonth !== "undefined" && (
							<div className="gmb-stat">
								<span>{t("Last Month")}</span>
								<span className="statistic-number">
									<span>{data.withDemandHub.viewsLastMonth}</span>
								</span>
							</div>
						)}
					</div>
					{data.withDemandHub &&
					(data.withDemandHub.viewsLastMonth || data.withDemandHub.totalViews || data.withDemandHub.avgMonthlyViews || data.withDemandHub.viewsThisMonth) ? (
						<div>
							<div className="text-center before-demandhub">
								<span data-tip data-for={`${data.metric}-monthly-avg-search-direct-veiws`}>
									{t("Monthly Average")} {data.beforeDemandHub && <Icon.Info size="14" />}
								</span>
								{data.beforeDemandHub && (
									<ReactTooltip
										id={`${data.metric}-monthly-avg-search-direct-veiws`}
										className="mb-react-tooltip GmbLocationStats__tooltip"
										type="info"
										effect="solid"
										place="top"
									>
										{t(
											"The data prior to joining is based on data up to 1 year before joining DemandHub. The data collected ranges from {{startRange}} to {{endRange}}",
											{ startRange: data.beforeDemandHub.startRange, endRange: data.beforeDemandHub.endRange }
										)}
									</ReactTooltip>
								)}
							</div>
							{data.withDemandHub.avgMonthlyViews && (
								<div className="gmb-stat">
									<span>{t("Since Joining")}</span>
									<span className="statistic-number">
										<span>{data.withDemandHub.avgMonthlyViews}</span>
										{data.withDemandHub.avgMonthlyViews && data.beforeDemandHub && data.beforeDemandHub.avgPerMonth && (
											<span className={this.statColor(data.beforeDemandHub.avgPerMonth, data.withDemandHub.avgMonthlyViews)}>
												{" "}
												(
												{data.withDemandHub.avgMonthlyViews === 0
													? `- ${data.withDemandHub.avgPerMonth}`
													: `${this.calculatePercentChange(data.withDemandHub.avgMonthlyViews, data.beforeDemandHub.avgPerMonth)}%`}
												)
											</span>
										)}
									</span>
								</div>
							)}
						</div>
					) : null}

					{data.beforeDemandHub && (data.beforeDemandHub.avgPerMonth || data.beforeDemandHub.totalViews) && (
						<div>
							{data.beforeDemandHub.avgPerMonth && (
								<div className="gmb-stat">
									<span>{t("Prior To Joining")}</span>
									<span className="statistic-number">{data.beforeDemandHub.avgPerMonth}</span>
								</div>
							)}

							{data.beforeDemandHub.totalViews && (
								<div className="gmb-stat">
									<span>{t("Total Views")}</span>
									<span className="statistic-number">{data.beforeDemandHub.totalViews}</span>
								</div>
							)}
						</div>
					)}

					{typeof data.yearOverYear !== "undefined" &&
					data.yearOverYear.last90Days &&
					data.yearOverYear.lastYearLast90Days &&
					this.calculatePercentChange(data.yearOverYear.last90Days, data.yearOverYear.lastYearLast90Days) ? (
						<div>
							<div className="text-center section-header">{t("Annual")}</div>
							<div className="gmb-stat">
								<span data-tip data-for={`${data.metric}-year-over-year`}>
									{t("Year over year")} <Icon.Info size="14" />
								</span>
								<span className="statistic-number">
									<span className={this.statColor(data.yearOverYear.lastYearLast90Days, data.yearOverYear.last90Days)}>
										{" "}
										(
										{data.yearOverYear.last90Days === 0
											? `- ${data.yearOverYear.lastYearLast90Days}`
											: `${this.calculatePercentChange(data.yearOverYear.last90Days, data.yearOverYear.lastYearLast90Days)}%`}
										)
									</span>
								</span>
							</div>
						</div>
					) : null}
					<ReactTooltip id={`${data.metric}-year-over-year`} className="mb-react-tooltip GmbLocationStats__tooltip" type="info" effect="solid" place="top">
						<div>
							<Trans
								i18nKey="Year over year comparison: <0 /> The percentage change of the total <1 /> views/clicks in the last 90 days <2 /> compared to last year over the same <3 /> 90 day period. <4 /> Total last 90 Days: {{last90Days}} <5 /> Total last Year: {{lastYearLast90Days}} <6 /> Last 90 days: {{last90DaysStartDate}} - {{last90DaysEndDate}} <7 /> Last year period: {{lastYearStartDate}} - {{lastYearEndDate}}"
								values={{
									last90Days: data.yearOverYear.last90Days,
									lastYearLast90Days: data.yearOverYear.lastYearLast90Days,
									last90DaysStartDate: data.yearOverYear.last90DaysStartDate,
									last90DaysEndDate: data.yearOverYear.last90DaysEndDate,
									lastYearStartDate: data.yearOverYear.lastYearStartDate,
									lastYearEndDate: data.yearOverYear.lastYearEndDate
								}}
								components={{
									0: <br />,
									1: <br />,
									2: <br />,
									3: <br />,
									4: <hr style={{ margin: "4px 0" }} />,
									5: <br />,
									6: <br />,
									7: <br />
								}}
							>
								Year over year comparison: <br />
								The percentage change of the total <br />
								views/clicks in the last 90 days <br />
								compared to last year over the same <br />
								90 day period.
								<hr style={{ margin: "4px 0" }} />
								Total last 90 Days: {data.yearOverYear.last90Days} <br />
								Total last Year: {data.yearOverYear.lastYearLast90Days} <br />
								Last 90 days: {data.yearOverYear.last90DaysStartDate} - {data.yearOverYear.last90DaysEndDate} <br />
								Last year period: {data.yearOverYear.lastYearStartDate} - {data.yearOverYear.lastYearEndDate} <br />
							</Trans>
						</div>
					</ReactTooltip>

					{!data.beforeDemandHub && !data.withDemandHub.totalViews && !data.withDemandHub.viewsLastMonth && (
						<div className="text-center">{t("No data found for this metric...")}</div>
					)}
				</div>
			</div>
		);
	}

	renderDatepicker = () => {
		const { loading, gmbLocationStats, start, end, showAnalyzedStats, gmbAnalyzedStats, gmbActive, connectNowClicked } = this.state;
		const modifiers = { start, end };
		const locationName = CsvService.filterActiveLocationName();
		let { t } = this.props;

		return (
			<>
				<div className="dashboard__card___datepicker">
					<label>{t("Date Range")}&nbsp;&nbsp;</label>
					<div className="input-group" id="datepicker">
						<div className="InputFromTo">
							<DayPickerInput
								value={start}
								placeholder={t(" From")}
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { after: end },
									toMonth: end,
									modifiers,
									numberOfMonths: 2,
									onDayClick: () => this.to.getInput().focus()
								}}
								onDayChange={start => this.handleFromChange(start)}
							/>{" "}
							<span className="InputFromTo-to">
								<DayPickerInput
									ref={el => (this.to = el)}
									value={end}
									placeholder={t(" To")}
									format="LL"
									formatDate={formatDate}
									parseDate={parseDate}
									dayPickerProps={{
										selectedDays: [start, { from: start, to: end }],
										disabledDays: { before: start },
										modifiers,
										month: start,
										fromMonth: start,
										numberOfMonths: 2
									}}
									onDayChange={this.handleToChange}
								/>
							</span>
						</div>
					</div>{" "}
					<button id="date-update" className="mb-button" onClick={e => this.handleUpdateDate(e)}>
						{t("Update Date")}
					</button>
				</div>
				<small>{t("GMB Stats are usually available from Google after 72 hours.")}</small>
			</>
		);
	};

	renderBody = () => {
		const { loading, gmbLocationStats, gmbActive } = this.state;
		const locationName = CsvService.filterActiveLocationName();
		let { t } = this.props;

		if (!gmbActive) {
			return (
				<DashboardCard>
					<div className="text-center">
						<h2>
							<span style={{ position: "relative", top: 3 }}>
								<Icon.AlertCircle color="red" size="25" />
							</span>{" "}
							{t("Please connect to GMB now to view this data.")}
						</h2>
						<h4>{t("By connecting your GMB account you will be able to track progress on:")}</h4>
					</div>

					<div className="text-center">
						{Object.values(GMB_STATS.metric).map(
							metric =>
								metric.used && (
									<div key={metric.name} style={{ marginTop: 5 }}>
										{metric.name}
										<span className="hidden-xs">
											<Icon.Info size="16" style={{ position: "relative", top: 3, left: 5 }} data-tip data-for={`${metric.name}-tooltip`} />{" "}
											<ReactTooltip type="info" effect="solid" id={`${metric.name}-tooltip`} place="bottom">
												{metric.description}
											</ReactTooltip>
										</span>
									</div>
								)
						)}
					</div>
					<div style={{ marginTop: 20 }} className="text-center">
						<button id="connect-now" className="mb-button" onClick={this.handleConfigureConnections}>
							{t("Connect now")}
						</button>
					</div>
				</DashboardCard>
			);
		}

		if (loading) {
			return (
				<>
					<DashboardCard>
						<div className="analytics__section__chart__spinner">
							<Spinners type="tail-fade" loading={true} size="60px" />
						</div>
					</DashboardCard>
					<DashboardCard>
						<div className="analytics__section__chart__spinner">
							<Spinners type="tail-fade" loading={true} size="60px" />
						</div>
					</DashboardCard>
				</>
			);
		}

		if (!gmbLocationStats) {
			return (
				<>
					<DashboardCard>
						<h2>
							{t("Sorry, no data was found")}{" "}
							<span role="img" aria-label="sad-face">
								😞
							</span>
						</h2>
					</DashboardCard>{" "}
					<DashboardCard>
						<h2>
							{t("Sorry, no data was found")}{" "}
							<span role="img" aria-label="sad-face">
								😞
							</span>
						</h2>
					</DashboardCard>
				</>
			);
		}

		return (
			<>
				<DashboardCard>
					<div className="dashboard__card__header dashboard__card__header--full-width">
						<div className="dashboard__card__header__name">{t("Search and View Statistics")}</div>
						<div className="dashboard__card__header__actions">
							{this.state.gmbViewSearchStatsCsv && (
								<CSVLink data={this.state.gmbViewSearchStatsCsv} filename={`${locationName}-gmb_views_stats.csv`} target="_self">
									<Action label={t("Download")} icon={Icon.Download} className="Common__csv-link">
										<Icon.Download />
									</Action>
								</CSVLink>
							)}
						</div>
					</div>
					<div id="gmb-stats-searches-chart" />
				</DashboardCard>
				<DashboardCard>
					<div className="dashboard__card__header dashboard__card__header--full-width">
						<div className="dashboard__card__header__name">{t("Action Statistics")}</div>
						<div className="dashboard__card__header__actions">
							{this.state.gmbActionStatsCsv && (
								<CSVLink data={this.state.gmbActionStatsCsv} filename={`${locationName}-gmb_action_stats.csv`} target="_self">
									<Action label={t("Download")} icon={Icon.Download} className="Common__csv-link">
										<Icon.Download />
									</Action>
								</CSVLink>
							)}
						</div>
					</div>
					<div id="gmb-stats-actions-chart" />
				</DashboardCard>
			</>
		);
	};

	render() {
		const { showAnalyzedStats, gmbAnalyzedStats, connectNowClicked } = this.state;
		let { t } = this.props;

		if (connectNowClicked) {
			const locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
			return <Redirect to={`/connections/${locationId}`} />;
		}

		return (
			<Page>
				<Header title={t("Google Business Stats")} />
				<div className="dh-page__body">{this.renderDatepicker()}</div>
				<div className="dh-page__cards">
					{this.renderBody()}
					{showAnalyzedStats ? (
						<div className="row GmbLocationStats__metric-data-cards">
							{gmbAnalyzedStats ? gmbAnalyzedStats.map(data => this.renderMetricDataCard(data)) : ""}
						</div>
					) : (
						<div className="GmbLocationStats__more-after">
							{t("* Additional statistics available after {{xNumberOfMonths}} months *", { xNumberOfMonths: SHOW_AFTER_X_MONTHS })}{" "}
						</div>
					)}
				</div>
			</Page>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(GmbLocationStatsAnalytics)));
