import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";

import DHModal from "./DHModal";

import "../../styles/css/scenes/select-gmb-location-modal.css";

class SelectGmbLocationsModal extends Component {
	render() {
		let { locations, onHide, show, onLocationSelected, googlePlaceId } = this.props;
		let { t } = this.props;

		let filteredLocations = locations.filter(
			l => googlePlaceId && googlePlaceId !== "" && l && l.metadata && l.metadata.placeId && l.metadata.placeId === googlePlaceId
		);
		let foundLocation = null;

		if (filteredLocations && filteredLocations.length > 0) {
			foundLocation = filteredLocations[0];
		} else {
			// if we cant find the location using the google place id we have, just show all of the ones we found
			filteredLocations = locations;
		}

		return (
			<DHModal title={t("Select a GMB location")} show={show} onHide={() => onHide()}>
				<>
					{foundLocation ? (
						<div className="modal-gmb-locations-sub-header">{t("Looks like we found your location! Please confirm by selecting it.")}</div>
					) : !foundLocation && filteredLocations && filteredLocations.length > 0 ? (
						<div className="modal-gmb-locations-sub-header">{t("Please select the location you would like to connect to DemandHub")}</div>
					) : (
						<div>
							<div className="modal-gmb-locations-sub-header">{t("No GMB locations for the account used were found.")} </div>
							<div>{t("Please try again with a different account. Or connect to DemandHub manually.")}</div>
							<div>
								<Trans
									i18nKey="Login to your <1>GMB account</1> and add 'connect@demandhub.co' as a 'Manager' of your business page."
									components={{
										1: <a href="https://business.google.com" target="_blank" rel="noopener noreferrer" />
									}}
								>
									Login to your{" "}
									<a href="https://business.google.com" target="_blank" rel="noopener noreferrer">
										GMB account
									</a>{" "}
									and add "connect@demandhub.co" as a "Manager" of your business page.
								</Trans>
							</div>
						</div>
					)}

					<div className="modal-gmb-locations-list">
						{filteredLocations &&
							filteredLocations.map(location => (
								<div
									key={location.name}
									className={`gmb-location-item ${foundLocation && foundLocation.name === location.name ? "selected" : ""}`}
									onClick={() => onLocationSelected(location)}
								>
									<span className="gmb-location-item__location-name">{location.title}</span>
									{location.storefrontAddress && (
										<span>
											{location.storefrontAddress.addressLines}, {location.storefrontAddress.locality}, {location.storefrontAddress.administrativeArea},{" "}
											{location.storefrontAddress.regionCode} {location.storefrontAddress.postalCode}
										</span>
									)}
									{!location.title && !location.storefrontAddress ? t("Location found, but no GMB title or address found.") : null}
								</div>
							))}
					</div>
				</>
			</DHModal>
		);
	}
}
export default withTranslation(null, { withRef: true })(SelectGmbLocationsModal);
