import React, { Component } from "react";
import * as Icon from "react-feather";
import { withTranslation, Trans } from "react-i18next";

import "./TeamChatDiscover.css";

class TeamChatDiscover extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		let { onGetStartedClicked } = this.props;
		let { t } = this.props;

		return (
			<div className="teamchat-discovery">
				<div className="teamchat-discovery__wrapper">
					<div className="teamchat-discovery__icon">
						<Icon.Bell size="17" />
					</div>
					<div className="teamchat-discovery__subtitle">{t("Introducing")}</div>
					<div className="teamchat-discovery__title">
						<Trans
							i18nKey="Team Chat <1>(Beta)</1>"
							components={{
								1: <span className="teamchat-discovery__mini_title" />
							}}
						>
							Team Chat <span className="teamchat-discovery__mini_title">(Beta)</span>
						</Trans>
					</div>
					<div className="teamchat-discovery__divider" />
					<div className="teamchat-discovery__features">
						<div className="teamchat-discovery__feature">
							<div className="teamchat-discovery__feature__icon">
								<Icon.Users size="16" />
							</div>
							<div className="teamchat-discovery__feature__description">{t("Start chatting and stay connected with your team!")}</div>
						</div>
						<div className="teamchat-discovery__feature">
							<div className="teamchat-discovery__feature__icon">
								<Icon.Info size="16" />
							</div>
							<div className="teamchat-discovery__feature__description">
								<Trans
									i18nKey="Learn more about team chat and its benefits <1>here.</1>"
									components={{
										1: <a target="_blank" href="https://www.demandhub.co/products/teamchat/" rel="noopener noreferrer" />
									}}
								>
									Learn more about team chat and its benefits{" "}
									<a target="_blank" href="https://www.demandhub.co/products/teamchat/" rel="noopener noreferrer">
										here.
									</a>
								</Trans>
							</div>
						</div>
					</div>
					<div className="teamchat-discovery__action" onClick={onGetStartedClicked}>
						{t("Get Started")}
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(TeamChatDiscover);
