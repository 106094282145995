import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";

import Action from "../../../components/common/Action";
import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import Filters from "../../../components/common/Filters";
import List from "../../../components/common/List";
import ManageKeywords from "./ManageKeywords";

import UserService from "../../../services/UserService";
import GAService from "../../../services/GAService";
import WorkflowService from "../../../services/WorkflowService";
import ToastService from "../../../services/ToastService";
import LocationService from "../../../services/LocationService";

import { STATUS_FILTER, KEYWORDS_COLUMNS } from "../../../constants/KeywordsConstants";

import withLocation from "../../../components/common/WithLocation";

import "../../../styles/css/scenes/keywords.css";

class Keywords extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			loadedAll: true,
			data: [],
			totalKeywords: 0,
			statusSelected: Object.keys(STATUS_FILTER)[0],
			showManageKeyword: false,
			selectedWorkflowId: null,
			showConfirmDelete: false,
			activeLocation: null
		};

		this.workflowConstant = null;
	}

	componentDidMount = () => {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.setup();
	};

	onLocationChanged = () => {
		this.setup();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	setup = async () => {
		this.workflowConstant = await WorkflowService.getCachedWorkflowsConfig();

		this.fetchKeywords();
		this.fetchTotalKeywords();
		this.setActiveLocation();
	};

	setActiveLocation = async () => {
		let activeLocation = UserService.getActiveLocation();
		let metaData = LocationService.getAndParseMetaData(activeLocation);
		activeLocation.meta_data = metaData;
		await this.update({
			activeLocation,
			totalKeywords:
				activeLocation.meta_data && activeLocation.meta_data.keywords && activeLocation.meta_data.keywords.templateLimit
					? activeLocation.meta_data.keywords.templateLimit
					: 0
		});
	};

	fetchKeywords = async () => {
		const { statusSelected } = this.state;
		await this.update({ loading: true });

		let workflows = await WorkflowService.fetchKeywords({
			locationId: UserService.getActiveLocation().id,
			status: [statusSelected]
		});

		if (!workflows) {
			return;
		}

		await this.update({ data: workflows, loading: false });
	};

	fetchTotalKeywords = async () => {
		let workflows = await WorkflowService.fetchKeywords({
			locationId: UserService.getActiveLocation().id,
			status: STATUS_FILTER.all.id
		});

		if (!workflows) {
			return;
		}

		await this.update({ totalKeywords: workflows.length });
	};

	showCreateButton = () => {
		let { activeLocation, totalKeywords } = this.state;

		let user = UserService.get();
		let allowCreate = user.GroupPermission.create_keywords;

		return (
			activeLocation &&
			activeLocation.meta_data &&
			activeLocation.meta_data.keywords &&
			activeLocation.meta_data.keywords.templateLimit &&
			totalKeywords < activeLocation.meta_data.keywords.templateLimit &&
			allowCreate
		);
	};

	getKeywordsTemplateWordLimit = () => {
		let { activeLocation } = this.state;

		if (activeLocation && activeLocation.meta_data && activeLocation.meta_data.keywords && activeLocation.meta_data.keywords.wordsPerTemplateLimit) {
			return activeLocation.meta_data.keywords.wordsPerTemplateLimit;
		}

		return 1;
	};

	getHeaders = () => {
		let headers = {
			items: KEYWORDS_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	};

	onDelete = async workflowId => {
		await this.update({ showConfirmDelete: true, selectedWorkflowId: workflowId });
	};

	deleteWorkflow = async () => {
		let { selectedWorkflowId } = this.state;
		let { t } = this.props;

		let response = await WorkflowService.deleteKeyword(selectedWorkflowId);
		if (!response) {
			ToastService.error(t(`Error deleting keywords`));
		} else {
			ToastService.info(t(`Deleted keywords`));
			await this.fetchKeywords();
			await this.fetchTotalKeywords();
		}
		this.setState({
			showConfirmDelete: false,
			selectedWorkflowId: "create"
		});
	};

	onRecordClicked = workflow => {
		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_keywords;

		if (!allowUpdate) {
			return;
		}

		this.setState({ showManageKeyword: true, selectedWorkflowId: workflow.id });
	};

	onStatusSelected = async item => {
		await this.update({ statusSelected: item.id });
		await this.fetchKeywords();
	};

	isStatusSelected = item => {
		return item === this.state.statusSelected;
	};

	getFilters = () => {
		let statuses = Object.keys(STATUS_FILTER)
			.map(item => {
				return { id: item, value: STATUS_FILTER[item].display, order: STATUS_FILTER[item].order };
			})
			.sort((a, b) => a.order - b.order);

		let filters = {
			statuses: {
				title: "",
				items: statuses,
				onClick: this.onStatusSelected,
				isSelected: this.isStatusSelected
			}
		};

		return filters;
	};

	renderKeywordTemplateLimit = () => {
		let { activeLocation, totalKeywords } = this.state;
		let { t } = this.props;

		if (!activeLocation || !activeLocation.meta_data || !activeLocation.meta_data.keywords || !activeLocation.meta_data.keywords.templateLimit) {
			return null;
		}

		return (
			<div className="keywords__limit-message">
				<span>
					{totalKeywords}/{activeLocation.meta_data.keywords.templateLimit} {t("Keywords Used")}
				</span>
				{activeLocation.meta_data.keywords.templateLimit === totalKeywords && (
					<span className="keywords_limit-message--reached ">
						<Icon.Info data-tip data-for={`keyword-limit-tooltip`} />
						<ReactTooltip id={`keyword-limit-tooltip`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="right">
							<div>{t("The keyword limit has been reached. Please contact support at support@demandhub.co to increase the keyword limit.")}</div>
						</ReactTooltip>
					</span>
				)}
			</div>
		);
	};

	renderRecord = recordData => {
		let { showConfirmDelete, selectedWorkflowId } = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_keywords;
		let allowDelete = user.GroupPermission.delete_keywords;

		return [
			recordData.status === STATUS_FILTER.active.id ? t("On") : t("Off"),
			<div className="keywords__list__record--words">
				{recordData.conditions[0].params &&
					recordData.conditions[0].params.words &&
					Array.isArray(recordData.conditions[0].params.words) &&
					recordData.conditions[0].params.words.map((word, index) => (
						<div key={index} className="badge keywords__list__record--word">
							{word}
						</div>
					))}
			</div>,
			recordData.MessageTemplate.msg_text,
			<div className="keywords__list__record--actions">
				{showConfirmDelete && selectedWorkflowId === recordData.id && allowDelete && (
					<>
						<Action icon={Icon.Check} id={`delete-${recordData.id}`} onClick={() => this.deleteWorkflow()} label={t("Confirm")} />
						<Action
							icon={Icon.X}
							id={`delete-${recordData.id}`}
							onClick={() => this.setState({ showConfirmDelete: false, selectedWorkflowId: null })}
							label={t("Cancel")}
						/>
					</>
				)}
				{!(showConfirmDelete && selectedWorkflowId === recordData.id) && allowUpdate && (
					<>
						<Action
							icon={Icon.Edit}
							id={`edit-${recordData.id}`}
							onClick={() => this.setState({ showManageKeyword: true, selectedWorkflowId: recordData.id, showConfirmDelete: false })}
							label={t("Edit")}
						/>
						<Action icon={Icon.Trash2} id={`delete-${recordData.id}`} onClick={() => this.onDelete(recordData.id)} label={t("Delete")} />
					</>
				)}
			</div>
		];
	};

	render() {
		const { data, loading, loadedAll, showManageKeyword, selectedWorkflowId } = this.state;
		let { t } = this.props;

		if (!WorkflowService.isKeywordsEnabled()) {
			return <Redirect to="/" />;
		}

		return (
			<Page>
				<Header title={t("Keywords")}>
					{this.showCreateButton() && (
						<Action
							id="create-keywords"
							label={t("Create")}
							icon={Icon.Plus}
							onClick={() => this.update({ showManageKeyword: true, selectedWorkflowId: "create", showConfirmDelete: false })}
						/>
					)}
				</Header>
				{this.renderKeywordTemplateLimit()}
				<Filters filters={this.getFilters()} />
				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={t("Looks like there are no keywords yet...")}
					noDataIcon={<Icon.AlertCircle />}
				/>
				<ManageKeywords
					show={showManageKeyword}
					workflowId={selectedWorkflowId}
					wordLimit={this.getKeywordsTemplateWordLimit()}
					onCancel={() => this.update({ showManageKeyword: false })}
					onUpdate={() => {
						this.fetchKeywords();
						this.fetchTotalKeywords();
						this.setState({ showManageKeyword: false });
					}}
				/>
			</Page>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(Keywords)));
