import React, { Component } from "react";
import * as Icon from "react-feather";
import { PoseGroup } from "react-pose";
import { withTranslation, Trans } from "react-i18next";

import { ContextMenu, CONVERSATION_FILTERS } from "../../../../constants/Messenger";
import ReactTooltip from "react-tooltip";

import "./filter-conversations.css";

class FilterConversations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showList: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		document.addEventListener("mousedown", this.onMouseDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onMouseDown, false);
	}

	onMouseDown = async e => {
		let { showList } = this.state;

		if (this.dropdown && this.dropdown.contains && this.dropdown.contains(e.target)) {
			return;
		}

		if (this.contextContainer && this.contextContainer.contains && this.contextContainer.contains(e.target) && !showList) {
			await this.update({
				showList: true
			});
		} else if (this.dropdown && this.dropdown.contains) {
			await this.update({
				showList: false
			});
		}

		if (this.props.onShowList) {
			this.props.onShowList(this.state.showList);
		}
	};

	onFilterSelected = filterId => {
		if (this.props.onFilterSelected) {
			this.props.onFilterSelected(filterId);
		}
	};

	renderFilters() {
		let { activeFilters } = this.props;

		return (
			<ContextMenu key="container" ref={ref => (this.dropdown = ref)} className="mb-conversation-filter-list">
				{Object.keys(CONVERSATION_FILTERS).map(filter => {
					let filterId = CONVERSATION_FILTERS[filter].id;
					let filterName = CONVERSATION_FILTERS[filter].name;
					let isFilterActive = false;

					// Decide which items to checkmark
					if (activeFilters && activeFilters.length > 0) {
						isFilterActive = activeFilters.includes(filterId);
					} else if (filterId === CONVERSATION_FILTERS.all.id && activeFilters && activeFilters.length === 0) {
						isFilterActive = true;
					}

					return (
						<div key={filterId} className="mb-conversation-filter-item" onClick={() => this.onFilterSelected(filterId)}>
							{filterName}
							{isFilterActive && (
								<div className="mb-conversation-filter-item__selected">
									<Icon.CheckCircle size="16" />
								</div>
							)}
						</div>
					);
				})}
			</ContextMenu>
		);
	}

	render() {
		let { showList } = this.state;
		let { activeFilters } = this.props;
		let { t } = this.props;

		return (
			<>
				<div id="fnctst-list-conversations-filter" ref={ref => (this.contextContainer = ref)} data-tip data-for="conversation-filter-tooltip">
					<Icon.Filter size="22" color={activeFilters && activeFilters.length > 0 ? "#60A9FF" : "#333"} />
				</div>
				<PoseGroup>{showList && this.renderFilters()}</PoseGroup>
				<ReactTooltip id="conversation-filter-tooltip" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="bottom">
					{t("Select a filter")}
				</ReactTooltip>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(FilterConversations);
