import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";

import Modal from "../../../../components/common/DHModal";

class EditReasonModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			reason: ""
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidUpdate = async prevProps => {
		if (prevProps.reason !== this.props.reason) {
			await this.update({ reason: this.props.reason });
		}
	};

	onSave = () => {
		if (this.props.onSave) {
			this.props.onSave(this.state.reason);
		}
	};

	handleReasonChange = async event => {
		try {
			let reason = event.target.value.toLowerCase();
			await this.update({ reason });
		} catch (error) {
			console.log(error);
		}
	};

	onHide = () => {
		this.update({ reason: "" });

		if (this.props.onHide) {
			this.props.onHide();
		}
	};

	renderTitle = () => {
		const { createMode } = this.props;
		let { t } = this.props;

		return createMode ? t("Create a new reason") : t("Update a reason");
	};

	render() {
		let { show, createMode } = this.props;
		const { reason } = this.state;
		let { t } = this.props;

		return (
			<Modal show={show} onHide={this.onHide} title={this.renderTitle()}>
				<>
					<div className="modal__flex-container">
						<input
							name="reason"
							id="reason-input"
							type="text"
							onChange={this.handleReasonChange}
							value={reason}
							className="Common__input"
							autoComplete="off"
							placeholder={t("ex: consulting")}
						/>

						<div className="modal__actions">
							<div id={createMode ? "create" : "update"} className="mb-button" onClick={this.onSave}>
								{t("Save")}
							</div>
						</div>
					</div>
				</>
			</Modal>
		);
	}
}
export default withTranslation(null, { withRef: true })(EditReasonModal);
