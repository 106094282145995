import React, { Component } from "react";
import * as Icon from "react-feather";
import { withTranslation, Trans } from "react-i18next";

import DHDropdown from "../../../../components/common/DHDropdown";
import DHDropdownOption from "../../../../components/common/DHDropdownOption";
import SendLaterModal from "./SendLaterModal";

import { MODE } from "../../../../constants/Messenger";
import { GA_CATEGORIES, GA_ACTIONS } from "../../../../constants/GAConstants";

import GAService from "../../../../services/GAService";

import "./send-button.css";

class SendButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isContext: false,
			showSendLaterModal: false
		};

		this.context = null;
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = e => {
		if (this.context && this.context.contains && this.context.contains(e.target)) {
			return;
		}

		this.update({
			isContext: false
		});
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onContextClick = () => {
		let { disabled } = this.props;
		let { isContext } = this.state;

		this.update({
			isContext: !isContext && !disabled
		});
	};

	onSendLaterAndClose = async ({ sendAfter }) => {
		this.hideContextMenu();
		this.hideSendLaterModal();

		if (this.props.onSendLater) {
			await this.props.onSendLater({ sendAfter, close: true, scheduledMessage: true });
		}

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.thread,
			action: GA_ACTIONS.messenger.thread.customer.sendLaterAndCloseMessage,
			label: GA_ACTIONS.messenger.thread.customer.sendLaterAndCloseMessage
		});
	};

	onSendLater = async ({ sendAfter }) => {
		this.hideContextMenu();
		this.hideSendLaterModal();

		if (this.props.onSendLater) {
			await this.props.onSendLater({ sendAfter, close: false, scheduledMessage: true });
		}

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.thread,
			action: GA_ACTIONS.messenger.thread.customer.sendLaterMessage,
			label: GA_ACTIONS.messenger.thread.customer.sendLaterMessage
		});
	};

	onSendAndClose = async () => {
		if (this.props.onSendAndClose) {
			await this.props.onSendAndClose();
		}

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.thread,
			action: GA_ACTIONS.messenger.thread.customer.sentAndCloseMessage,
			label: GA_ACTIONS.messenger.thread.customer.sentAndCloseMessage
		});
	};

	onSend = async () => {
		let { mode, disabled } = this.props;

		if (disabled) {
			return;
		}

		if (this.props.onSend) {
			await this.props.onSend();
		}

		if (mode === MODE.customer) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.thread,
				action: GA_ACTIONS.messenger.thread.customer.sentMessage,
				label: GA_ACTIONS.messenger.thread.customer.sentMessage
			});
		} else {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.thread,
				action: GA_ACTIONS.messenger.thread.teamchat.sentMessage,
				label: GA_ACTIONS.messenger.thread.teamchat.sentMessage
			});
		}
	};

	hideContextMenu = () => {
		this.update({ isContext: false });
	};

	hideSendLaterModal = () => {
		this.update({
			showSendLaterModal: false
		});
	};

	showSendLaterModal = () => {
		this.update({
			showSendLaterModal: true
		});
	};

	renderSendLaterModal() {
		let { showSendLaterModal, disabled } = this.state;
		let { conversationStatus, messageContent, messageHtml, messageJSON, mode, mediaIds, media } = this.props;

		return (
			<SendLaterModal
				show={showSendLaterModal}
				onHide={this.hideSendLaterModal}
				conversationStatus={conversationStatus}
				onSendLaterAndClose={this.onSendLaterAndClose}
				onSendLater={this.onSendLater}
				messageContent={messageContent}
				messageHtml={messageHtml}
				messageJSON={messageJSON}
				mediaIds={mediaIds}
				mode={mode}
				disabled={disabled}
			/>
		);
	}

	render() {
		let { mode, sending, disabled, conversationStatus } = this.props;
		let { isContext } = this.state;
		let { t } = this.props;

		return (
			<div id="mb-send-button" className={`mb-send mb-send--auto ${disabled ? "mb-send--disabled" : ""}`} style={this.props.style}>
				<div className="mb-send--text" onClick={this.onSend}>
					{sending ? t("Sending ...") : t("Send")}
				</div>
				<div className="mb-send--chevron" id="mb-send--chevron" onClick={this.onContextClick}>
					{!isContext ? <Icon.ChevronDown size="16" /> : <Icon.ChevronUp size="16" />}
				</div>

				<DHDropdown
					offset={{
						bottom: 74,
						right: 30,
						marginBottom: 5
					}}
					show={isContext}
					onChange={({ show }) => this.update({ isContext: show })}
					options={
						<>
							<DHDropdownOption icon={Icon.Send} title={mode === MODE.customer ? t(`Send & Close`) : t(`Send`)} action={this.onSendAndClose} />
							<DHDropdownOption icon={Icon.Clock} title={t("Send Later")} action={this.showSendLaterModal} />
						</>
					}
				/>

				{this.renderSendLaterModal()}
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(SendButton);
