import i18n from "../i18n/config";

export const NPS_TABS = {
	suggestions: {
		id: "suggestions",
		value: i18n.t("Suggestions"),
		route: "/nps/suggestions"
	},
	analytics: {
		id: "analytics",
		value: i18n.t("Analytics"),
		route: "/nps/analytics"
	}
};
