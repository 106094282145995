import React, { Component } from "react";
import * as Icon from "react-feather";
import moment from "moment";
import { withTranslation, Trans } from "react-i18next";

import AppConfig from "../../../../config/app/web-app.config";

import MessagesService from "../../../../services/MessagesService";
import UserService from "../../../../services/UserService";
import UtilityService from "../../../../services/UtilityService";

import Checkbox from "../../../../components/common/Checkbox";

import { MESSAGE_STATES, MEDIA_ICON } from "../../../../constants/Messenger";

import "./conversation.css";

class Conversation extends Component {
	constructor(props) {
		super(props);

		this.conversationComponent = null;
	}

	getRenderContent() {
		let { convo } = this.props;
		let user = UserService.get();

		let {
			content,
			direction,
			sender_user_name,
			sender_user_id,

			last_message_content,
			last_message_direction,
			last_message_user_id,
			last_message_user_name
		} = convo;

		let renderedContent = "";

		// If there is a last message
		if (last_message_content && last_message_content.length > 0) {
			renderedContent = content;

			// If it is an outbound message
			if (last_message_direction === "out" && last_message_user_id) {
				if (user.id === last_message_user_id) {
					renderedContent = `You: ${last_message_content}`;
				} else {
					let firstName = last_message_user_name ? last_message_user_name.split(" ")[0] : "";
					renderedContent = `${firstName}: ${last_message_content}`;
				}
			} else {
				// If it is an inbound message
				renderedContent = last_message_content;
			}
			return renderedContent;
		}

		// If not last message was found
		renderedContent = content;
		if (direction === "out" && sender_user_id) {
			if (user.id === sender_user_id) {
				renderedContent = <span>You: {content ? content : <Icon.Image size={16} />}</span>;
			} else {
				let firstName = sender_user_name ? sender_user_name.split(" ")[0] : "";
				renderedContent = (
					<span>
						{firstName}: {content ? content : <Icon.Image size={16} />}
					</span>
				);
			}
		}

		return renderedContent;
	}

	canSeeConversationPreview() {
		let user = UserService.get();
		let { convo } = this.props;

		let showConvoPreview = true;
		// If they don't have permissions to see messages assigned to others
		if (!user.GroupPermission.view_messages_assigned_to_others && convo.assigned_user_id !== user.id) {
			showConvoPreview = false;
		} else if (!user.GroupPermission.view_unassigned_messages && convo.assigned_user_id === 0) {
			// If they don't have permissions to view conversations that are unassigned
			showConvoPreview = false;
		} else if (!user.GroupPermission.view_customer_messages && convo.assigned_user_id === user.id) {
			// If they don't have permissions to view conversations that are assigned to them
			showConvoPreview = false;
		}
		return showConvoPreview;
	}

	canShowNudge(nudge) {
		let user = UserService.get();
		if (!user.messenger_conversation_nudging || !nudge || !nudge.text || !nudge.messageTimeAt) {
			return false;
		}

		let messageTimeAt = moment(nudge.messageTimeAt);
		let now = moment();

		if (messageTimeAt.isAfter(now)) {
			return false;
		}

		let daysAgo = moment().diff(messageTimeAt, "days");

		if (daysAgo < 0) {
			return false;
		}

		return true;
	}

	renderNudge(nudge) {
		if (!nudge) {
			return null;
		}
		let messageTimeAt = moment(nudge.messageTimeAt);
		let daysAgo = moment().diff(messageTimeAt, "days");

		return <div className="mb-convo-bottom__nudge">{MessagesService.generateContent(nudge.text, { DAYS_AGO: daysAgo })}</div>;
	}

	renderConvoBottom({ unread, nudge, inbox_name, message_state }) {
		let renderedContent = this.getRenderContent();

		// Check if the last message did not send
		let messageError = message_state && message_state === MESSAGE_STATES.error;

		return (
			<div className="mb-convo-bottom">
				<div className="mb-convo-content">{this.canSeeConversationPreview() ? renderedContent : "..."}</div>
				{this.canShowNudge(nudge) && this.renderNudge(nudge)}
				{inbox_name && <div className="mb-convo-inbox">{inbox_name}</div>}
				{unread > 0 && <div className="mb-convo-unread">{unread}</div>}
				{messageError && (
					<div className="mb-convo-error">
						<Icon.AlertCircle size="20" color="#ec4758" />
					</div>
				)}
			</div>
		);
	}

	onCheckMarkWasToggled = (e, convo) => {
		let { contact_id } = convo;

		e.stopPropagation();
		e.preventDefault();

		if (this.props.onCheckMarkToggle) {
			this.props.onCheckMarkToggle(contact_id);
		}
	};

	render() {
		let { activeContactId, convo, onSelect, shouldShowSelectableCheckMarks, isCheckmarked } = this.props;
		let { contact_id, name, content, media_type, unread, last_sent, assigned_user, message_state, medium, nudge, inbox_name } = convo;
		let { t } = this.props;

		let unreadStyle = unread > 0 ? "mb-convo--bold" : "";
		let activeStyle = contact_id === activeContactId ? "mb-convo--active" : "";

		// If it's media message and it has no text
		if (content === "" && media_type && media_type.length > 0) {
			// Replace the message used in the list component to a string of emojis
			content = MEDIA_ICON[media_type];
		}

		return (
			<div
				key={contact_id}
				ref={ref => (this.conversationComponent = ref)}
				className={`mb-convo ${unreadStyle} ${activeStyle}`}
				onClick={() => onSelect(convo)}
			>
				<div className="mb-convo-top">
					{shouldShowSelectableCheckMarks && (
						<div className="mb-convo__checkbox-container" onClick={e => this.onCheckMarkWasToggled(e, convo)}>
							<Checkbox id="is_bulk_selected" name="is_bulk_selected" checked={isCheckmarked} className="mb-convo__checkbox" />
						</div>
					)}
					<div className={`mb-convo-name ${name ? "" : "mb-convo-name--unknown"}`}>{name || t("Unknown")}</div>
					<div className="mb-convo-top__spacer" />
					{assigned_user && <div className="mb-convo-user">{assigned_user}</div>}
					<img className="mb-convo__medium" alt="conversation medium" src={`${AppConfig.CDN_URL}web-app/assets/messenger/mediums/${medium}.svg`} />
					<div className="mb-convo-date">{UtilityService.getRelativeDate(last_sent)}</div>
				</div>

				{this.renderConvoBottom({ unread, nudge, inbox_name, message_state })}
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(Conversation);
