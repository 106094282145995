import React, { Component } from "react";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import LocationService from "../../services/LocationService";
import NotificationService from "../../services/NotificationService";
import ContactService from "../../services/ContactService";
import GAService from "../../services/GAService";

import SendReviewInviteModal from "../../components/common/SendReviewInviteModal";
import Alert from "../../components/common/Alert";
import withLocation from "../../components/common/WithLocation";

import { TEMPLATE_TYPE } from "../../constants/TemplateConstants";
import { SEND_REVIEW_INVITES_MODAL, CREATE_REVIEW_INVITE_ERRORS } from "../../constants/Invites";
import { GA_CATEGORIES, GA_ACTIONS } from "../../constants/GAConstants";

import "./send-review-invites.css";

class SendReviewInvites extends Component {
	constructor(props) {
		super(props);
		let { t } = this.props;

		this.state = {
			showReviewInviteSuccessMessage: false,
			showReviewInviteFailMessage: false,
			name: "",
			reviewInviteFailMessage: t("The review invitation could not be sent. Please check the number/email and try again."),
			phoneOrEmail: "",
			duplicateNumber: false,
			duplicateDate: "",
			showReviewSending: false,
			showReviewRepeatMessage: false,
			templateId: 0,
			repeatDate: null,
			showSendReviewInviteModal: false
		};

		this.debouncedChanged = _.debounce(
			async () => {
				await this.checkLastSentReviewRequest();
			},
			500,
			{
				leading: true,
				trailing: true
			}
		);
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async componentDidMount() {
		// If we want to open the fill the review invite dialog with a specific contact
		NotificationService.subscribeOnce("openReviewInvite", "SendReviewInvites", async contactId => {
			let contact = await ContactService.getContact(contactId);

			if (!contact) {
				return;
			}

			// Get the contact name
			let contactName = contact.name;

			// Declare a blank email or phone
			let phoneOrEmail = "";

			// If they prefer sms and have a phone
			if (contact.preferred_medium === "sms" && contact.phone !== "") {
				phoneOrEmail = contact.phone;
			}
			// If they prefer email and have an email set
			else if (contact.preferred_medium === "email" && contact.email !== "") {
				phoneOrEmail = contact.email;
			}
			// If they have one of them set
			else if (contact.phone || contact.email) {
				phoneOrEmail = contact.phone || contact.email;
			}

			await this.update({
				showSendReviewInviteModal: true,
				name: contactName,
				phoneOrEmail: phoneOrEmail
			});

			// Check if sent before
			await this.checkLastSentReviewRequest();
		});
	}

	onLocationChanged = async () => {
		await this.update({ templateId: 0 });
	};

	handleNameChange(event) {
		this.update({ name: event.target.value });
	}

	handlePhoneOrEmailChange = async event => {
		await this.update({
			phoneOrEmail: event.target.value
		});

		this.debouncedChanged();
	};

	checkLastSentReviewRequest = async () => {
		let { phoneOrEmail } = this.state;

		// Check for duplicate numbers if we have a sufficient number of digits
		if (phoneOrEmail < 7) {
			this.update({ duplicateNumber: false });
			return;
		}

		// Check if the number or email was already sent a message
		const locationId = UserService.getActiveLocation().id;
		let reviewRequest = await LocationService.lastSentReviewRequest(locationId, phoneOrEmail);

		if (!reviewRequest || !reviewRequest.last_sent) {
			this.update({ duplicateNumber: false, duplicateDate: "" });
			return;
		}

		// if the review request has not been sent recently
		this.update({
			duplicateNumber: true,
			duplicateDate: reviewRequest.created_at
		});
	};

	handleSendInviteHeaderButton = async () => {
		// Clear the name and phone
		await this.update({
			name: "",
			phoneOrEmail: "",
			duplicateNumber: false,
			duplicateDate: "",
			showSendReviewInviteModal: true
		});
	};

	handleChangeTemplate = templateId => {
		this.update({ templateId: templateId });
	};

	handleReviewInviteSubmit = async templateId => {
		await this.update({ templateId });
		await this.submitInvite(true);
	};

	submitInvite = async checkRepeat => {
		const locationId = UserService.getActiveLocation().id;

		// If we want to skip the guards/checks, for example, we've already checked the form but poped up a modal to confirm that the user want's to send an invite to someone who has already received one recently
		if (!checkRepeat) {
			await this.createReviewInvite();
			return;
		}

		const { name, phoneOrEmail } = this.state;

		// Check if the review was already sent
		let reviewRequest = await LocationService.lastSentReviewRequest(locationId, phoneOrEmail);

		// If there was an error getting the review request
		if (!reviewRequest) {
			await this.update({ showSendReviewInviteModal: false, templateId: 0 });
			await this.update({ showReviewSending: false, showReviewInviteFailMessage: true });
			return;
		}

		// If a review request has already been sent to this phone or email. If the review request is not found, the review request object looks like {last_sent: false}
		if (reviewRequest.last_sent) {
			await this.update({
				showReviewRepeatMessage: true,
				repeatDate: reviewRequest.created_at,
				repeatName: name,
				repeatPhone: phoneOrEmail,
				repeatLocationId: locationId
			});
			return;
		}

		// All the checks have passed, we are good to send a review invite
		await this.createReviewInvite();
	};

	createReviewInvite = async () => {
		const { name, phoneOrEmail, templateId } = this.state;

		await this.update({ showReviewSending: true });

		// Make an API call to send the review request
		const locationId = UserService.getActiveLocation().id;
		const phoneOrEmailField = phoneOrEmail.indexOf("@") >= 0 ? "email" : "phone";

		const data = { name: name, location: locationId, [phoneOrEmailField]: phoneOrEmail, template: templateId };
		let result = await LocationService.createReviewRequest(data);

		// If there was an issue with sending the review request
		if (!result || result.error) {
			let reviewInviteFailMessage = CREATE_REVIEW_INVITE_ERRORS.failedToSend.message;
			if (result && result.error) {
				if (result.error.includes(CREATE_REVIEW_INVITE_ERRORS.receiveFeedbackSmsDisabled.id)) {
					reviewInviteFailMessage = CREATE_REVIEW_INVITE_ERRORS.receiveFeedbackSmsDisabled.message;
				} else if (result.error.includes(CREATE_REVIEW_INVITE_ERRORS.receiveFeedbackEmailDisabled.id)) {
					reviewInviteFailMessage = CREATE_REVIEW_INVITE_ERRORS.receiveFeedbackEmailDisabled.message;
				}
			}

			await this.update({
				reviewInviteFailMessage: reviewInviteFailMessage,
				showSendReviewInviteModal: false,
				templateId: 0,
				showReviewSending: false,
				showReviewInviteFailMessage: true
			});
			return;
		}

		// If the review request was sent
		await this.update({ showSendReviewInviteModal: false, showReviewSending: false, showReviewInviteSuccessMessage: true, templateId: 0 });

		if (this.props.dashboard) {
			this.props.dashboard.refreshDashboardData();
		}

		GAService.GAEvent({
			category: GA_CATEGORIES.topBar.sections.sendReviewButton,
			action: GA_ACTIONS.invites.sendReviewInvite,
			label: `Review Invite Sent`
		});
	};

	async triggerShow() {
		await this.update({ templateId: 0 });
		this.handleSendInviteHeaderButton();
	}

	render() {
		let { t } = this.props;
		let user = UserService.get();

		if (!user || !user.GroupPermission || !user.GroupPermission.create_invites) {
			return null;
		}

		const {
			showReviewInviteFailMessage,
			showReviewInviteSuccessMessage,
			showSendReviewInviteModal,
			duplicateNumber,
			duplicateDate,
			phoneOrEmail,
			name,
			showReviewSending,
			showReviewRepeatMessage,
			repeatDate,
			reviewInviteFailMessage
		} = this.state;

		return (
			<>
				<SendReviewInviteModal
					key="invite-remind-modal"
					showModal={showSendReviewInviteModal}
					handleOnHide={() => this.update({ showSendReviewInviteModal: false, isEmailOrPhoneInValid: false })}
					duplicateNumber={duplicateNumber}
					duplicateDate={duplicateDate}
					phoneOrEmail={phoneOrEmail}
					handlePhoneOrEmailChange={this.handlePhoneOrEmailChange}
					handleNameChange={e => this.handleNameChange(e)}
					name={name}
					showSending={showReviewSending}
					handleSubmit={this.handleReviewInviteSubmit}
					type={SEND_REVIEW_INVITES_MODAL.type.invite}
					title={t("Send Review Invitation")}
					handleChangeTemplate={this.handleChangeTemplate}
					templateId={this.state.templateId}
					reviewType={TEMPLATE_TYPE.reviewRequest}
					showPreview={true}
				/>
				<Alert
					type="success"
					show={showReviewInviteSuccessMessage}
					title={t("Invitation Sent")}
					confirm={t("OK")}
					onClose={() => {
						this.update({ showReviewInviteSuccessMessage: false });
					}}
				>
					<div>{t("The review invitation has been sent successfully.")}</div>
				</Alert>
				<Alert
					type="error"
					show={showReviewInviteFailMessage}
					title={t("Invitation Error")}
					confirm={t("OK")}
					onClose={() => {
						this.update({ showReviewInviteFailMessage: false });
					}}
				>
					<div>{reviewInviteFailMessage}</div>
				</Alert>
				<Alert
					type="warning"
					show={showReviewRepeatMessage}
					title={t("Previously Sent")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={result => {
						if (result) {
							this.update({ showReviewRepeatMessage: false });

							// This is a bit of JS hackery, since the SweetAlert for the repeat message doesn't dismiss quickly enough, causing UI issues when the success/fail SweetAlert tries to pop
							setTimeout(() => {
								this.submitInvite(false);
							}, 1000);
						} else {
							this.update({ showSendReviewInviteModal: false }, this.update({ showReviewSending: false, showReviewRepeatMessage: false }));
						}
					}}
				>
					<div>{t("This customer was already sent a message on {{date}}. Do you want to send again?", { date: new Date(repeatDate).toDateString() })}</div>
				</Alert>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(SendReviewInvites);
