import i18n from "../i18n/config";

export const GMB_STATS = {
	// metric info can be found here: https://developers.google.com/my-business/reference/rest/v4/Metric
	metric: {
		queriesDirect: {
			id: "queries_direct_daily",
			name: i18n.t("Search Direct Views"),
			description: i18n.t("The number of times the resource was shown when searching for the location directly."),
			used: false
		},
		queriesIndirect: {
			id: "queries_indirect_daily",
			name: i18n.t("Search Indirect Views"),
			description: i18n.t("The number of times the resource was shown as a result of a categorical search (for example, restaurant)."),
			used: false
		},
		queriesChain: {
			id: "queries_chain_daily",
			name: i18n.t("Search Chain Views"),
			description: i18n.t(
				"The number of times a resource was shown as a result of a search for the chain it belongs to, or a brand it sells. For example, Starbucks, Adidas. This is a subset of 'Search Indirect Views'."
			),
			used: false
		},
		viewsMaps: {
			id: "views_maps_daily",
			name: i18n.t("Google Maps Views"),
			description: i18n.t("The number of times the resource was viewed on Google Maps."),
			used: true
		},
		viewsSearch: {
			id: "views_search_daily",
			name: i18n.t("Google Search Views"),
			description: i18n.t("The number of times the resource was viewed on Google Search."),
			used: true
		},
		actionsWebsite: {
			id: "actions_website_daily",
			name: i18n.t("Website Visit Clicks"),
			description: i18n.t("The number of times the website was clicked."),
			used: true
		},
		actionsPhone: {
			id: "actions_phone_daily",
			name: i18n.t("Phone Clicks"),
			description: i18n.t("The number of times the phone number was clicked."),
			used: true
		},
		actionsDrivingDirections: {
			id: "actions_driving_directions_daily",
			name: i18n.t("Driving Directions Requests"),
			description: i18n.t("The number of times driving directions were requested."),
			used: true
		},
		photosViewsMerchant: {
			id: "photos_views_merchant_daily",
			name: i18n.t("Merchant Photos Views"),
			description: i18n.t("The number of views on media items uploaded by the merchant."),
			used: false
		},
		photosViewsCustomers: {
			id: "photos_views_customers_daily",
			name: i18n.t("Customer Photos Views"),
			description: i18n.t("The number of views on media items uploaded by customers."),
			used: false
		},
		photosCountMerchant: {
			id: "photos_count_merchant_daily",
			name: i18n.t("Merchant Photos Count"),
			description: i18n.t("The total number of media items that are currently live that have been uploaded by the merchant."),
			used: false
		},
		photosCountCustomers: {
			id: "photos_count_customers_daily",
			name: i18n.t("Customer Photos Count"),
			description: i18n.t("The total number of media items that are currently live that have been uploaded by customers."),
			used: false
		},
		localPostViewsSearch: {
			id: "local_post_views_search_daily",
			name: i18n.t("Local Post Search Views"),
			description: i18n.t("The number of times the local post was viewed on Google Search."),
			used: false
		},
		localPostActionsCallToAction: {
			id: "local_post_actions_call_to_action_daily",
			name: i18n.t("Local Post Call To Action Clicks"),
			description: i18n.t("The number of times the call to action button was clicked on Google."),
			used: false
		}
	}
};
