import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";

import ConfirmBlock from "../../../components/common/ConfirmBlock";
import ContactSelectorUpdate from "../../../scenes/ContactSelector/ContactSelectorUpdate";

import { TAG_TYPES } from "../../../constants/Tags";

import "../../MessengerBeta/Thread/Message/message.css";
import "../../../styles/css/components/commons/bulk-actions.css";

import { CONFIRM_BLOCK_COLUMNS_CONTACT } from "../../../constants/ConfirmBlock";

const UPLOAD_LIMIT = 2000;

class UploadContactsConfirmBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTagsFilter: [],
			selectedGroupsFilter: [],
			allTagsFilter: []
		};
	}

	onTagsChange = selectedTagsFilter => {
		this.setState({ selectedTagsFilter, allTagsFilter: [...this.state.selectedGroupsFilter, ...selectedTagsFilter] });
	};

	onGroupsChange = selectedGroupsFilter => {
		this.setState({ selectedGroupsFilter, allTagsFilter: [...this.state.selectedTagsFilter, ...selectedGroupsFilter] });
	};

	render() {
		let { allTagsFilter } = this.state;
		let {
			bulkLoading,
			bulkActionSuccess,
			bulkActionError,
			bulkActionPartialSuccess,
			errorFeedback,
			successFeedback,
			onConfirm,
			onNext,
			back,
			csvContacts
		} = this.props;
		let { t } = this.props;

		return (
			<>
				<ConfirmBlock
					header={t("Are you sure you want to upload contacts?")}
					loading={bulkLoading}
					submitSuccess={bulkActionSuccess || bulkActionPartialSuccess}
					submitError={bulkActionError}
					successMessage={t("Contacts uploaded")}
					errorMessage={t("Error uploading contacts")}
					confirm={() => onConfirm(allTagsFilter)}
					next={onNext}
					back={() => {
						if (back) {
							back();
						}
					}}
					showFeedback={true}
					successFeedback={successFeedback}
					errorFeedback={errorFeedback}
					backLabel={t("Map Csv")}
					successFeedbackLabel={t("Successfully processed")}
					errorFeedbackLabel={t("Failed to create")}
					successBtnLabel={t("Done")}
					showNextButton={!csvContacts || csvContacts.length > UPLOAD_LIMIT ? false : true}
				>
					<div className="mb-message-box mb-message-box--out" style={{ maxWidth: "400px", margin: "Auto" }}>
						<p>
							{csvContacts
								? t("Up to {{contactsNumber}} contacts will be created.", { contactsNumber: csvContacts.length })
								: t("No contacts will be created.")}
						</p>
						{csvContacts && csvContacts.length > UPLOAD_LIMIT && (
							<p className="text-danger">
								{t("A maximum of {{UPLOAD_LIMIT}} contacts can be uploaded at a time. Please change the csv to have a maximum of {{UPLOAD_LIMIT}} rows.", {
									UPLOAD_LIMIT
								})}
							</p>
						)}
						<div>
							<span>{t("Place contacts into a group or tag:")}</span>
						</div>
						<br />
						<ContactSelectorUpdate showHeader={true} numSelectedContacts={0} onSelectChange={this.onTagsChange} type={TAG_TYPES.tag} showButtons={false} />
						<ContactSelectorUpdate showHeader={true} numSelectedContacts={0} onSelectChange={this.onGroupsChange} type={TAG_TYPES.group} showButtons={false} />
					</div>
				</ConfirmBlock>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(UploadContactsConfirmBlock);
