import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";
import moment from "moment";
import * as Icon from "react-feather";
import c3 from "c3";
import { CSVLink } from "react-csv";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

import UserService from "../../../services/UserService";
import { AnalyticsService } from "../../../services/AnalyticsService";
import { CsvService } from "../../../services/CsvService";
import UtilityService from "../../../services/UtilityService";
import ToastService from "../../../services/ToastService";

import Spinners from "../../../components/common/Spinners";
import withLocation from "../../../components/common/WithLocation";
import Action from "../../../components/common/Action";
import DashboardCard from "../../Dashboard/DashboardCard";

import { CHART_COLORS } from "../../../constants/CommonConstants";
import { DASHBOARD_CARDS } from "../../../constants/Dashboard";

import "../../../styles/css/scenes/leadSourceAnalytics.css";

class LeadsByDate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			start: moment()
				.subtract(30, "days")
				.startOf("day")
				.toDate(),
			end: moment()
				.endOf("day")
				.toDate(),
			data: [],
			leadSourceCsv: null
		};
	}

	componentDidMount() {
		this.refreshData();
	}

	onLocationChanged = location => {
		this.refreshData();
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.setState({ start });
	};

	handleToChange = end => {
		this.setState({ end }, this.showFromMonth);
	};

	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}

	handleUpdateDate() {
		this.refreshData();
	}

	async refreshData() {
		const { start, end } = this.state;

		await this.update({ loading: true });
		let result = await AnalyticsService.getLeadSourceAnalytics(UserService.getActiveLocation().id, start, end);

		if (!result) {
			ToastService.error("Error fetching Leads By Date data.");
			await this.update({ loading: false, data: [], leadSourceCsv: [] });

			return;
		}

		await this.update({ loading: false, data: result, leadSourceCsv: result });
		this.updateCharts();
	}

	async updateCharts() {
		let { data } = this.state;

		if (!data || data.length < 1) {
			return;
		}

		// Generate a color pattern for other charts
		// If there are more lead sources than colors, generate some random colors!
		let colors = [...CHART_COLORS];
		let length = data.length > colors.length ? data.length - colors.length : 0;
		colors = UtilityService.generateColorsArray({ colors, length });

		this.generateLeadsByDateChart(colors);
	}

	generateLeadsByDateChart = colors => {
		try {
			let { data, start, end } = this.state;
			let { t } = this.props;

			if (!data) {
				return;
			}
			const isMonth = moment(end).diff(moment(start), "days") >= 90;

			let dates = data.map(item => item.date);
			dates.unshift("x");
			let leadSources = Object.keys(data[0]);
			delete leadSources["date"];
			let statsPerSource = {};
			for (const item of data) {
				for (const key in item) {
					if (key === "date") {
						continue;
					}
					const count = item[key];
					if (!statsPerSource[key]) {
						statsPerSource[key] = [`${key}`]; // key is what will be shown on the graph
					}
					statsPerSource[key].push(count);
				}
			}

			let columns = [];
			columns.push(dates);
			for (const source in statsPerSource) {
				const stat = statsPerSource[source];
				columns.push(stat);
			}
			this.generateChart(
				"#lead-source-analytics-chart",
				{
					x: "x",
					columns: columns,
					type: "bar"
				},
				{
					x: {
						label: t("Day"),
						type: "timeseries",
						tick: {
							format: function(x) {
								if (isMonth) {
									const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
									return "" + x.getFullYear() + " " + monthString[x.getMonth()];
								}
								return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
							},
							rotate: window.innerWidth > 768 ? 0 : 75,
							multiline: false
						}
					},
					y: {
						label: t("Counts")
					}
				},
				{
					pattern: colors
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	createRandomColor() {
		var randomColor = "#000000".replace(/0/g, function() {
			return (~~(Math.random() * 16)).toString(16);
		});
		return randomColor;
	}

	generateChart(id, data, axis = {}, color = {}, legend = {}) {
		try {
			c3.generate({
				bindto: id,
				data: data,
				axis: axis,
				size: {
					height: 260
				},
				color: color,
				legend: legend
			});
		} catch (error) {
			console.log(`Error generate a chart - ${error.stack}`);
		}
	}

	renderHeader = () => {
		const { start, end, leadSourceCsv } = this.state;
		const { cardName } = this.props;
		const modifiers = { start, end };
		const locationName = CsvService.filterActiveLocationName();
		let { t } = this.props;

		return (
			<>
				<div className="dashboard__card__header dashboard__card__header--full-width">
					<div className="dashboard__card__header__name">{cardName ? cardName : t("Leads By Date Period")}</div>
					<div className="dashboard__card__header__actions">
						{leadSourceCsv && (
							<CSVLink data={leadSourceCsv} filename={`${locationName}-contact_lead_sources.csv`} target="_self">
								<Action label={t("Download")} icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
				</div>

				<div className="dashboard__card___datepicker">
					<label>{t("Date Range")}&nbsp;&nbsp;</label>
					<div className="input-group" id="datepicker">
						<div className="InputFromTo">
							<DayPickerInput
								value={start}
								placeholder={t(" From")}
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { after: end },
									toMonth: end,
									modifiers,
									numberOfMonths: 2,
									onDayClick: () => this.to.getInput().focus()
								}}
								onDayChange={start => this.handleFromChange(start)}
							/>{" "}
							<span className="InputFromTo-to">
								<DayPickerInput
									ref={el => (this.to = el)}
									value={end}
									placeholder={t(" To")}
									format="LL"
									formatDate={formatDate}
									parseDate={parseDate}
									dayPickerProps={{
										selectedDays: [start, { from: start, to: end }],
										disabledDays: { before: start },
										modifiers,
										month: start,
										fromMonth: start,
										numberOfMonths: 2
									}}
									onDayChange={this.handleToChange}
								/>
							</span>
						</div>
					</div>{" "}
					<div id="date-update" className="mb-button " onClick={e => this.handleUpdateDate(e)}>
						{t("Update Date")}
					</div>
				</div>
			</>
		);
	};

	renderBody = () => {
		const { loading } = this.state;
		const { isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		if (isEnabled && !isEnabled()) {
			return <div className="text-center">{t("Contacts is not enabled. Contact support to get started.")}</div>;
		}

		if (isPermissible && !isPermissible()) {
			return <div className="text-center">{t("Contact data restricted.")}</div>;
		}

		if (loading) {
			return (
				<div className="analytics__section__chart__spinner">
					<Spinners type="tail-fade" loading={true} size="60px" />
				</div>
			);
		}

		return (
			<div className="">
				<div id="lead-source-analytics-chart" />
			</div>
		);
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.leadsByDate.className} height={DASHBOARD_CARDS.leadsByDate.h}>
				{this.renderHeader()}

				{this.renderBody()}
			</DashboardCard>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(LeadsByDate)));
