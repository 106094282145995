import i18n from "../i18n/config.js";

export const CONTACT_COLUMNS = {
	contactSelect: {
		id: "contactSelect",
		value: "",
		sortable: false,
		width: "none",
		notClickable: true
	},
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: true,
		sortField: "name",
		width: 2
	},
	phone: {
		id: "phone",
		value: () => i18n.t("Phone"),
		sortable: true,
		sortField: "phone",
		width: 2,
		widthSm: 0
	},
	email: {
		id: "email",
		value: i18n.t("Email"),
		sortable: true,
		sortField: "email",
		width: 1,
		widthSm: 0
	},
	status: {
		id: "status",
		value: i18n.t("Status"),
		sortable: true,
		sortField: "status",
		width: 1,
		widthMd: 0
	},
	is_blocked: {
		id: "is_blocked",
		value: i18n.t("Blocked"),
		sortable: true,
		sortField: "is_blocked",
		width: 1,
		widthSm: 0
	},
	tags: {
		id: "tags",
		value: i18n.t("Tags"),
		sortable: false,
		sortField: "tags",
		width: 2,
		widthMd: 0
	},
	groups: {
		id: "groups",
		value: i18n.t("Groups"),
		sortable: false,
		sortField: "groups",
		width: 2,
		widthMd: 0
	},
	last_message_sent: {
		id: "last_message_sent",
		value: i18n.t("Last Message Sent/Received"),
		sortable: true,
		sortField: "last_message_sent",
		width: 2,
		widthMd: 0
	},
	actions: {
		id: "actions",
		value: i18n.t("Actions"),
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const SELECTED_CONTACTS_COLUMNS = {
	contactSelect: {
		id: "contactSelect",
		value: "",
		sortable: false,
		width: "none",
		notClickable: true
	},
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: false,
		sortField: "name",
		width: 2
	},
	phone: {
		id: "phone",
		value: i18n.t("Phone"),
		sortable: false,
		sortField: "phone",
		width: 2,
		widthSm: 0
	},
	email: {
		id: "email",
		value: i18n.t("Email"),
		sortable: false,
		sortField: "email",
		width: 1,
		widthSm: 0
	},
	status: {
		id: "status",
		value: i18n.t("Status"),
		sortable: false,
		sortField: "status",
		width: 1,
		widthMd: 0,
		widthSm: 0
	},
	is_blocked: {
		id: "is_blocked",
		value: i18n.t("Blocked"),
		sortable: false,
		sortField: "is_blocked",
		width: 1,
		widthSm: 0
	},
	tags: {
		id: "tags",
		value: i18n.t("Tags"),
		sortable: false,
		sortField: "tags",
		width: 2,
		widthMd: 0
	},
	groups: {
		id: "groups",
		value: i18n.t("Groups"),
		sortable: false,
		sortField: "groups",
		width: 2,
		widthMd: 0
	},
	last_message_sent: {
		id: "last_message_sent",
		value: i18n.t("Last Message Sent/Received"),
		sortable: false,
		sortField: "last_message_sent",
		width: 2,
		widthMd: 0
	},
	actions: {
		id: "actions",
		value: i18n.t("Actions"),
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const CONTACT_STATES = {
	active: "active",
	inactive: "inactive",
	deleted: "deleted"
};

export const CONTACT_STATES_OPTIONS = [
	{ value: "all", label: i18n.t("Show All") },
	{ value: CONTACT_STATES.active, label: i18n.t("Open") },
	{ value: CONTACT_STATES.inactive, label: i18n.t("Closed") }
];

export const CONTACT_BLOCK_OPTIONS = [
	{ value: "all", label: i18n.t("Show All") },
	{ value: "blocked", label: i18n.t("Blocked") },
	{ value: "not_blocked", label: i18n.t("Not Blocked") }
];

export const CONTACT_TABS = {
	contacts: {
		id: "contacts",
		value: i18n.t("Contacts"),
		route: "/contacts/selector"
	},
	groups: {
		id: "groups",
		value: i18n.t("Groups"),
		route: "/contacts/tags"
	},
	tags: {
		id: "tags",
		value: i18n.t("Tags"),
		route: "/contacts/groups"
	},
	customFields: {
		id: "customFields",
		value: i18n.t("Custom Fields"),
		route: "/contacts/custom-fields"
	}
};

export const CONTACT_SELECTOR_FILTERS = {
	all: {
		id: "all",
		display: i18n.t("All"),
		order: 1
	},
	selectedContacts: {
		id: "selectedContacts",
		display: i18n.t("Selected Contacts"),
		order: 2
	}
};

export const EDIT_CONTACT_TABS = {
	general: "general",
	advanced: "advanced",
	notes: "notes",
	actions: "actions",
	communication: "communication",
	appointments: "appointments",
	media: "media"
};

export const EDIT_CONTACT_ADD_NEW_MEDIUM_ERRORS = {
	email: {
		invalid: i18n.t("Email is invalid."),
		invalidFormat: i18n.t("Invalid email format."),
		alreadyExists: i18n.t("Email belongs to another contact.")
	},
	phone: {
		invalid: i18n.t("Phone is invalid."),
		invalidFormat: i18n.t("Invalid phone format."),
		alreadyExists: i18n.t("Phone belongs to another contact.")
	}
};

export const EDIT_CONTACT_APPOINTMENTS_COLUMNS = {
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: true,
		sortField: "name",
		width: 2
	},
	state: {
		id: "state",
		value: i18n.t("State"),
		sortable: true,
		sortField: "state",
		width: 1
	},
	booking_at: {
		id: "booking_at",
		value: i18n.t("Booking At"),
		sortable: true,
		sortField: "booking_at",
		width: 2
	}
};

export const CONTACT_ANALYTICS_TABS = {
	leadSource: {
		id: "leadSource",
		value: i18n.t("Lead Source")
	},
	subscriber: {
		id: "subscriber",
		value: i18n.t("Subscriber")
	}
};

export const MERGE_CONTACT_WIZARD_STEPS = {
	findContact: {
		step: 1,
		header: i18n.t("Find Contact")
	},
	findSecondContact: {
		step: 2,
		header: i18n.t("Find A Second Contact To Merge With")
	},
	mergeContact: {
		step: 3,
		header: i18n.t("Merge Contact")
	},
	confirmDetails: {
		step: 4,
		header: i18n.t("Confirm Details")
	}
};

export const MERGE_CONTACT_FIELDS = [
	"name",
	"phone",
	"email",
	"preferred_medium",
	"address_1",
	"address_2",
	"city",
	"state_prov",
	"country",
	"zip_postal",
	"dob",
	"language",
	"receive_marketing_emails",
	"receive_transactional_emails",
	"receive_feedback_emails",
	"receive_marketing_sms",
	"receive_transactional_sms",
	"receive_feedback_sms",
	"assigned_rep",
	"lead_source"
];

export const MERGE_CONTACT_FIELDS_LABELS = {
	name: i18n.t("Name"),
	phone: i18n.t("Phone"),
	email: i18n.t("email"),
	preferred_medium: i18n.t("Preferred Medium"),
	address_1: i18n.t("Address 1"),
	address_2: i18n.t("Address 2"),
	city: i18n.t("City"),
	state_prov: i18n.t("State/Province"),
	country: i18n.t("Country"),
	zip_postal: i18n.t("Zip/Postal Code"),
	dob: i18n.t("Date Of Birth"),
	language: i18n.t("Language"),
	receive_marketing_emails: i18n.t("Receive Marketing Emails"),
	receive_transactional_emails: i18n.t("Receive Transactional Emails"),
	receive_feedback_emails: i18n.t("Receive Feedback Emails"),
	receive_marketing_sms: i18n.t("Receive Marketing Sms"),
	receive_transactional_sms: i18n.t("Receive Transactional Sms"),
	receive_feedback_sms: i18n.t("Receive Feedback Sms"),
	assigned_rep: i18n.t("Assigned Rep"),
	lead_source: i18n.t("Lead Source")
};

export const CONTACT_GENDER = {
	unknown: "unknown",
	male: "male",
	female: "female"
};

export const CONTACT_GENDER_OPTIONS = [
	{
		value: "unknown",
		label: i18n.t("Unknown")
	},
	{
		value: "male",
		label: i18n.t("Male")
	},
	{
		value: "female",
		label: i18n.t("Female")
	}
];
