import React from "react";
import moment from "moment";
import * as Icon from "react-feather";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { withTranslation, Trans } from "react-i18next";

import Action from "../../../../components/common/Action";
import TeamChatMessage from "./TeamChatMessage";

class TeamChatScheduledMessagesListItem extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {};

		this.teamchatMessageRef = React.createRef();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidUpdate(prevProps) {
		if (!prevProps.editing && this.props.editing) {
			if (this.teamchatMessageRef && this.teamchatMessageRef.current) {
				this.teamchatMessageRef.current.onEditMessage();
			}

			// Make sure ref is there for us
			this.forceUpdate();
		} else if (prevProps.editing && !this.props.editing) {
			if (this.teamchatMessageRef && this.teamchatMessageRef.current) {
				this.teamchatMessageRef.current.onStopEditMessage();
			}

			// Make sure ref is there for us
			this.forceUpdate();
		}
	}

	renderDatePicker = () => {
		let { minSendAfter, sendAfter, onDateChange } = this.props;
		let { t } = this.props;

		let minTime = moment()
			.startOf("day")
			.toDate();

		if (moment().format("YYYY-MM-DD") === moment(sendAfter).format("YYYY-MM-DD")) {
			minTime = moment(minSendAfter).toDate();
		}

		return (
			<div className="mb-tcsm__item__date-picker">
				<DatePicker
					minDate={minSendAfter}
					placeholderText={t("Send Date")}
					selected={moment(sendAfter).toDate()}
					onChange={onDateChange}
					shouldCloseOnSelect={true}
					dateFormat="MMMM dd, yyyy"
					className="Common__input mb-send__picker__date"
				/>
				<DatePicker
					minDate={minSendAfter}
					minTime={minTime}
					maxTime={setHours(setMinutes(new Date(), 45), 23)}
					placeholderText={t("Send Time")}
					selected={moment(sendAfter).toDate()}
					onChange={onDateChange}
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={15}
					timeCaption="Time"
					shouldCloseOnSelect={true}
					dateFormat="h:mm aa"
					className="Common__input mb-send__picker__time"
				/>
			</div>
		);
	};

	render = () => {
		let { t, conversationId, conversations, editing, queuedMessage, onEditMessage, onDeleteMessage, onSave, onContentChange, onCancel } = this.props;

		let conversation = null;

		if (!conversationId) {
			conversation = conversations.find(o => o.id === queuedMessage.conversation_id);
		}

		return (
			<div className="mb-tcsm__item">
				<div className="mb-tcsm__item__header">
					<div className="mb-tcsm__item__header__text">
						{!conversationId && <div className="mb-tcsm__item__header__text__name">{conversation ? conversation.name : queuedMessage.Conversation.name} -</div>}
						<div className="mb-tcsm__item__header__text__send-after">
							{t("Scheduled to send on {{date}}", { date: moment(queuedMessage.send_after).format("dddd, MMMM Do YYYY @ h:mm a") })}
						</div>
					</div>

					<div className="mb-tcsm__item__header__actions">
						<Action id={`edit-${queuedMessage.id}`} label={t("Edit")} icon={Icon.Edit} onClick={onEditMessage} />
						<Action id={`delete-${queuedMessage.id}`} label={t("Delete")} icon={Icon.Trash2} onClick={onDeleteMessage} />
					</div>
				</div>

				{editing && this.renderDatePicker()}

				<div className="mb-tcsm__item__content">
					<TeamChatMessage
						ref={this.teamchatMessageRef}
						message={{
							event_type: "message",
							id: queuedMessage.id,
							content: queuedMessage.content,
							content_html: queuedMessage.content_html,
							content_json: queuedMessage.content_json,
							media: queuedMessage.media,
							reactions: null
						}}
						onContentChange={onContentChange}
						hideSaveControls={true}
						hideHoverOptions={true}
						hideDate={true}
						horizontalMedia={true}
						mediaMaxHeightOrWidth={100}
						mediaReadOnly={false}
					/>
				</div>

				{editing && (
					<div className="mb-tcsm__item__editing">
						<div className="mb-button mb-button--fit" onClick={onSave}>
							{t("Save")}
						</div>
						<div className="mb-button mb-button--cancel mb-button--fit" onClick={onCancel}>
							{t("Cancel")}
						</div>
					</div>
				)}
			</div>
		);
	};
}

export default withTranslation(null, { withRef: true })(TeamChatScheduledMessagesListItem);
