import React from "react";
import * as Icon from "react-feather";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import Action from "../../../components/common/Action";
import Filters from "../../../components/common/Filters";
import List from "../../../components/common/List";
import SearchInput from "../../../components/common/SearchInput";
import ManageShortLinkModal from "./ManageShortLinkModal";
import Alert from "../../../components/common/Alert";
import withLocation from "../../../components/common/WithLocation";

import ShortLinkService from "../../../services/ShortLinkService";
import UserService from "../../../services/UserService";
import ToastService from "../../../services/ToastService";
import GAService from "../../../services/GAService";

import {
	SHORT_LINK_COLUMNS,
	SHORT_LINK_TYPES,
	SHORT_LINK_TYPE_LABEL,
	SHORT_LINK_TYPE_FILTERS,
	SHORT_LINK_EXPIRED_FILTERS,
	SHORT_LINK_LIMIT
} from "../../../constants/ShortLinksConstants";
import { SORT_ORDER, STATUS } from "../../../constants/CommonConstants";
import { GA_CATEGORIES, GA_ACTIONS } from "../../../constants/GAConstants";

class ShortLinks extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			loadedAll: false,

			searchTerm: "",
			sortField: SHORT_LINK_COLUMNS.name.sortField,
			sortOrder: SORT_ORDER.desc,
			selectedTypeFilter: SHORT_LINK_TYPES.custom,
			selectedExpiredFilter: SHORT_LINK_EXPIRED_FILTERS.valid,
			limit: 50,
			pageSize: 50,

			data: [],
			customShortLinkCount: 0,
			selectedShortLinkId: null,
			showManageShortLinkModal: false,
			showDeleteAlert: false
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });

		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		// Reset the rest of the states
		this.update({
			selectedShortLinkId: null,
			showManageShortLinkModal: false,
			searchTerm: "",
			sortField: SHORT_LINK_COLUMNS.name.sortField,
			sortOrder: SORT_ORDER.desc,
			selectedTypeFilter: SHORT_LINK_TYPE_FILTERS.custom.id,
			selectedExpiredFilter: SHORT_LINK_EXPIRED_FILTERS.valid.id,
			limit: 50,
			pageSize: 50
		});
		await this.update({ loading: true });
		await this.fetchData();
		await this.update({ loading: false });
	};

	onLocationChanged = location => {
		this.resetComponent();
	};

	fetchData = async () => {
		const { searchTerm, selectedTypeFilter, selectedExpiredFilter, sortField, sortOrder, limit } = this.state;
		let { t } = this.props;

		let params = {
			locationId: UserService.getActiveLocation().id,
			type: selectedTypeFilter,
			searchTerm,
			sortField,
			sortOrder,
			limit
		};

		if (selectedExpiredFilter === SHORT_LINK_EXPIRED_FILTERS.valid.id || selectedExpiredFilter === SHORT_LINK_EXPIRED_FILTERS.expired.id) {
			params.includeExpired = selectedExpiredFilter === SHORT_LINK_EXPIRED_FILTERS.expired.id;
		}

		let data = await ShortLinkService.fetch(params);

		if (!data) {
			ToastService.error(t("Error fetching links. Please try again."));
			await this.update({ loading: false });
			return;
		}

		let customData = await ShortLinkService.fetch({
			locationId: UserService.getActiveLocation().id,
			type: SHORT_LINK_TYPES.custom.id,
			limt: SHORT_LINK_LIMIT
		});

		await this.update({ data: data, loadedAll: data.length < limit, customShortLinkCount: customData ? customData.length : 0 });
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		await this.fetchData();
	};

	onEditShortLink = async shortLink => {
		await this.update({
			selectedShortLinkId: shortLink.id
		});
		GAService.GAEvent({
			category: GA_CATEGORIES.settings.sections.shortLinks,
			action: GA_ACTIONS.generic.buttonClick,
			label: `Edit Short Link`
		});
		await this.showCreateShortLinkModal();
	};

	onCreateShortLink = async shortLink => {
		GAService.GAEvent({
			category: GA_CATEGORIES.settings.sections.shortLinks,
			action: GA_ACTIONS.generic.buttonClick,
			label: `Create Short Link`
		});
		await this.showCreateShortLinkModal();
	};

	showCreateShortLinkModal = () => {
		this.update({ showManageShortLinkModal: true });
	};

	closeManageShortLinkModal = () => {
		this.update({ showManageShortLinkModal: false, selectedShortLinkId: null });
	};

	onDeleteShortLink = async shortLink => {
		await this.update({
			selectedShortLinkId: shortLink.id,
			showDeleteAlert: true
		});
	};

	onCloseDeleteAlert = async confirm => {
		let { t } = this.props;

		if (confirm) {
			let response = await ShortLinkService.update({
				id: this.state.selectedShortLinkId,
				locationId: UserService.getActiveLocation().id,
				status: STATUS.deleted
			});

			if (!response) {
				ToastService.error(t("Error deleting short link. Please try again."));
			} else {
				ToastService.info(t("Short link deleted."));
				this.fetchData();
			}
		}
		this.update({ showDeleteAlert: false, selectedShortLinkId: null });
	};

	onHideManageShortLinkModal = updated => {
		let { t } = this.props;

		if (updated) {
			ToastService.info(t("Short link saved."));
			this.fetchData();
		}
		this.closeManageShortLinkModal();
	};

	onTypeFilterSelected = async item => {
		await this.update({ selectedTypeFilter: item.id });
		await this.fetchData();
	};

	isTypeFilterSelected = item => {
		return item === this.state.selectedTypeFilter;
	};

	onExpiredFilterSelected = async item => {
		await this.update({ selectedExpiredFilter: item.id });
		await this.fetchData();
	};

	isExpiredFilterSelected = item => {
		return item === this.state.selectedExpiredFilter;
	};

	getFilters() {
		let { t } = this.props;

		let typeItems = Object.keys(SHORT_LINK_TYPE_FILTERS)
			.map(item => {
				return { id: SHORT_LINK_TYPE_FILTERS[item].id, value: SHORT_LINK_TYPE_FILTERS[item].display, order: SHORT_LINK_TYPE_FILTERS[item].order };
			})
			.sort((a, b) => a.order - b.order);

		let expiredItems = Object.keys(SHORT_LINK_EXPIRED_FILTERS)
			.map(item => {
				return { id: SHORT_LINK_EXPIRED_FILTERS[item].id, value: SHORT_LINK_EXPIRED_FILTERS[item].display, order: SHORT_LINK_EXPIRED_FILTERS[item].order };
			})
			.sort((a, b) => a.order - b.order);

		let filters = {
			type: {
				title: t("Type"),
				items: typeItems,
				onClick: this.onTypeFilterSelected,
				isSelected: this.isTypeFilterSelected
			},
			expired: {
				title: t("Expired"),
				items: expiredItems,
				onClick: this.onExpiredFilterSelected,
				isSelected: this.isExpiredFilterSelected
			}
		};
		return filters;
	}

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchData();
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ sortField, sortOrder });
		await this.fetchData();
	};

	getHeaders = () => {
		let headers = {
			items: SHORT_LINK_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	};

	onRecordClicked = () => {
		//
	};

	renderRecord = recordData => {
		let { t } = this.props;

		return [
			recordData.name,
			SHORT_LINK_TYPE_LABEL[recordData.type] ? SHORT_LINK_TYPE_LABEL[recordData.type] : recordData.type,
			recordData.short_url,
			recordData.long_url,
			recordData.open_count,
			recordData.last_open_time ? moment(recordData.last_open_time).format("MMM Do YYYY hh:mm a") : "",
			recordData.expire_at ? moment(recordData.expire_at).format("MMM Do YYYY hh:mm a") : "",
			<div className="dh-list__actions">
				<Action icon={Icon.Edit} id={`edit-${recordData.id}`} onClick={() => this.onEditShortLink(recordData)} label={t("Edit Short Link")} />
				<Action icon={Icon.Trash2} id={`delete-${recordData.id}`} onClick={() => this.onDeleteShortLink(recordData)} label={t("Delete")} />
			</div>
		];
	};

	render = () => {
		const {
			showManageShortLinkModal,
			selectedTypeFilter,
			customShortLinkCount,
			selectedShortLinkId,
			data,
			loading,
			loadedAll,
			sortField,
			sortOrder,
			showDeleteAlert
		} = this.state;
		let { t } = this.props;

		return (
			<Page>
				<Header title={t("Short Links")}>
					{customShortLinkCount < SHORT_LINK_LIMIT && (
						<Action id="new-short-link" label={t("Add Custom Short Link")} icon={Icon.Plus} onClick={this.onCreateShortLink} />
					)}
				</Header>
				<div className="settings-search">
					<SearchInput placeholder={t("Search ...")} onChange={this.onSearchChange} />
					<div className="settings-search__limit">{!loading ? `Custom limit: ${customShortLinkCount}/${SHORT_LINK_LIMIT}` : null}</div>
				</div>

				<Filters filters={this.getFilters()} />
				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={t("Looks like there are no custom short links yet...")}
					noDataIcon={<Icon.AlertCircle />}
				/>

				<ManageShortLinkModal show={showManageShortLinkModal} id={selectedShortLinkId ? selectedShortLinkId : null} onHide={this.onHideManageShortLinkModal} />

				<Alert
					type="warning"
					show={showDeleteAlert}
					title={t("Are you sure?")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={confirm => this.onCloseDeleteAlert(confirm)}
				>
					<div>{t("Are you sure you would like to delete this short link?")}</div>
				</Alert>
			</Page>
		);
	};
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(ShortLinks)));
