import i18n from "../i18n/config";

export const LAGGING_LOCATIONS_FILTERS = {
	all: {
		id: "all",
		display: i18n.t("All"),
		order: 1
	},
	favourites: {
		id: "favourites",
		display: i18n.t("Favourites"),
		order: 2
	}
};

export const LAGGING_LOCATIONS_COLUMNS = {
	company_name: {
		id: "company_name",
		value: i18n.t("Company"),
		sortable: true,
		sortField: "company_name",
		width: 1
	},
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: true,
		sortField: "name",
		width: 1
	},
	last_review_request: {
		id: "last_review_request",
		value: i18n.t("Last Review Request"),
		sortable: true,
		sortField: "last_review_request",
		width: 1
	},
	last_auto_review_request: {
		id: "last_auto_review_request",
		value: i18n.t("Last Auto Review Request"),
		sortable: true,
		sortField: "last_auto_review_request",
		width: 1
	},
	oldest_unread_date: {
		id: "oldest_unread_date",
		value: i18n.t("Oldest Unread"),
		sortable: true,
		sortField: "oldest_unread_date",
		width: 1
	},
	last_message_date: {
		id: "last_message_date",
		value: i18n.t("Last Message"),
		sortable: true,
		sortField: "last_message_date",
		width: 1
	},
	webchat_status: {
		id: "webchat_status",
		value: i18n.t("Webchat"),
		sortable: true,
		sortField: "webchat_status",
		width: 1
	},
	actions: {
		id: "actions",
		value: i18n.t("Actions"),
		sortable: false,
		width: 1,
		notClickable: true
	}
};
