// FilePastePlugin.jsx
import React from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

/**
 * Low priority to ensure this plugin runs after others
 */
const LOW_PRIORITY = 1;

/**
 * Class-based component to handle paste events
 */
class FilePastePluginClass extends React.Component {
	componentDidMount() {
		const { editor, onFilePaste } = this.props;

		// Define the paste event handler
		this.handlePaste = event => {
			if (event.clipboardData?.files?.length > 0) {
				onFilePaste?.(event);
			}
		};

		// Get the root DOM element from the editor
		const rootElement = editor.getRootElement();
		if (!rootElement) {
			return;
		}

		// Attach the paste event listener
		rootElement.addEventListener("paste", this.handlePaste, false);
	}

	componentWillUnmount() {
		const { editor } = this.props;

		// Get the root DOM element from the editor
		const rootElement = editor.getRootElement();
		if (!rootElement || !this.handlePaste) {
			return;
		}

		// Remove the paste event listener
		rootElement.removeEventListener("paste", this.handlePaste, false);
	}

	render() {
		// This plugin doesn't render anything
		return null;
	}
}

/**
 * Functional wrapper to integrate with Lexical's context
 */
function FilePastePlugin({ onFilePaste }) {
	const [editor] = useLexicalComposerContext();

	return <FilePastePluginClass editor={editor} onFilePaste={onFilePaste} />;
}

export default FilePastePlugin;
