import React, { Component } from "react";
import * as Icon from "react-feather";
import posed from "react-pose";
import { withTranslation, Trans } from "react-i18next";

import MessagesService from "../../../../services/MessagesService";
import ToastService from "../../../../services/ToastService";

import AttachmentItem from "./../../../../components/common/AttachmentItem";

import { ATTACHMENT_LOADING, ATTACHMENT_MEDIA } from "../../../../constants/Attachments";
import { KEYS } from "../../../../constants/Messenger";

import "./attachments.css";

const Box = posed.div({
	visible: {
		y: -125,
		opacity: 1
	},
	hidden: {
		y: 350,
		opacity: 0
	}
});

class Attachments extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			loading: false,
			mediaIds: [],
			attachmentsChanged: false,
			showAttachmentList: false,
			isDraggingFile: false
		};

		this.uploadInput = React.createRef();
		this.dragDropArea = React.createRef();
	}

	componentDidMount() {
		document.addEventListener("keydown", this.onKey);

		if (this.dragDropArea) {
			try {
				this.dragDropArea.addEventListener("dragover", this.onDragOver);
				this.dragDropArea.addEventListener("dragenter", this.onDragEnter);
				this.dragDropArea.addEventListener("dragleave", this.onDragLeave);
				this.dragDropArea.addEventListener("drop", this.onFileDrop);
			} catch (error) {
				console.log(error);
			}
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKey);
		if (this.dragDropArea && this.dragDropArea.current) {
			this.dragDropArea.removeEventListener("dragover", this.onDragOver);
			this.dragDropArea.removeEventListener("drop", this.onFileDrop);
			this.dragDropArea.removeEventListener("dragenter", this.onDragEnter);
			this.dragDropArea.removeEventListener("dragleave", this.onDragLeave);
		}
	}

	onKey = e => {
		if (e.keyCode === KEYS.esc) {
			e.preventDefault();
			this.hide();
		}
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	show = () => {
		this.uploadInput.click();
	};

	hide = async () => {
		let { disabled } = this.props;

		if (disabled) {
			return;
		}

		await this.update({
			show: false,
			attachmentsChanged: false,
			mediaIds: []
		});

		if (this.props.onChange) {
			this.props.onChange(this.state.mediaIds);
		}
	};

	onDragOver = async event => {
		await this.update({ isDraggingFile: true });

		event.preventDefault();
	};

	onDragEnter = async event => {
		let { isDraggingFile } = this.state;

		if (isDraggingFile) {
			return;
		}

		await this.update({ isDraggingFile: true });
	};

	onDragLeave = async event => {
		let { isDraggingFile } = this.state;

		if (!isDraggingFile) {
			return;
		}

		await this.update({ isDraggingFile: false });
	};

	onFileDrop = async event => {
		try {
			await this.update({ isDraggingFile: false });

			let files = event.dataTransfer.files;

			if (files) {
				this.showAttachment(files);
				event.preventDefault();
				event.stopPropagation();
			}
		} catch (error) {
			console.error(`Attachments.js - File Drag/Drop Error - ${error}`);
		}
	};

	showAttachment = async (files, clearAttachments = false) => {
		let { mediaIds } = this.state;
		let { t } = this.props;

		if (!files) {
			return;
		}

		if (!mediaIds || clearAttachments) {
			mediaIds = [];
		}

		let attachmentTooLargeFound = false;
		let mediaLimitReached = false;

		let sizeLimit = this.props.sizeLimit ? this.props.sizeLimit : ATTACHMENT_MEDIA.sizeLimit;

		for (const file of files) {
			// If we have reached the limit of attachments
			if (mediaIds.length >= ATTACHMENT_MEDIA.limit) {
				continue;
			}

			// Don't add this file if it is too large, but we will continue looking at the other files
			if (file.size > sizeLimit) {
				attachmentTooLargeFound = true;
				continue;
			}

			let localMediaId = MessagesService.storeLocalMedia({ file }).id;
			mediaIds.push(localMediaId);
		}

		// Large files or files after the attachment limit has been reached will not be added
		if (attachmentTooLargeFound) {
			ToastService.info(t("An attachment is too big. Attachments must be less than {{sizeInMB}}MB.", { sizeInMB: this.bytesToMegaBytes(sizeLimit) }));
		}
		if (mediaLimitReached) {
			ToastService.info(t("A limit of {{numberOfAttachments}} attachments has been reached.", { numberOfAttachments: ATTACHMENT_MEDIA.limit }));
		}

		await this.update({
			show: true,
			mediaIds,
			attachmentsChanged: true
		});

		if (this.props.onChange) {
			this.props.onChange(mediaIds);
		}
	};

	bytesToMegaBytes = bytes => {
		return bytes / (1000 * 1000);
	};

	importMediaIds = async mediaIds => {
		await this.update({
			show: true,
			mediaIds,
			attachmentsChanged: true
		});

		if (this.props.onChange) {
			this.props.onChange(mediaIds);
		}
	};

	onFileUpload = async event => {
		let files = event.target.files;

		let { mediaIds } = this.state;
		// Remove the first loading attachment we find
		let index = mediaIds.indexOf(ATTACHMENT_LOADING);
		if (index >= 0) {
			mediaIds.splice(index, 1);
		}

		await this.update({
			mediaIds
		});

		if (files) {
			this.showAttachment(files);
		}
	};

	onHideAttachment = async mediaId => {
		let { mediaIds } = this.state;

		// remove this media id from the list
		let index = mediaIds.indexOf(mediaId);
		if (index >= 0) {
			mediaIds.splice(index, 1);
		}

		await this.update({
			attachmentsChanged: true,
			mediaIds
		});

		if (this.props.onChange) {
			this.props.onChange(mediaIds);
		}
	};

	onHandleFocusBack = () => {
		// If not media is selected, we remove all "loading" media
		let { mediaIds } = this.state;
		mediaIds = mediaIds.filter(m => m !== ATTACHMENT_LOADING);
		this.update({
			mediaIds
		});

		if (this.props.onChange) {
			this.props.onChange(mediaIds);
		}

		window.removeEventListener("focus", this.onHandleFocusBack);
	};

	onInputClick = async event => {
		event.target.value = null;

		let { mediaIds } = this.state;
		mediaIds.push(ATTACHMENT_LOADING);
		await this.update({
			mediaIds
		});

		window.addEventListener("focus", this.onHandleFocusBack);
	};

	boxStyles = () => {
		let { inputComposeHeight, isRichtext } = this.props;

		if (!inputComposeHeight) {
			return null;
		}

		if (isRichtext) {
			return { marginBottom: `150px` };
		} else {
			return { marginBottom: `${inputComposeHeight - 30}px` };
		}
	};

	render() {
		let { show, mediaIds, isDraggingFile } = this.state;
		let boxStyles = this.boxStyles();

		let classes = "mb-attachments";

		// If we want to place this attachements beside another container
		if (this.props.placed) {
			classes += " mb-attachments--placed";
			boxStyles = { bottom: "10px", left: "-30px" };
		}

		return (
			<Box className={classes} pose={show ? "visible" : "hidden"} style={boxStyles} ref={ref => (this.dragDropArea = ref)}>
				<div className="mb-attachments__list">
					{mediaIds.map((mediaId, index) => (
						<AttachmentItem
							key={mediaId === ATTACHMENT_LOADING ? `${ATTACHMENT_LOADING}-${index}` : mediaId}
							mediaId={mediaId === ATTACHMENT_LOADING ? null : mediaId}
							hide={() => this.onHideAttachment(mediaId)}
							loading={mediaId === ATTACHMENT_LOADING ? true : false}
						/>
					))}
					{mediaIds && mediaIds.length < ATTACHMENT_MEDIA.limit && (
						<div className={`mb-attachments__add ${isDraggingFile ? `mb-attachments__add--dragging` : ``}`} onClick={this.show}>
							<Icon.PlusCircle size="36" />
						</div>
					)}
				</div>

				<div className="mb-attachments__x" onClick={this.hide}>
					<Icon.X size="18" />
				</div>
				<input
					className="mb-attachments-input"
					ref={ref => (this.uploadInput = ref)}
					type="file"
					onChange={this.onFileUpload}
					onClick={this.onInputClick}
					multiple
				/>
			</Box>
		);
	}
}

export default withTranslation(null, { withRef: true })(Attachments);
