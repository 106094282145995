import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";
import moment from "moment";
import c3 from "c3";
import * as Icon from "react-feather";
import { CSVLink } from "react-csv";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

import UserService from "../../../services/UserService";
import UtilityService from "../../../services/UtilityService";
import { CsvService } from "../../../services/CsvService";
import { AnalyticsService } from "../../../services/AnalyticsService";
import GAService from "../../../services/GAService";

import withLocation from "../../../components/common/WithLocation";
import Action from "../../../components/common/Action";
import Spinners from "../../../components/common/Spinners";
import DashboardCard from "../../Dashboard/DashboardCard";

import { CHART_COLORS } from "../../../constants/CommonConstants";
import { DASHBOARD_CARDS } from "../../../constants/Dashboard";

class SubscriberAnalytics extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			totalSubscribeCount: 0,
			totalUnsubscribeCount: 0,
			csvData: null,
			loading: false,
			start: moment()
				.subtract(30, "days")
				.startOf("day")
				.toDate(),
			end: moment()
				.endOf("day")
				.toDate()
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	onLocationChanged = location => {
		this.resetComponent();
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	resetComponent = async () => {
		let locationId = UserService.getActiveLocation().id;

		await this.update({ loading: true });

		const { start, end } = this.state;

		let result = await AnalyticsService.getSubscriberAnalytics({ locationId, start, end });

		await this.update({ loading: false, data: result });
		this.updateCharts();
	};

	updateCharts() {
		let { data, start, end } = this.state;
		let { t } = this.props;

		if (!data || data.length < 1) {
			return;
		}

		// Generate a color pattern for other charts
		let colors = [...CHART_COLORS];

		// if there are more lead sources than colors, generate some random colors!
		for (let i = colors.length; i < data.length; i++) {
			colors.push(UtilityService.createRandomColor());
		}

		let dates = ["x"];
		let subscribeCount = [];
		let unsubscribeCount = [];

		for (let i = 0; i < data.length; i++) {
			const item = data[i];
			dates.push(item.date);
			subscribeCount.push(item.subscribe_count);
			unsubscribeCount.push(item.unsubscribe_count);
		}

		const isMonth = moment(end).diff(moment(start), "days") >= 90;

		this.update({
			totalSubscribeCount: subscribeCount.reduce((a, b) => a + b, 0),
			totalUnsubscribeCount: unsubscribeCount.reduce((a, b) => a + b, 0)
		});

		subscribeCount.unshift(t("Subscribe Count"));
		unsubscribeCount.unshift(t("Unsubscribe Count"));

		let columns = [dates, subscribeCount, unsubscribeCount];

		this.generateChart(
			"#subscription-bar-chart",
			{
				x: "x",
				columns: columns,
				type: "bar"
				// labels: true
			},
			{
				x: {
					label: t("Day"),
					type: "timeseries",
					tick: {
						format: function(x) {
							if (isMonth) {
								const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
								return "" + x.getFullYear() + " " + monthString[x.getMonth()];
							}
							return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
						},
						rotate: window.innerWidth > 768 ? 0 : 75,
						multiline: false
					}
				},
				y: {
					label: t("Counts")
				}
			},
			{
				pattern: colors
			}
		);
	}

	generateChart(id, data, axis = {}, color = {}, legend = {}) {
		try {
			c3.generate({
				bindto: id,
				data: data,
				axis: axis,
				size: {
					height: 260
				},
				color: color,
				legend: legend
			});
		} catch (error) {
			console.log(`Error generate a chart - ${error.stack}`);
		}
	}

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.update({ start });
	};

	handleToChange = end => {
		this.update({ end }, this.showFromMonth);
	};

	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}

	renderDatePicker = () => {
		let { start, end } = this.state;
		const modifiers = { start, end };
		const { isCard } = this.props;
		let { t } = this.props;

		return (
			<div className="dashboard__card___datepicker">
				<label>{t("Date Range")}&nbsp;&nbsp;</label>
				<div className="input-group" id="datepicker">
					<div className="InputFromTo">
						<DayPickerInput
							value={start}
							placeholder={t(" From")}
							format="LL"
							formatDate={formatDate}
							parseDate={parseDate}
							dayPickerProps={{
								selectedDays: [start, { from: start, to: end }],
								disabledDays: { after: end },
								toMonth: end,
								modifiers,
								numberOfMonths: 2,
								onDayClick: () => this.to.getInput().focus()
							}}
							onDayChange={start => this.handleFromChange(start)}
						/>{" "}
						<span className="InputFromTo-to">
							<DayPickerInput
								ref={el => (this.to = el)}
								value={end}
								placeholder={t(" To")}
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { before: start },
									modifiers,
									month: start,
									fromMonth: start,
									numberOfMonths: 2
								}}
								onDayChange={this.handleToChange}
							/>
						</span>
					</div>
				</div>{" "}
				<div id="date-update" className="mb-button " onClick={this.resetComponent}>
					{t("Update Date")}
				</div>
			</div>
		);
	};

	renderLoading = () => {
		return (
			<div className="analytics__section__chart__spinner">
				<Spinners type="tail-fade" loading={true} size="60px" />
			</div>
		);
	};

	renderHeader = () => {
		let { data, start, end } = this.state;
		const locationName = CsvService.filterActiveLocationName();
		let { t } = this.props;
		let { cardName } = this.props;

		return (
			<>
				<div className="dashboard__card__header dashboard__card__header--full-width">
					<div className="dashboard__card__header__name">{cardName ? cardName : t("Subscriber Data")}</div>
					<div className="dashboard__card__header__actions">
						{data && (
							<CSVLink
								data={data}
								filename={`${locationName}-contact_subscribers_${moment(start).format("YYYY-MM-DD")}_to_${moment(end).format("YYYY-MM-DD")}.csv`}
								target="_self"
							>
								<Action label={t("Download")} icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
				</div>
				{this.renderDatePicker()}
			</>
		);
	};

	renderCharts = () => {
		let { totalSubscribeCount, totalUnsubscribeCount } = this.state;
		let { t } = this.props;

		return (
			<div>
				<div id="subscription-bar-chart" />
				<div className="text-center">
					Total Subscribe Count: {totalSubscribeCount}, Total Unsubscribe Count: {totalUnsubscribeCount}
					{t("Total Subscribe Count")}: {totalSubscribeCount}, {t("Total Unsubscribe Count")}: {totalUnsubscribeCount}
				</div>
			</div>
		);
	};

	renderBody = () => {
		let { loading } = this.state;
		let { isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		if (isEnabled && !isEnabled()) {
			return <div className="text-center">{t("Contacts is not enabled. Contact support to get started.")}</div>;
		}

		if (isPermissible && !isPermissible()) {
			return <div className="text-center">{t("Contact data restricted.")}</div>;
		}

		if (loading) {
			return this.renderLoading();
		}

		return this.renderCharts();
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.subscriberAnalytics.className} height={DASHBOARD_CARDS.subscriberAnalytics.h}>
				{this.renderHeader()}

				<div>{this.renderBody()}</div>
			</DashboardCard>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(SubscriberAnalytics)));
