import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import Page from "../../components/common/Page";
import SearchInput from "../../components/common/SearchInput";
import List from "../../components/common/List";
import Action from "../../components/common/Action";
import Alert from "../../components/common/Alert";
import DHSelect from "../../components/common/DHSelect";
import withLocation from "../../components/common/WithLocation";
import Checkbox from "../../components/common/Checkbox";
import { withTranslation } from "react-i18next";

import UserFilters from "./UserFilters";

import UtilityService from "../../services/UtilityService";
import ToastService from "../../services/ToastService";
import GAService from "../../services/GAService";
import UserService from "../../services/UserService";

import { SORT_ORDER, STATUS, STATUS_VERB } from "../../constants/CommonConstants";
import { USERS_COLUMNS } from "../../constants/Users";

import "../../styles/css/scenes/users.css";

class Users extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			loadedAll: true,
			data: [],
			searchTerm: "",
			sortField: USERS_COLUMNS.name.id,
			sortOrder: SORT_ORDER.asc,
			limitDefault: 25,
			limit: 25,
			pageSize: 50,
			showConfirmResetPasswordsModal: false,
			showChangeUserStatusModal: false,
			selectedUserToStatus: "",
			selectedUser: null,
			showUserFilters: false,
			locationIds: [],
			filterLocationIds: [],
			filterAccountTypes: [],
			showConfirmReassignModal: false,
			reassignedUser: null,

			selectedUsers: {},
			selectAll: false
		};
	}

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	resetComponent = async () => {
		await this.update({ loading: true });
		await this.fetchData();
		await this.update({ loading: false });
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = async location => {
		await this.resetComponent();
	};

	fetchData = async () => {
		let company = UserService.getActiveCompany();
		let user = UserService.get();

		let { limit, filterLocationIds, filterAccountTypes, searchTerm, sortField, sortOrder } = this.state;
		await this.update({ loading: true });
		let data = await UserService.fetchUsers({
			limit,
			companyId: company.id,
			userId: user.id,
			locationIds: filterLocationIds,
			groupIds: filterAccountTypes,
			searchTerm: searchTerm,
			sortField,
			sortOrder
		});

		await this.update({
			data: data.users || [],
			locationIds: data.locations || [],
			loading: false,
			loadedAll: data.users && data.users.length < limit
		});
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		await this.fetchData();
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchData();
	};

	async handleFiltersOnSubmit(locationIds, groupIds) {
		await this.update({
			filterLocationIds: locationIds,
			filterAccountTypes: groupIds
		});
		this.fetchData();
	}

	onFilterClick = async () => {
		await this.update({ showUserFilters: true });
	};

	onNewUserClick = async () => {
		this.props.history.push(`/settings/users/create`);
	};

	onInviteUsersClick = async () => {
		this.props.history.push(`/invite-users`);
	};

	onRecordClicked = async item => {};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ loading: true, sortField, sortOrder });
		await this.fetchData();
		await this.update({ loading: false });
	};

	getHeaders = () => {
		let { selectAll } = this.state;
		let columns = { ...USERS_COLUMNS };

		if (UserService.isSuperAdmin()) {
			// Add contact select as the first obj
			columns = Object.assign(
				{
					usersSelect: {
						id: "usersSelect",
						value: (
							<span className="contacts__record-checkbox">
								<Checkbox id={`select-all-checkbox`} name={`select-all-checkbox`} checked={selectAll} onChange={() => this.selectAllUsers()} />
							</span>
						),
						sortable: false,
						width: "none",
						notClickable: true
					}
				},
				columns
			);
		}

		let headers = {
			items: columns,
			sortBy: this.sortBy
		};

		return headers;
	};

	selectAllUsers = async () => {
		let { data, selectAll } = this.state;
		selectAll = !selectAll;

		let selectedUsers = {};
		let selectPromises = [];

		for (let i = 0; i < data.length; i++) {
			const user = data[i];
			selectPromises.push(this.onUserSelected(user.id, selectAll, user, false));
		}

		if (selectPromises.length > 0) {
			await Promise.all(selectPromises);
			selectedUsers = this.state.selectedUsers;
		}

		await this.update({ selectedUsers, selectAll: selectAll });
	};

	onUserSelected = async (id, checked, user, updateUser = false) => {
		let { selectedUsers, selectAll } = this.state;

		if (checked) {
			selectedUsers[`${id}`] = true;
		} else {
			delete selectedUsers[`${id}`];
			if (selectAll) {
				await this.update({ selectAll: false });
			}
		}

		if (updateUser) {
			await this.update({ selectedUsers });
			return;
		}
	};

	handleOnUserSelect = (event, user) => {
		let { checked } = event.target;
		this.onUserSelected(user.id, checked, user, true);
	};

	confirmPasswordReset = () => {
		this.update({ showConfirmResetPasswordsModal: true });
	};

	onResetPasswords = async confirmed => {
		let { selectedUsers } = this.state;

		if (!confirmed) {
			this.update({ showConfirmResetPasswordsModal: false });
			return;
		}

		let result = await UserService.resetUserPasswords(Object.keys(selectedUsers).map(id => parseInt(id)));

		if (!result) {
			ToastService.info(`Error resetting passwords. Please try again.`);
			return;
		}

		ToastService.info(`User passwords reset.`);

		this.update({ selectedUsers: {}, selectAll: false, showConfirmResetPasswordsModal: false });
	};

	onChangeStatusAlertClose = async confirmed => {
		let { selectedUserToStatus } = this.state;

		if (confirmed && selectedUserToStatus === STATUS.deleted) {
			this.deleteUser();
		}
		if (confirmed && selectedUserToStatus === STATUS.active) {
			this.setUserStatus();
		}
		if (confirmed && selectedUserToStatus === STATUS.inactive) {
			this.setUserStatus();
		}

		await this.update({
			selectedUser: null,
			selectedUserToStatus: "",
			showChangeUserStatusModal: false
		});
	};

	async setUserStatus() {
		let { selectedUser, selectedUserToStatus } = this.state;

		if (!selectedUser) {
			return;
		}

		let { t } = this.props;

		let response = await UserService.updateUser({
			userId: selectedUser.id,
			company: selectedUser.company_id,
			group: selectedUser.group_id,
			status: selectedUserToStatus,
			admin_update: true
		});

		if (response) {
			ToastService.info(t("User updated."));
		} else {
			ToastService.error(t("Error updating user"));
		}

		await this.update({
			selectedUser: null,
			selectedUserToStatus: "",
			showChangeUserStatusModal: false
		});

		await this.fetchData();
	}

	async deleteUser() {
		let { selectedUser, reassignedUser } = this.state;
		let { t } = this.props;

		let response = await UserService.deleteUser({
			userId: selectedUser.id,
			reassignedUserId: reassignedUser ? reassignedUser.value : null
		});

		if (response) {
			ToastService.info(t("User deleted"));
		} else {
			ToastService.error(t("Error deleting user"));
		}

		await this.update({
			selectedUser: null,
			showChangeUserStatusModal: false
		});

		await this.fetchData();
	}

	onDeleteUser = async ({ name, userId }) => {
		let user = await UserService.fetchUser(userId);

		// If the user is assigned to conversations then show the reassign alert
		if (user.assigned_contacts > 0) {
			await this.update({
				showConfirmReassignModal: true,
				selectedUser: user,
				selectedUserToStatus: STATUS.deleted
			});

			return;
		}

		await this.update({
			selectedUserToStatus: STATUS.deleted,
			showChangeUserStatusModal: true,
			selectedUser: user
		});
	};

	onConfirmStatusUser = async ({ user, status }) => {
		user = await UserService.fetchUser(user.id);

		this.update({
			showChangeUserStatusModal: true,
			selectedUser: user,
			selectedUserToStatus: status
		});
	};

	onReassignAlertClose = async confirmed => {
		if (!confirmed) {
			await this.update({
				reassignedUser: null
			});
		}

		await this.update({
			showChangeUserStatusModal: true,
			showConfirmReassignModal: false
		});
	};

	renderActions(recordData) {
		let { t } = this.props;

		const user = UserService.get();
		var fullName = UserService.createFullName({ firstName: recordData.first_name, lastName: recordData.last_name });

		return (
			<div className="users__actions" key={`user-${recordData.id}-status-${recordData.status}`}>
				{user.GroupPermission.update_team && (
					<Action
						key={`editUser-${recordData.id}`}
						id={`editUser-${recordData.id}`}
						label={t("Edit")}
						icon={Icon.Edit}
						onClick={() => {
							this.props.history.push(`/settings/users/${recordData.id}`);
						}}
						transparent={true}
					/>
				)}
				{user.GroupPermission.delete_team && (
					<Action
						key={`deleteUser-${recordData.id}`}
						id={`deleteUser-${recordData.id}`}
						label={t(STATUS_VERB.deleted)}
						icon={Icon.Trash2}
						onClick={() => this.onDeleteUser({ name: fullName, userId: recordData.id })}
						transparent={true}
					/>
				)}
				{recordData.status === STATUS.inactive && user.GroupPermission.update_team && (
					<Action
						key={`activate-${recordData.id}`}
						id={`activate-${recordData.id}`}
						label={t(STATUS_VERB.active)}
						icon={Icon.Zap}
						onClick={() => this.onConfirmStatusUser({ user: recordData, status: STATUS.active })}
						transparent={true}
					/>
				)}
				{recordData.status === STATUS.active && user.GroupPermission.update_team && (
					<Action
						key={`deactivate-${recordData.id}`}
						id={`deactivate-${recordData.id}`}
						label={t(STATUS_VERB.inactive)}
						icon={Icon.Power}
						onClick={() => this.onConfirmStatusUser({ user: recordData, status: STATUS.inactive })}
						transparent={true}
					/>
				)}
			</div>
		);
	}

	renderRecord = recordData => {
		let { selectedUsers } = this.state;

		let loginMethod = recordData.last_login_origin ? ` (${recordData.last_login_origin})` : "";
		let loginFormattedDate = recordData.last_login_date ? UtilityService.getRelativeDate(recordData.last_login_date) : "";

		var fullName = UserService.createFullName({ firstName: recordData.first_name, lastName: recordData.last_name });

		let record = [fullName, recordData.email, recordData.permission_name, `${loginFormattedDate}${loginMethod}`, this.renderActions(recordData)];

		if (UserService.isSuperAdmin()) {
			record.unshift(
				<div className="contacts__record-checkbox">
					<Checkbox
						id={`${recordData.id}-checkbox`}
						name={`${recordData.id}-checkbox`}
						checked={selectedUsers[`${recordData.id}`] ? true : false}
						onChange={e => this.handleOnUserSelect(e, recordData)}
					/>
				</div>
			);
		}

		return record;
	};

	getUserReassignOptions = () => {
		let { data } = this.state;
		let { t } = this.props;

		let options = data.map(u => ({ value: u.id, label: `${u.first_name} ${u.last_name}` }));
		options.unshift({ value: 0, label: t("Unassign") });

		return options;
	};

	render() {
		let {
			data,
			loading,
			loadedAll,
			sortField,
			sortOrder,
			showChangeUserStatusModal,
			selectedUser,
			selectedUserToStatus,
			showUserFilters,
			locationIds,
			showConfirmReassignModal,
			assignedContacts,
			selectedUserName,
			reassignedUser,

			selectedUsers,
			showConfirmResetPasswordsModal
		} = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let selectedUserFullName = "";
		if (selectedUser) {
			selectedUserFullName = UserService.createFullName({ firstName: selectedUser.first_name, lastName: selectedUser.last_name });
		}

		let isSuperAdmin = UserService.isSuperAdmin();

		return (
			<Page>
				<div className="users__header">
					<div className="users__search">
						<SearchInput placeholder={t("Search ...")} onChange={this.onSearchChange} />
					</div>
					<div className="users__header__actions">
						{isSuperAdmin && selectedUsers && Object.keys(selectedUsers).length > 0 && (
							<Action id="reset-passwords" label={t("Reset Password")} icon={Icon.RotateCcw} onClick={this.confirmPasswordReset} />
						)}

						<Action id="filter-users" label={t("Filter Users")} icon={Icon.Filter} onClick={this.onFilterClick} />
						{user.GroupPermission.create_team && <Action id="new-user" label={t("Create")} icon={Icon.UserPlus} onClick={this.onNewUserClick} />}
						{user.GroupPermission.create_user_invites && (
							<Action id="invite-users" label={t("Invite Users")} icon={Icon.Send} onClick={this.onInviteUsersClick} />
						)}
					</div>
				</div>
				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={t("Users not found...")}
					noDataIcon={<Icon.AlertCircle />}
				/>
				<Alert
					type="warning"
					show={showChangeUserStatusModal}
					title={t("Are you sure?")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={this.onChangeStatusAlertClose}
				>
					<div>{t("Do you want to {{status}} {{userName}}?", { userName: selectedUserFullName, status: STATUS_VERB[selectedUserToStatus] })}</div>
				</Alert>
				<Alert
					type="warning"
					show={showConfirmResetPasswordsModal}
					title={t("Are you sure?")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={this.onResetPasswords}
				>
					<div>{t("Do you want to reset the passwords? The users will be logged out and forced to create new passwords.")}</div>
				</Alert>
				<Alert
					type="warning"
					show={showConfirmReassignModal}
					title={t("Conversations attached ...")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={this.onReassignAlertClose}
				>
					<div className="users__reassign-alert">
						{t("{{userName}} has {{numberOfAssignedContacts}} conversation(s) assigned to them. Would you like to reassign these conversations?", {
							userName: selectedUserFullName,
							numberOfAssignedContacts: assignedContacts
						})}
					</div>
					<DHSelect
						label={t("Reassign to user ...")}
						name="reassignedUserId"
						id="reassignedUserId"
						onChange={user => this.update({ reassignedUser: user })}
						value={reassignedUser}
						options={this.getUserReassignOptions()}
					/>
				</Alert>
				<UserFilters
					show={showUserFilters}
					handleOnHide={() => this.setState({ showUserFilters: false })}
					handleFiltersOnSubmit={(locations, accountTypes) => this.handleFiltersOnSubmit(locations, accountTypes)}
					locations={locationIds}
				/>
			</Page>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(Users)));
