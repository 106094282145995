import React, { Component } from "react";
import * as Icon from "react-feather";
import ContentLoader from "react-content-loader";
import { withTranslation, Trans } from "react-i18next";

import LightboxService from "../../../../services/LightboxService";
import UtilityService from "../../../../services/UtilityService";
import TeamChatService from "../../../../services/TeamChatService";

import MediaGrid from "../../../../components/common/MediaGrid";

import "./teamchat-media-grid.css";

class TeamChatMediaGrid extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mediaList: [],
			limit: 10,
			canLoadMore: true,
			loading: false
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	async componentDidUpdate(prevProps) {
		if (prevProps.conversationId !== this.props.conversationId) {
			await this.resetComponent();
		} else if (prevProps.userId !== this.props.userId) {
			await this.resetComponent();
		}
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.update({ mediaList: [], limit: 10, canLoadMore: true, loading: false });
		await this.fetchMedia();
	};

	fetchMedia = async () => {
		let { mediaList, limit } = this.state;
		let { conversationId, userId } = this.props;

		await this.update({ loading: true });

		let query = {
			conversationId,
			limit
		};

		if (userId) {
			query.userId = userId;
		}

		let newMediaList = await TeamChatService.fetchMediaForConversation(query);

		if (newMediaList.length === mediaList.length || newMediaList.length < limit) {
			await this.update({ canLoadMore: false });
		}

		await this.update({ mediaList: newMediaList, loading: false });
	};

	onLoadMoreMedia = async () => {
		let { limit } = this.state;
		let upperLimit = limit + 10;

		await this.update({
			limit: upperLimit
		});

		await this.fetchMedia();
	};

	onMediaClicked = url => {
		let { mediaList } = this.state;

		// Filter it so it's only images, add the correct params it's looking for
		let mediaListForLightbox = mediaList.map(m => {
			if (m.type !== "image") {
				return null;
			}

			return {
				src: UtilityService.appendQueryAuthToken(m.download_url),
				file_name: m.file_name,
				height: m.height,
				width: m.width
			};
		});

		// Remove the nulls
		mediaListForLightbox = mediaListForLightbox.filter(item => {
			return item !== null;
		});

		// Find the correct index
		let mediaIndex = 0;
		for (let i = 0; i < mediaList.length; i++) {
			if (mediaListForLightbox[i].src === url) {
				mediaIndex = i;
				break;
			}
		}

		LightboxService.setMedia({ media: mediaListForLightbox, mediaIndex: mediaIndex });
		LightboxService.open();
	};

	renderLoading = () => {
		return (
			<ContentLoader height={"210"} width={"100%"}>
				<rect x="0" y="0" rx="0" ry="0" width="100%" height="210" />
			</ContentLoader>
		);
	};

	render = () => {
		let { loading, mediaList, canLoadMore } = this.state;
		let { t } = this.props;

		if (mediaList.length < 1) {
			return (
				<div className="teamchat-media-grid">
					<img className="teamchat-media-grid__no-media-image" src="https://cdn.demandhub.co/web-app/assets/media-banner.svg" alt="media loading" />
				</div>
			);
		}

		return (
			<div className="teamchat-media-grid">
				<MediaGrid mediaList={mediaList} itemWidth={250} onMediaClicked={url => this.onMediaClicked(url)} />
				{loading && <div className="teamchat-media-grid__loading">{this.renderLoading()}</div>}
				{!loading && canLoadMore && (
					<div className="teamchat-media-grid__load-more" onClick={this.onLoadMoreMedia}>
						<div className="mb-button">{t("Load More")}</div>
					</div>
				)}
			</div>
		);
	};
}

export default withTranslation(null, { withRef: true })(TeamChatMediaGrid);
