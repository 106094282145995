// Provides access to user data stored in local storage
import Kichiri from "./KichiriService";
import config from "../config/app/web-app.config";
import TranslationService from "./TranslationService";
import { LANGUAGES } from "../constants/LanguageConstants";
import GROUP_PERMISSIONS from "../constants/GroupPermissions";
import MessagesService from "./MessagesService";
import UnknownTranslationService from "./UnknownTranslationService";
import TemplateService from "./TemplateService";
import SupportChatService from "./SupportChatService";
import CompanyService from "./CompanyService";

import AppConfig from "../config/app/web-app.config";
import { upload } from "./FileUploadService";

import { setAuthToken } from "service-fetch";

const cachedUser = {};

const UserService = {
	/**
	 * Check if the authtoken is valid
	 */
	async checkAuthToken() {
		try {
			await Kichiri.user.checkAuthToken({}, {}, this.getAuthToken());
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Get the user data from local storage
	 * @returns {Object|null} Returns the user data, or null if not found
	 */
	get() {
		let userData = localStorage.getItem("dh_user_data");

		if (userData == null) {
			// We originally used sessionStorage, so check there too
			userData = sessionStorage.getItem("dh_user_data");

			if (userData !== null) {
				userData = JSON.parse(userData);
				this.set(userData);
			}
		} else {
			userData = JSON.parse(userData);
		}

		return userData;
	},

	/**
	 * Login a user
	 *
	 * @param {String} email
	 * @param {String} password
	 * @param {String} loginToken
	 * @param {String} loginMethod
	 * @param {String} loginSource
	 *
	 * @returns {Object} response data
	 */
	async login({ email, password, loginToken, loginMethod, loginSource }) {
		try {
			let response = await Kichiri.user.login({ email: email, password: password, loginToken, loginMethod, loginSource });
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Set the user object in local storage for easy access
	 * @param {Object} userData - The user data response object from /api/user/login
	 */
	set(userData) {
		if (typeof userData === "undefined" || userData === null) {
			console.log("Error: Attempting to set dh_user_data with undefined/null userData");
			return;
		}
		localStorage.setItem("dh_user_data", JSON.stringify(userData));
		//this.setUserLanguage(userData.language);

		setAuthToken(userData.auth_token);
	},

	/**
	 * Clear user data from local storage
	 */
	clear() {
		// Check for user because this is called from when we sign up a new user
		let user = this.get();
		if (user && !this.isSuperAdminOrCustomerSuccess()) {
			CompanyService.clearRecentlyUsedCompanies();
		}

		localStorage.removeItem("dh_user_data");
		localStorage.removeItem("dh_company");
		localStorage.removeItem("dh_location");

		// We originally stored in sessionStorage, so remove from there too just in case
		sessionStorage.removeItem("dh_user_data");

		// Clear all the possible message drafts we stored in localStorage for both customer chat and team chat
		MessagesService.clearMessageDrafts();

		// Clear all recently used templates
		TemplateService.clearRecentlyUsedTemplates();

		// Safely unload and remove the Support Chat
		SupportChatService.uninitializeChat();
	},

	/**
	 * Get the active company from local storage. We only return id and name for a company.
	 * @param {Number} companyId - The id of the company
	 * @param {Number} companyName - The name of the company
	 * @returns {Object|null} Returns the company data, or null if not found
	 */
	getActiveCompany() {
		let company = localStorage.getItem("dh_company");

		if (company !== null) {
			company = JSON.parse(company);
		} else {
			// Try to get the user's company
			let userData = this.get();
			if (userData !== null) {
				company = { id: userData.company_id, name: userData.Company.name };
			}
		}

		return company;
	},

	/**
	 * Set the active company give the id and name of a company
	 * @param {Number} companyId - The id of the company
	 * @param {Number} companyName - The name of the company
	 */
	setActiveCompany(companyId, companyName) {
		if (typeof companyId === "undefined" || companyId === null) {
			console.log("Error: Attempting to set localstorage dh_company with undefined/null companyId");
			return;
		} else if (typeof companyName === "undefined" || companyName === null) {
			console.log("Error: Attempting to set localstorage dh_company with undefined/null companyName");
			return;
		}
		let company = { id: companyId, name: companyName };
		localStorage.setItem("dh_company", JSON.stringify(company));
	},

	/**
	 * Get the active location from local storage. We only store id and name.
	 * @returns {Object|null} The full location data, or null if not found.
	 */
	getActiveLocation() {
		const location = localStorage.getItem("dh_location");

		if (location !== null) {
			return JSON.parse(location);
		}
		return this.getLastActiveLocation();
	},

	/**
	 * Set the active location given the specified location id and name.
	 * @param {Number} locationId The id of the location
	 * @param {String} locationName The name of the location
	 */
	setActiveLocation(location) {
		if (typeof location === "undefined" || location === null) {
			console.log("Error: Attempting to set localstorage dh_location with undefined/null location");
			return;
		}

		localStorage.setItem("dh_location", JSON.stringify(location));
		//set this location to the last active location
		this.setLastActiveLocation(location);
	},
	/**
	 * Delete active location
	 */
	unsetActiveLocation() {
		localStorage.removeItem("dh_location");
		localStorage.removeItem("dh_last_location");
	},
	/**
	 * Get the number of locations for the user
	 * @returns {Number} The number of locations
	 */
	getLocationCount() {
		let locationCount = 0;
		let userData = this.get();
		if (userData !== null && userData.Locations) {
			locationCount = userData.Locations.length;
		}

		return locationCount;
	},

	/**
	 * Get the auth token for the user
	 * @returns {String} The auth token, or "" if not found
	 */
	getAuthToken() {
		let userData = this.get();
		return userData !== null ? userData.auth_token : "";
	},

	/**
	 * Determine if the user is logged in or not. Note that further checks still need to be done
	 * to find out of the user's authorization token is actually valid.
	 * @returns {Boolean} True if a user object was found, false otherwise
	 */
	isLoggedIn() {
		let userData = this.get();

		if (userData !== null) {
			return true;
		}

		return false;
	},

	/**
	 * Checks if the active location is DemandHub
	 *
	 * @returns {Boolean}
	 */
	isActiveLocationDemandHub() {
		return this.getActiveLocation().id === config.DEMANDHUB.LOCATION.ID;
	},

	/**
	 * Gets last active location
	 */
	getLastActiveLocation() {
		const lastLocation = localStorage.getItem("dh_last_location");
		const userData = this.get();
		if (userData === null || !userData.Locations) {
			return null;
		}
		if (lastLocation !== "undefined" && lastLocation && typeof lastLocation !== "undefined") {
			const lastLocationJson = JSON.parse(lastLocation);
			const isPresent = userData.Locations.some(aLocation => aLocation.id === lastLocationJson.id);
			if (isPresent) {
				return lastLocationJson;
			}
		}
		const newLastLocation = userData.Locations[0];
		this.setLastActiveLocation(newLastLocation);
		return newLastLocation;
	},

	/**
	 * Sets the last selected location
	 * @param {object} location
	 */
	setLastActiveLocation(location) {
		if (typeof location === "undefined" || location === null) {
			console.log("Error: Attempting to set localstorage dh_last_location with undefined/null location");
			return;
		}
		localStorage.setItem("dh_last_location", JSON.stringify(location));
	},
	/**
	 * set user language
	 * @param {string} language
	 */
	setUserLanguage(language) {
		// if (!language) {
		// 	return;
		// }
		// // We only want to set the language if there's nothing in local storage
		// // or if local storage does not match the user's language preference
		// let localStorageLanguage = TranslationService.getLocalStorageLanguage();
		// if (!localStorageLanguage || localStorageLanguage !== language) {
		// 	debugger;
		// 	// Store the updated language in local storage
		// 	TranslationService.setLocalStorageLanguage(language);
		// 	// Tell i18n to change the language via translation service
		// 	TranslationService.setLanguage(language);
		// }
	},
	/**
	 * Get user language
	 */
	getUserLanguage() {
		try {
			return UserService.get().language;
		} catch (error) {
			console.log(error);
			return null;
		}
	},

	/**
	 * Checks if the user is a super user or cs visiting another company
	 *
	 * @return {Boolean}
	 */
	isSuperOrCsVisitingAnotherCompany() {
		let user = this.get();
		let currentlyActiveCompany = this.getActiveCompany().id;
		let belongsToThisCompany = currentlyActiveCompany === user.Company.id;
		return this.isSuperAdminOrCustomerSuccess() && !belongsToThisCompany;
	},

	/**
	 * Checks if the current user is a super user
	 *
	 * @return {Boolean}
	 */
	isSuperAdmin() {
		let user = this.get();
		return user.GroupPermission.type === GROUP_PERMISSIONS.super_administrator.type;
	},

	/**
	 * Checks if the current user is a customer success
	 *
	 * @return {Boolean}
	 */
	isCustomerSuccess() {
		let user = this.get();
		return user.GroupPermission.type === GROUP_PERMISSIONS.customer_success.type;
	},

	/**
	 * Checks if the current user is a reseller
	 *
	 * @return {Boolean}
	 */
	isReseller() {
		let user = this.get();
		return user.GroupPermission.type === GROUP_PERMISSIONS.reseller.type;
	},

	/**
	 * Checks if the current user is an account owner
	 *
	 * @return {Boolean}
	 */
	isAccountOwner() {
		let user = this.get();
		return user.GroupPermission.type === GROUP_PERMISSIONS.account_owner.type;
	},

	/**
	 * Checks if the current user is a super user or customer service
	 *
	 * @return {Boolean}
	 */
	isSuperAdminOrCustomerSuccess() {
		return this.isSuperAdmin() || this.isCustomerSuccess();
	},

	/**
	 * Checks if the current user is a super user or customer service or reseller
	 *
	 * @return {Boolean}
	 */
	isSuperAdminOrCustomerSuccessOrReseller() {
		return this.isSuperAdmin() || this.isCustomerSuccess() || this.isReseller();
	},

	/**
	 * Checks if the current user is a super user or customer success or account owner
	 *
	 * @return {Boolean}
	 */
	isSuperAdminOrCustomerSuccessOrAccountOwner() {
		return this.isSuperAdmin() || this.isCustomerSuccess() || this.isAccountOwner();
	},

	/**
	 * Checks if the current user is a restricted customer service
	 *
	 * @return {Boolean}
	 */
	isRestrictedCustomerSuccess() {
		let user = this.get();
		return user.GroupPermission.type === GROUP_PERMISSIONS.restricted_customer_success.type;
	},

	/**
	 * Fetch a user
	 *
	 * @param {Number} userId
	 * @return {Promise}
	 */
	async fetchUser(userId) {
		let authToken = this.getAuthToken();

		try {
			let response = await Kichiri.user.getUser(
				{
					userId
				},
				{},
				authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Determines if the user needs a specific tour
	 *
	 * @param {Number} tourId
	 * @param {Number} userId
	 * @return {Boolean}
	 */
	hasToured(tourId, userId) {
		let user = UserService.get();

		if (!user.enable_tours) {
			return true;
		}

		let hasToured = localStorage.getItem(`tour_${tourId}_${userId}`);

		if (hasToured) {
			return true;
		}

		return false;
	},

	/**
	 * Completes a tour for a user
	 *
	 * @param {Number} tourId
	 * @param {Number} userId
	 * @return {Boolean}
	 */
	completeTour(tourId, userId) {
		localStorage.setItem(`tour_${tourId}_${userId}`, true);
	},

	/**
	 * Fetch a list of users
	 * @param {Object} param Query parameters to fetch users
	 * @returns {Promise} - An array of users
	 */
	async fetchUsers({ companyId, limit, offset, locationIds, groupIds, userId, searchTerm, sortField, sortOrder }) {
		try {
			if (!companyId) {
				companyId = UserService.getActiveCompany().id;
			}
			if (!userId) {
				userId = UserService.get().id;
			}
			let response = await Kichiri.user.fetch(
				{},
				{
					company: companyId,
					limit: limit,
					offset: offset,
					locationIds,
					groupIds,
					userId,
					searchTerm,
					sortField,
					sortOrder
				},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return [];
	},

	/**
	 * Delete a user
	 * @param {Number} userId
	 * @param {Number} reassignedUserId
	 * @returns {Promise}
	 */
	async deleteUser({ userId, reassignedUserId }) {
		try {
			await Kichiri.user.deleteUser({ userId, reassignedUserId }, {}, UserService.getAuthToken());
			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update the availability for a user
	 *
	 * @param {Boolean} isAvailable
	 */
	async setAvailability(isAvailable) {
		try {
			let user = UserService.get();

			let { data } = await Kichiri.user.updateUser({ userId: user.id, isAvailable }, {}, this.getAuthToken());

			user.is_available = data.is_available;

			UserService.set(user);

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Return the full name of the currently logged in user
	 * @return {String}
	 */
	getCurrentUserFullName() {
		let user = this.get();
		return this.createFullName({ firstName: user.first_name, lastName: user.last_name });
	},

	/**
	 * Given the first and last names which may or may not be present, it returns the full name
	 * @param {String} firstName
	 * @param {String} lastName
	 * @return {String}
	 */
	createFullName({ firstName, lastName }) {
		// If both are present
		if (firstName && lastName) {
			return firstName + " " + lastName;
		}
		// If one of them is present
		else if (firstName || lastName) {
			return firstName || lastName;
		}
		return "";
	},

	/**
	 * Update a user
	 * @param {Object} userData
	 * @returns
	 */
	async updateUser(userData) {
		try {
			let { data: newUserData } = await Kichiri.user.updateUser(userData, {}, this.getAuthToken());

			const user = UserService.get();

			// If the updated user is the current user
			if (user.id === newUserData.id) {
				user.first_name = newUserData.first_name;
				user.last_name = newUserData.last_name;
				user.phone = newUserData.phone;
				user.email = newUserData.email;
				user.profile_picture_url = newUserData.profile_picture_url;
				user.language = newUserData.language;
				user.enable_messenger_signature = newUserData.enable_messenger_signature;
				user.messenger_signature = newUserData.messenger_signature;
				user.messenger_html_signature = newUserData.messenger_html_signature;
				user.messenger_click_to_send = newUserData.messenger_click_to_send;
				user.messenger_conversation_nudging = newUserData.messenger_conversation_nudging;
				user.messenger_show_reply_suggestions = newUserData.messenger_show_reply_suggestions;
				user.enable_tours = newUserData.enable_tours;

				this.set(user);
			}

			return user;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Creates a new user
	 *
	 * @param {Object} userData
	 * @returns
	 */
	async createUser(userData) {
		try {
			let authToken = UserService.getAuthToken();
			let { data } = await Kichiri.user.createUser(userData, {}, authToken);
			return data;
		} catch (error) {
			if (error.response.data.dh_error) {
				const dhError = error.response.data.dh_error;
				if (dhError.error_code) {
					throw UnknownTranslationService.getErrorMessage(dhError.error_code, dhError.field, dhError.length);
				} else {
					throw UnknownTranslationService.getErrorMessage(dhError.type, dhError.field, dhError.length);
				}
			}
		}

		return null;
	},

	/**
	 * Fetch all sessions used by a user
	 * @param {Number} userId
	 * @param {Number} offset
	 * @param {String} sortField
	 * @param {String} sortOrder
	 * @param {Number} limit
	 * @returns {Promise} - An array of sessions
	 */
	async fetchSessions({ userId, offset, sortField, sortOrder, limit }) {
		try {
			let response = await Kichiri.user.fetchSessions(
				{ userId },
				{
					offset,
					sortField,
					sortOrder,
					limit
				},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return [];
	},

	/**
	 * Removes the profile image for a specific user
	 * @param {int} userId
	 * @returns
	 */
	async removeProfilePicture(userId) {
		try {
			await Kichiri.user.removeProfilePicture({ userId }, {}, UserService.getAuthToken());
			return;
		} catch (e) {
			throw e;
		}
	},

	/**
	 * Uploads a profile image for a specific user
	 * @param {file} profilePictureFileNew
	 */
	async uploadProfilePicture(userId, profilePictureFileNew) {
		try {
			await upload(
				profilePictureFileNew,
				`Profile picture for ${userId}`,
				`${AppConfig.API_SERVER}/api/user/${userId}/profile_picture/update`,
				UserService.getAuthToken()
			);
		} catch (e) {
			throw e;
		}
	},

	/**
	 * Get what's new data
	 * @returns {Array}
	 */
	async getWhatsNew() {
		try {
			const response = await Kichiri.version.getWhatsNew({}, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Send a password token
	 * @param {String} medium
	 * @param {String} phoneOrEmail
	 * @returns  {Boolean}
	 */
	async sendPasswordToken({ medium, phoneOrEmail }) {
		try {
			await Kichiri.password.sendToken({ [medium]: phoneOrEmail });

			return true;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Reset a password
	 * @param {String} password
	 * @param {String} token
	 * @param {String} recaptchaValue
	 * @returns {Boolean}
	 */
	async resetPassword({ password, token, recaptchaValue }) {
		try {
			await Kichiri.password.reset({ password, token, recaptchaValue });

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Force a password reset for an array of users
	 * @param {Array/Number} userIds
	 * @returns {Boolean}
	 */
	async resetUserPasswords(userIds) {
		try {
			await Kichiri.password.resetUsers({ userIds }, {}, this.getAuthToken());

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Verify a token
	 * @param {String} token
	 * @returns {Object}
	 */
	async verifyToken({ token }) {
		try {
			const response = await Kichiri.password.verifyToken({ token });

			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Get user locations for a user
	 * @param {Integer} userId
	 * @returns {Object}
	 */
	async getUserLocations(userId) {
		try {
			const response = await Kichiri.user.getUserLocations(
				{
					userId: userId
				},
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Caches the email and password for the login flow and 2FA flow
	 *
	 * @param {String} email
	 * @param {String} password
	 */
	async cacheUserCredentials({ email, password }) {
		cachedUser.email = email;
		cachedUser.password = password;
	},

	/**
	 * Verifies if the two factor token is valid
	 *
	 * @param {String} token
	 * @returns {Boolean}
	 */
	async verifyTwoFactorToken({ verifyToken }) {
		try {
			let { email } = cachedUser;
			let response = await Kichiri.user.verifyToken({ email, verifyToken });

			return response.data.isValidToken;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Confirm if the Two Factor PIN is valid
	 *
	 * @param {String} verifyToken
	 * @param {String} pin
	 * @returns {Boolean}
	 */
	async confirmTwoFactorPin({ verifyToken, pin }) {
		try {
			let { email, password } = cachedUser;
			let response = await Kichiri.user.confirmPin({ email, password, verifyToken, pin });

			delete cachedUser.email;
			delete cachedUser.password;

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Indicates if the user has already seen the new nav banner
	 * @returns {Boolean}
	 */
	hasSeenBanner() {
		let hasSeenBanner = localStorage.getItem("dh_has_seen_banner");

		return !(hasSeenBanner == null);
	},

	/**
	 * Set's whether or not the user has seen the new nav banner
	 */
	setSeenBanner({ hasSeenBanner }) {
		localStorage.setItem("dh_has_seen_banner", hasSeenBanner);
	}
};

export default UserService;
