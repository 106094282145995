import i18n from "../i18n/config";

export const PASSWORD_REQUIREMENTS_GENERATOR = {
	characters: {
		label: function(expected) {
			if (expected > 1) {
				return i18n.t("Password has at least {{number}} characters", { number: expected });
			}
			return i18n.t(`Password has at least a single character`);
		},
		test: function(value, expected) {
			return value.length >= expected;
		}
	},
	uppercase: {
		label: function(expected) {
			if (expected > 1) {
				return i18n.t("Password has at least {{number}} uppercase letters", { number: expected });
			}
			return i18n.t(`Password has an uppercase letter`);
		},
		test: function(value, expected) {
			return (value.match(/[A-Z]/g) || []).length >= expected;
		}
	},
	lowercase: {
		label: function(expected) {
			if (expected > 1) {
				return i18n.t("Password has at least {{number}} lowercase letters", { number: expected });
			}
			return i18n.t(`Password has a lowercase letter`);
		},
		test: function(value, expected) {
			return (value.match(/[a-z]/g) || []).length >= expected;
		}
	},
	number: {
		label: function(expected) {
			if (expected > 1) {
				return i18n.t("Password has at least {{number}} numbers", { number: expected });
			}
			return i18n.t(`Password has a number`);
		},
		test: function(value, expected) {
			return value.replace(/\D/g, "").length >= expected;
		}
	},
	special: {
		label: function(expected) {
			if (expected > 1) {
				return i18n.t("Password has at least {{number}} special characters", { number: expected });
			}
			return i18n.t(`Password has a special character`);
		},
		test: function(value, expected) {
			return (value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/gi) || []).length >= expected;
		}
	}
};

export const USER_PASSWORD_REQUIREMENTS = { characters: 8, uppercase: 1, lowercase: 1, number: 1, special: 1 };
