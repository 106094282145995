import React, { Component } from "react";
import c3 from "c3";
import ReactTooltip from "react-tooltip";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import { AnalyticsService } from "../../services/AnalyticsService";

import withLocation from "../../components/common/WithLocation";
import DashboardCard from "./DashboardCard";
import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class TotalMessages extends Component {
	componentDidMount() {
		this.getMessagesCount();
	}
	onLocationChanged = () => {
		this.getMessagesCount();
	};

	async getMessagesCount() {
		const locationId = UserService.getActiveLocation().id;
		let data = await AnalyticsService.getMessagesCount({ locationId });
		let { t } = this.props;

		if (!data) {
			document.getElementById("dashboard__total--messages").innerHTML = "";
			return;
		}

		c3.generate({
			bindto: "#dashboard__total--messages",
			size: {
				height: 300
			},
			data: {
				columns: [
					[t(`{{number}} Sent`, { number: data.outbound }), data.outbound],
					[t(`{{number}} Received`, { number: data.inbound }), data.inbound]
				],
				type: "donut"
			},
			donut: {
				label: {
					format: value => value
				},
				title: t(`Total: `) + `${data.outbound + data.inbound}`
			},
			legend: {
				item: {
					tile: {
						width: 30
					}
				}
			}
		});
	}
	render() {
		const { cardName } = this.props;
		let { t } = this.props;

		return (
			<DashboardCard className={DASHBOARD_CARDS.totalMessages.className} height={DASHBOARD_CARDS.totalMessages.h}>
				<div className="dashboard__card--total-messages__header">
					{cardName ? cardName : t("Total Messages")}
					<span className="dashboard__card__header__icon">
						<Icon.Info size="20" data-tip data-for="total-messages" />
					</span>
					<ReactTooltip id="total-messages" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="right">
						<div className="text-left">{t("Last 30 days.")}</div>
					</ReactTooltip>
				</div>
				<div className="dashboard__card--total-messages__chart">
					<div id="dashboard__total--messages" />
				</div>
			</DashboardCard>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(TotalMessages));
