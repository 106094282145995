import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import Header from "../../../components/common/Header";
import List from "../../../components/common/List";
import Page from "../../../components/common/Page";
import Action from "../../../components/common/Action";
import withLocation from "../../../components/common/WithLocation";
import Alert from "../../../components/common/Alert";

import EditCampaignModal from "./EditCampaignModal";

import UserService from "../../../services/UserService";
import GAService from "../../../services/GAService";
import ToastService from "../../../services/ToastService";
import A2PService from "../../../services/A2PService";

import { A2P_CAMPAIGN_COLUMNS } from "../../../constants/A2P";

class A2PCampaigns extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			campaigns: [],
			loading: false,

			selectedCampaignId: null,
			showCreateModal: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	onLocationChanged = async () => {
		await this.resetComponent();
	};

	resetComponent = async () => {
		await this.update({
			campaigns: [],
			loading: false,

			selectedCampaignId: null,
			showCreateModal: false
		});

		await this.fetchCampaigns();
	};

	fetchCampaigns = async () => {
		let locationId = UserService.getActiveLocation().id;

		await this.update({ loading: true });

		let campaigns = await A2PService.fetchCampaigns({ locationId });

		await this.update({ campaigns, loading: false });
	};

	getHeaders() {
		let headers = {
			items: A2P_CAMPAIGN_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	}

	renderRecord = recordData => {
		if (!recordData) {
			return null;
		}

		return [
			recordData.id,
			recordData.location_id,
			recordData.usecase,
			recordData.description,
			recordData.auto_renewal ? "true" : "false",
			moment(recordData.created_at).format("MMM Do YYYY hh:mm a"),
			<div className="dh-list__actions">
				<Action id={`edit-${recordData.id}`} label="Edit" icon={Icon.Edit} onClick={() => this.onShowCreateModal(recordData.id)} />
			</div>
		];
	};

	onShowCreateModal = async dhCampaignId => {
		await this.update({
			selectedCampaignId: dhCampaignId,
			showCreateModal: true
		});
	};

	onHideCreateModal = async () => {
		await this.update({
			selectedCampaignId: null,
			showCreateModal: false
		});
	};

	onSave = async () => {
		await this.update({
			selectedCampaignId: null,
			showCreateModal: false
		});
		await this.fetchCampaigns();
	};

	render() {
		let { campaigns, loading, showCreateModal, selectedCampaignId } = this.state;
		let { t } = this.props;

		return (
			<Page>
				<Header>
					<Action id="create" label={t("Create Campaign")} icon={Icon.Plus} onClick={() => this.onShowCreateModal()} />
				</Header>

				<List
					items={campaigns}
					loading={loading}
					loadedAll={true}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={() => {}}
					noDataTitle={t("No campaigns found...")}
					noDataIcon={<Icon.AlertCircle />}
				/>

				{!loading && <EditCampaignModal show={showCreateModal} campaignId={selectedCampaignId} onHide={this.onHideCreateModal} onSave={this.onSave} />}
			</Page>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(A2PCampaigns)));
