import React from "react";
import { withTranslation } from "react-i18next";

import Modal from "../../components/common/DHModal";
import Input from "../../components/common/Input";
import SearchInput from "../../components/common/SearchInput";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class AddDashboardCardModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dashboardName: "",
			searchTerm: "",
			selectedOptions: null,
			dashboardOptions: this.getDashboardOptions()
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	isCreateMode = () => {
		let { layout } = this.props;
		return !layout;
	};

	onHide = () => {
		let { onHide } = this.props;

		this.update({ selectedOptions: null, dashboardName: "", searchTerm: "", dashboardOptions: this.getDashboardOptions() });

		if (this.props.onHide) {
			onHide();
		}
	};

	getDashboardOptions() {
		let options = [];
		Object.values(DASHBOARD_CARDS).map(card => {
			if (card.skip) {
				return;
			}
			options.push({ id: card.id, name: card.name, description: card.description });
		});
		return options;
	}

	onSearchChange = async value => {
		await this.update({ searchTerm: value });

		let { searchTerm } = this.state;

		const filteredOptions = this.getDashboardOptions().filter(option => {
			if (option.name.toLowerCase().includes(searchTerm.toLowerCase())) {
				return true;
			}
			if (option.description.toLowerCase().includes(searchTerm.toLowerCase())) {
				return true;
			}
			return false;
		});

		await this.update({ dashboardOptions: filteredOptions });
	};

	onAddCard = () => {
		let { onAddCard } = this.props;
		let { selectedOptions, dashboardName } = this.state;

		if (!selectedOptions) {
			return;
		}

		this.onHide();

		if (this.props.onAddCard) {
			onAddCard(selectedOptions, dashboardName);
		}
	};

	onSelectCard = option => {
		let { selectedOptions } = this.state;

		if (!selectedOptions) {
			selectedOptions = [];
		}

		selectedOptions = [...selectedOptions];

		let optionIndexOf = selectedOptions.indexOf(option.id);

		// If the option has already been selected, de select the option
		if (optionIndexOf >= 0) {
			selectedOptions.splice(optionIndexOf, 1);
		} else {
			selectedOptions.push(option.id);
		}

		this.update({ selectedOptions });
	};

	canSave = () => {
		let { selectedOptions, dashboardName } = this.state;

		if (!selectedOptions || selectedOptions.length < 1) {
			return false;
		}

		if (this.isCreateMode()) {
			if (!dashboardName || dashboardName.length < 4) {
				return false;
			}
		}

		return true;
	};

	render = () => {
		let { show, layout } = this.props;
		let { dashboardName, dashboardOptions, selectedOptions } = this.state;
		let { t } = this.props;

		return (
			<Modal show={show} title={t("Add Dashboard Card")} onHide={this.onHide}>
				<div className="modal__body">
					{this.isCreateMode() && (
						<Input
							label={t("Dashboard Name")}
							name="dashboardName"
							id="dashboardName"
							type="text"
							onChange={e => this.update({ dashboardName: e.target.value })}
							value={dashboardName}
							required
						/>
					)}

					<div>
						<b>{t("Dashboard Cards")}</b>
					</div>
					<div>
						<SearchInput placeholder={t("Search ...")} onChange={this.onSearchChange} />
					</div>
					{layout && layout.length === dashboardOptions.length ? (
						<>
							<br></br>
							<div className="text-center">{t("No cards found.")}</div>
						</>
					) : (
						<>
							<div className="add-dashboard-card__cards">
								{dashboardOptions.map(option => {
									let alreadyInLayout = layout ? layout.find(l => l.i === option.id) : false;
									if (alreadyInLayout) {
										return null;
									}
									return (
										<div
											key={option.id}
											className={`add-dashboard-card__cards__item ${
												selectedOptions && selectedOptions.includes(option.id) ? "add-dashboard-card__cards__item--selected" : ""
											}`}
											onClick={() => this.onSelectCard(option)}
										>
											<div>{option.name}</div>
											<div>{option.description}</div>
										</div>
									);
								})}
							</div>
						</>
					)}

					<div className="modal__actions">
						<div className="mb-button mb-button--cancel" onClick={this.onHide}>
							{t("Cancel")}
						</div>
						<div className={`mb-button ${this.canSave() ? "" : "mb-button--disabled"}`} onClick={this.onAddCard}>
							{this.isCreateMode() ? t("Create Dashboard") : t("Add Card")}
						</div>
					</div>
				</div>
			</Modal>
		);
	};
}

export default withTranslation(null, { withRef: true })(AddDashboardCardModal);
