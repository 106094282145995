import React from "react";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import { AnalyticsService } from "../../services/AnalyticsService";
import SupportChatService from "../../services/SupportChatService";
import LocationService from "../../services/LocationService";

import withLocation from "../../components/common/WithLocation";
import DashboardCard from "./DashboardCard";
import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class WidgetConversions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			widgetConversions: 0
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	onLocationChanged = () => {
		this.fetchData();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	fetchData = async () => {
		try {
			let locationId = UserService.getActiveLocation().id;
			let result = await AnalyticsService.getTotalConversionsCount({ locationId });

			await this.update({
				widgetConversions: result.widgetConversions
			});
		} catch (error) {
			console.log(error);
		}
	};

	onWidgetsGetStarted = () => {
		let { t } = this.props;
		SupportChatService.showNewMessage(t("Hi, I would like to enable Webchat and booking features. Would you be able to help?"));
	};

	renderHeader = () => {
		const { cardName } = this.props;
		let { t } = this.props;

		return (
			<div className="dashboard__card__header">
				<div className="dashboard__card__header__icon">
					<Icon.Book size="42" />
				</div>
				<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">
					{cardName ? cardName : t("Widget Conversions")} <Icon.Info size={20} data-tip data-for="dashboard-widget-conversions-info"></Icon.Info>
				</div>
				<ReactTooltip
					id="dashboard-widget-conversions-info"
					className="mb-react-tooltip mb-react-tooltip--medium"
					arrowColor="#333"
					type="info"
					effect="solid"
					place="right"
				>
					{t(
						"Shows the total number of bookings or sales that are attributable to a lead through a DemandHub widget. Shows cumulative data for all time. Requires a widget and an automation to be enabled."
					)}
				</ReactTooltip>
			</div>
		);
	};

	renderBody = () => {
		let { widgetConversions } = this.state;
		const { isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		if (isEnabled && !isEnabled()) {
			return (
				<div className="dashboard__widget__conversions">
					<div className="mb-button mb-button--right" onClick={this.onWidgetsGetStarted}>
						{t("Get Started")}
					</div>
				</div>
			);
		}

		if (isPermissible && !isPermissible()) {
			return <div className="dashboard__widget__conversions">{t("Widget data restricted.")}</div>;
		}

		let content = t("N/A");

		let areWidgetsEnabled = LocationService.isWebchatPermissible() || LocationService.isBookingsPermissible();

		if (areWidgetsEnabled && widgetConversions && widgetConversions > 0) {
			content = widgetConversions;
		}

		return (
			<div className="dashboard__card__stat">
				<div className="dashboard__card__stat__count">{content}</div>
			</div>
		);
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.widgetConversions.className} height={DASHBOARD_CARDS.widgetConversions.h}>
				{this.renderHeader()}

				{this.renderBody()}
			</DashboardCard>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(WidgetConversions));
