import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";

import UserService from "../../services/UserService";
import LocationService from "../../services/LocationService";

import withLocation from "../../components/common/WithLocation";
import DashboardCard from "./DashboardCard";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class ClickRate extends Component {
	state = { clickRate: 0 };

	componentDidMount() {
		this.fetchData();
	}

	onLocationChanged = () => {
		this.fetchData();
	};

	async fetchData() {
		let clickRate = await LocationService.fetchClickRate({ locationId: UserService.getActiveLocation().id });
		if (!clickRate) {
			this.setState({ clickRate: 0 });
			return;
		}
		this.setState({ clickRate });
	}

	render() {
		const { clickRate } = this.state;
		const { cardName, isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		return (
			<DashboardCard className={DASHBOARD_CARDS.clickRate.className} height={DASHBOARD_CARDS.clickRate.h}>
				<div className="dashboard__card__header">
					<div className="dashboard__card__header__icon">
						<Icon.MousePointer size="42" />
					</div>
					<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">
						{cardName ? cardName : t("Click Rate ")}
						<Icon.Info size={20} data-tip data-for="dashboard-widget-click-rate" />{" "}
					</div>

					<ReactTooltip
						id="dashboard-widget-click-rate"
						className="mb-react-tooltip mb-react-tooltip--medium"
						arrowColor="#333"
						type="info"
						effect="solid"
						place="right"
					>
						{t("Shows cumulative data for all time.")}
					</ReactTooltip>
				</div>

				<div className="dashboard__card__stat">
					{isEnabled() && isPermissible() ? (
						<>
							<div className="dashboard__card__stat__count">{clickRate}%</div>
							<small>{t("since joining")}</small>
						</>
					) : (
						`${isEnabled() ? t("Review Invites are not enabled. Contact support to get started.") : t("Review Invites Data Restricted.")}`
					)}
				</div>
			</DashboardCard>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(ClickRate));
