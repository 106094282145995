import React, { Component } from "react";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import MessagesService from "../../services/MessagesService";
import UtilityService from "../../services/UtilityService";
import UserService from "../../services/UserService";

import Spinners from "./Spinners";
import Action from "./Action";
import EditImageModal from "./EditImageModal";

import { MEDIA_TYPES } from "../../constants/Messenger";

import "../../styles/css/components/commons/attachment.css";

class AttachmentItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			media: null,
			originalMedia: null,
			editAllowed: this.props.editAllowed,
			editMode: false
		};

		this.uploadInput = null;
		this.imageRef = null;
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.mediaId !== this.props.mediaId) {
			this.resetComponent();
		}
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		let { mediaId } = this.props;

		if (!mediaId) {
			this.setState({ media: null });
			return;
		}

		let media = await MessagesService.getMedia({ mediaId });

		if (!media) {
			this.setState({ media: null });
			return;
		}

		media.download_url = UtilityService.appendQueryAuthToken(media.download_url, UserService.getActiveLocation().id, UserService.getAuthToken());

		this.setState({ media });
	};

	hide = () => {
		if (this.props.disabled) {
			return;
		}
		if (this.props.hide) {
			this.props.hide();
		}
	};

	onShowEditModal = () => {
		this.update({
			editMode: true
		});
	};

	onHideEditModal = () => {
		this.update({
			editMode: false
		});
		this.resetComponent();
	};

	renderMediaName = () => {
		const { media } = this.state;
		return (
			<>
				<div className="attachment__file__text__name">{media.file_name}</div>
				{media.file_name.length > 15 && <div>{media.extension}</div>}
			</>
		);
	};

	renderBody = () => {
		const { media } = this.state;
		let { referrerPolicy } = this.props;

		if (!media) {
			return null;
		}

		if (media && media.file_name && media.type !== MEDIA_TYPES.image) {
			return (
				<div className="attachment__file">
					<div className="attachment__file__icon">
						<Icon.FileText size="42" />
					</div>
					<div className="attachment__file__text">{this.renderMediaName()}</div>
				</div>
			);
		}

		if (media && media.type !== MEDIA_TYPES.image) {
			return null;
		}

		return <img className="attachment__img" src={media.download_url} alt="An attachment" referrerPolicy={referrerPolicy} />;
	};

	renderLoading = () => {
		return <Spinners loading={true} size="25px" type="tail-fade" />;
	};

	render() {
		let { editMode, editAllowed } = this.state;
		const { disabled, loading, t, mediaId, referrerPolicy } = this.props;

		return (
			<>
				<div
					className={`attachment ${loading ? "attachment--loading" : ""} ${disabled ? "attachment--disabled" : ""} ${editMode ? "attachment--editing" : ""}`}
				>
					{loading && this.renderLoading()}
					{!loading && this.renderBody()}
					{!editMode && !disabled && (
						<div className="attachment__overlay">
							<div className="attachment__overlay__actions">
								{editAllowed && (
									<Action key={`edit`} id={`edit`} label="Edit" transparent={true} lightOutline={true} icon={Icon.Edit3} onClick={this.onShowEditModal} />
								)}
								<Action key={`hide`} id={`hide`} label="Remove" transparent={true} lightOutline={true} icon={Icon.X} onClick={this.hide} />
							</div>
						</div>
					)}
				</div>
				{editMode && <EditImageModal show={true} mediaId={mediaId} onHide={this.onHideEditModal} referrerPolicy={referrerPolicy} />}
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(AttachmentItem);
