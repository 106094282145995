import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import UserService from "../../../services/UserService";
import { withTranslation, Trans } from "react-i18next";

import Spinners from "../../../components/common/Spinners";
import withLocation from "../../../components/common/WithLocation";
import DashboardCard from "../../Dashboard/DashboardCard";

import { AnalyticsService } from "../../../services/AnalyticsService";

import { DASHBOARD_CARDS } from "../../../constants/Dashboard";

import "../../../styles/css/scenes/analytics.css";
import "../../../App.css";

class AnalyticsSummary extends Component {
	state = {
		overallRatingsObject: { reviews: [] },
		loading: true
	};

	componentDidMount() {
		this.resetComponent();
	}

	async resetComponent() {
		try {
			const locationId = UserService.getActiveLocation().id;

			const ratingsAndReviews = await AnalyticsService.getOverallRatingAndReviews({ locationId });
			this.setState({ overallRatingsObject: ratingsAndReviews });
		} catch (error) {
			console.log(error);
		}
		this.setState({
			loading: false
		});
	}

	onLocationChanged = () => {
		this.resetComponent();
	};

	renderHeader = () => {
		const { overallRatingsObject, loading } = this.state;
		const { cardName, isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		return (
			<div className="dashboard__card__header dashboard__card__header--full-width">
				<div className="dashboard__card__header__name">{cardName ? cardName : t("Ratings by Site")}</div>
			</div>
		);
	};

	renderBody = () => {
		const { overallRatingsObject, loading } = this.state;
		const { cardName, isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		if (isEnabled && !isEnabled()) {
			return <div className="text-center">{t("Reviews is not enabled. Contact support to get started.")}</div>;
		}

		if (isPermissible && !isPermissible()) {
			return <div className="text-center">{t("Review data is restricted.")}</div>;
		}

		if (loading) {
			return (
				<div className="analytics__section__chart__spinner">
					<Spinners type="tail-fade" loading={true} size="60px" />
				</div>
			);
		}

		return (
			<div className="analytics__ratings-by-site">
				{overallRatingsObject &&
					overallRatingsObject.reviews &&
					overallRatingsObject.reviews.map(aReview => (
						<div key={aReview.name}>
							<div>
								<small className="pull-right Analytics__counts">
									{aReview.avg_rating.toFixed(1)} <i className="fa fa-star" />
								</small>
								<h3>{aReview.name}</h3>
							</div>
							<div className="progress progress-large">
								<div style={{ width: `${aReview.avg_rating * 20}%` }} className="progress-bar progress-bar-primary" />
							</div>
						</div>
					))}
			</div>
		);
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.ratingsBySite.className} height={DASHBOARD_CARDS.ratingsBySite.h}>
				{this.renderHeader()}
				{this.renderBody()}
			</DashboardCard>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(AnalyticsSummary)));
