import UserService from "../services/UserService";

import i18n from "../i18n/config";

const STATUS = {
	active: "active",
	inactive: "inactive",
	deleted: "deleted"
};

export { STATUS };

export const COMPANY_COLUMNS = {
	id: {
		id: "id",
		value: i18n.t("Id"),
		sortable: true,
		sortField: "id",
		width: () => (UserService.isSuperAdminOrCustomerSuccess() ? 1 : 0),
		notClickable: true
	},
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: true,
		sortField: "name",
		width: 5,
		notClickable: true
	},
	actions: {
		id: "actions",
		value: i18n.t("Actions"),
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const COMPANY_FILTERS = {
	all: {
		id: "all",
		display: i18n.t("All"),
		value: "all",
		label: i18n.t("all"),
		order: 1
	},
	favorites: {
		id: "favorites",
		display: i18n.t("Favorites"),
		value: "favorites",
		label: i18n.t("Favorites"),
		order: 2
	}
};

export const MAX_RECENT_COMPANIES_TO_STORE = 7;
