import React, { Component } from "react";
import ContentLoader from "react-content-loader";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import * as Icon from "react-feather";
import { getServices } from "service-fetch";
import moment from "moment";
import ReactTooltip from "react-tooltip";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import ToastService from "../../services/ToastService";
import UtilityService from "../../services/UtilityService";
import MessagesService from "../../services/MessagesService";

import withLocation from "../../components/common/WithLocation";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Tab from "../../components/common/Tab";
import Tabs from "../../components/common/Tabs";
import Action from "../../components/common/Action";
import ManagePostsModal from "./ManagePostsModal";
import Alert from "../../components/common/Alert";
import MediaGrid from "../../components/common/MediaGrid";

import {
	LOCAL_POSTS_ACTIONS,
	LOCAL_POSTS_ACTIONS_LABELS,
	LOCAL_POSTS_MEDIA_FORMATS,
	LOCAL_POSTS_STATES,
	LOCAL_POSTS_TOPIC_TYPES
} from "../../constants/PostsConstants";
import { DATE_FORMAT } from "../../constants/CommonConstants";
import { MEDIA_EXTENSIONS } from "../../constants/Messenger";

import "../../App.css";
import "./posts.css";

const { LocationService } = getServices();

class Posts extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			data: [],
			cachedMedia: {},
			showManageModal: false,
			showConfirmDeletePostModal: false,
			selectedPost: null,
			isGmbConnected: false
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	componentWillUnmount = () => {
		MessagesService.clearLocalMedia();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		let { t } = this.props;

		await this.update({
			loading: true
		});

		try {
			const locationId = UserService.getActiveLocation().id;

			const { data: messengerIntegrations } = await LocationService.hasIntegrations({ params: { locationId } });

			if (!messengerIntegrations || !messengerIntegrations.gmb) {
				this.update({
					loading: false,
					isGmbConnected: false
				});
				return;
			}

			let { data: posts } = await LocationService.getGmbLocalPosts({ params: { locationId } });

			let cachedMedia = {};

			if (!posts) {
				await this.update({
					data: null,
					loading: false,
					isGmbConnected: true,
					cachedMedia
				});
				return;
			}

			for (let i = 0; i < posts.length; i++) {
				const post = posts[i];

				if (!post.media || post.media.length < 1) {
					continue;
				}

				if (!cachedMedia[post.name]) {
					cachedMedia[post.name] = [];
				}

				for (let j = 0; j < post.media.length; j++) {
					const media = post.media[j];
					try {
						// Process media to be used for the media grid in each post
						let localMedia = await this.processPostMedia({ media, mediaIndex: j + 1 });
						cachedMedia[post.name].push(localMedia);
					} catch (error) {
						console.log(error);
					}
				}
			}

			await this.update({
				data: posts,
				isGmbConnected: true,
				loading: false,
				cachedMedia
			});
		} catch (error) {
			console.log(error);

			ToastService.error(t("Error fetching posts. Please try again."));
			await this.update({
				data: [],
				loading: false
			});
		}
	};

	/**
	 * Process all the media in the local posts so that we can store it locally and use our Media Grid components
	 * @param {Object} media The local post media object we want to cache
	 * @param {Object} mediaIndex The index of the media in the local post
	 */
	async processPostMedia({ media, mediaIndex }) {
		try {
			let response = await fetch(media.googleUrl, {
				referrerPolicy: "no-referrer"
			});

			// Convert the response to a Blob
			const blob = await response.blob();
			let extension = MEDIA_EXTENSIONS.mimeType[blob.type];
			let type = blob.type;

			if (media.mediaFormat === LOCAL_POSTS_MEDIA_FORMATS.VIDEO) {
				extension = "mp4";
				type = "video/mp4";
			}

			let file = new File([blob], `media-${mediaIndex}.${extension}`, { type });
			file.googleMedia = media;

			const url = URL.createObjectURL(blob);

			let localMedia = MessagesService.storeLocalMedia({
				file,
				downloadUrl: url
			});

			return localMedia;
		} catch (error) {
			throw error;
		}
	}

	onLocationChanged = location => {
		this.resetComponent();
	};

	onShowCreateModal = async () => {
		await this.update({
			selectedPost: null,
			showManageModal: true
		});
	};

	onHideManageModal = async changesMade => {
		await this.update({
			selectedPost: null,
			showManageModal: false
		});

		if (changesMade) {
			this.update({ loading: true });
			MessagesService.clearLocalMedia();
			// Wait a little bit before fetching the data
			await UtilityService.timeout(2000);

			this.resetComponent();
		}
	};

	onEditLocalPost = async post => {
		await this.update({ selectedPost: post, showManageModal: true });
	};

	onDeleteLocalPost = async post => {
		await this.update({ selectedPost: post, showConfirmDeletePostModal: true });
	};

	onConfirmDeletePost = async confirmed => {
		let { selectedPost } = this.state;
		let { t } = this.props;

		if (!confirmed) {
			await this.update({ selectedPost: null, showConfirmDeletePostModal: false });

			return;
		}

		try {
			await LocationService.deleteGmbLocalPost({
				params: { locationId: UserService.getActiveLocation().id },
				body: {
					localPostName: selectedPost.name
				}
			});

			ToastService.info(t("Local Post Deleted."));

			this.resetComponent();
		} catch (error) {
			console.log(error);
		}

		await this.update({ selectedPost: null, showConfirmDeletePostModal: false });
	};

	renderLoading = () => {
		return (
			<ContentLoader viewBox="0 0 630px 860" height={860} width={"630px"}>
				<rect x="30" y="0" rx="5" ry="5" width="100%" height="200" />
				<rect x="30" y="220" rx="5" ry="5" width="100%" height="200" />
				<rect x="30" y="440" rx="5" ry="5" width="100%" height="200" />
				<rect x="30" y="660" rx="5" ry="5" width="100%" height="200" />
			</ContentLoader>
		);
	};

	renderGooglePosts = () => {
		let { data, isGmbConnected, cachedMedia } = this.state;
		let user = UserService.get();
		let { t } = this.props;

		if (!isGmbConnected) {
			return (
				<div className="posts">
					<div className="posts__connect">
						<div>{t("Your Google Business is not connected.")}</div>
						<img className="posts__connect__img" alt="Connect GMB" src="https://cdn.demandhub.co/web-app/assets/social-growth.svg" />
						{user.GroupPermission.connect_locations && (
							<div className="mb-button" onClick={() => this.props.history.push("/settings/connections")}>
								{t("Connect Google Business")}
							</div>
						)}
					</div>
				</div>
			);
		}

		return (
			<div className="posts">
				{data &&
					data.map(post => {
						let startDate = null;
						let endDate = null;

						if (post.event) {
							let schedule = post.event.schedule;

							startDate = moment()
								.year(schedule.startDate.year)
								.month(schedule.startDate.month - 1)
								.date(schedule.startDate.day)
								.format("MMM D");

							endDate = moment()
								.year(schedule.endDate.year)
								.month(schedule.endDate.month - 1)
								.date(schedule.endDate.day)
								.format("MMM D");
						}

						return (
							<div key={post.name} className="posts__post">
								<div className="posts__post__header">
									<div>
										{t("Type:")} {post.topicType}
									</div>
									<div className="posts_post__header__actions">
										<Action label={t("Edit")} icon={Icon.Edit3} className="posts_post__header__actions__action" onClick={() => this.onEditLocalPost(post)} />
										<Action
											label={t("Delete")}
											icon={Icon.Trash}
											className="posts_post__header__actions__action"
											onClick={() => this.onDeleteLocalPost(post)}
										/>
									</div>
								</div>
								{cachedMedia[post.name] && cachedMedia[post.name].length > 0 && (
									<div className="posts__post__media">
										<MediaGrid mediaList={cachedMedia[post.name]} readOnly={true} referrerPolicy={"no-referrer"} />
									</div>
								)}

								{post.event && (
									<>
										<div className="posts__post__action__label">{t("Title")}</div>
										<div className="posts__post__text">{post.event.title}</div>
									</>
								)}
								{startDate && endDate && (
									<>
										<div className="posts__post__action__label">{t("Event Dates")}</div>
										<div className="posts__post__text">
											{startDate} - {endDate}
										</div>
									</>
								)}

								{post.summary && (
									<>
										<div className="posts__post__action__label">{t("Description")}</div>
										<div className="posts__post__text">{post.summary}</div>
									</>
								)}

								{post.offer && post.offer.couponCode && (
									<>
										<div className="posts__post__action__label">{t("Coupon Code")}</div>
										<div className="posts__post__text">{post.offer.couponCode}</div>
									</>
								)}
								{post.offer && post.offer.redeemOnlineUrl && (
									<>
										<div className="posts__post__action__label">{t("Offer Url")}</div>
										<div className="posts__post__text">
											<a href={post.offer.redeemOnlineUrl}>{post.offer.redeemOnlineUrl}</a>
										</div>
									</>
								)}
								{post.offer && post.offer.termsConditions && (
									<>
										<div className="posts__post__action__label">{t("Terms and Conditions")}</div>
										<div className="posts__post__text">{post.offer.termsConditions}</div>
									</>
								)}

								{post.callToAction && post.callToAction.actionType !== LOCAL_POSTS_ACTIONS.ACTION_TYPE_UNSPECIFIED && (
									<div className="posts__post__action">
										<div className="posts__post__action__button-label">{t("Button")}</div>
										<div className="posts__post__text">{LOCAL_POSTS_ACTIONS_LABELS[post.callToAction.actionType]}</div>
										{post.callToAction.actionType !== LOCAL_POSTS_ACTIONS.CALL && (
											<>
												<div className="posts__post__action__label"> {t("Button Url")}</div>
												<div className="posts__post__text">
													<a href={post.callToAction.url}>{post.callToAction.url}</a>
												</div>
											</>
										)}
									</div>
								)}
								<div className="posts__post__created">
									<div className="posts__post__created__text" data-tip={true} data-for={`posts_post__created__${post.name}__rtt`}>
										{moment(post.createTime).format(DATE_FORMAT)}
										<ReactTooltip
											id={`posts_post__created__${post.name}__rtt`}
											className="mb-react-tooltip mb-react-tooltip--medium text-left"
											arrowColor="#333"
											type="info"
											effect="solid"
											place="right"
										>
											{t("Created On:")} {moment(post.createTime).format("MMMM Do YYYY, h:mm A")} <br></br>
											{t("Last Updated On:")} {moment(post.updateTime).format("MMMM Do YYYY, h:mm A")}
										</ReactTooltip>
									</div>
								</div>
							</div>
						);
					})}
			</div>
		);
	};

	render() {
		let { showManageModal, showConfirmDeletePostModal, selectedPost, loading, isGmbConnected, cachedMedia } = this.state;
		let { t } = this.props;
		let user = UserService.get();

		return (
			<Page>
				<Header title={t("Posts")}>
					{isGmbConnected && user.GroupPermission.connect_locations && (
						<Action id="create" label={t("Create Post")} icon={Icon.Plus} onClick={() => this.onShowCreateModal()} />
					)}
				</Header>

				<Tabs selected={"google"} onSelect={() => {}}>
					<Tab id={"google"} value={t("Google")} />
				</Tabs>

				{!loading && this.renderGooglePosts()}
				{loading && this.renderLoading()}

				<ManagePostsModal
					show={showManageModal}
					onClose={this.onHideManageModal}
					post={selectedPost}
					media={selectedPost ? cachedMedia[selectedPost.name] : []}
				/>

				<Alert
					type="warning"
					show={showConfirmDeletePostModal}
					title={t("Are you sure?")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={this.onConfirmDeletePost}
				>
					{t("Are you sure you want to delete this Local Post?")}
				</Alert>
			</Page>
		);
	}
}
export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(Posts)));
