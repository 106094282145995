import moment from "moment";
import React from "react";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import UserService from "../../services/UserService";
import LocationService from "../../services/LocationService";
import ToastService from "../../services/ToastService";
import GAService from "../../services/GAService";
import { withTranslation } from "react-i18next";

import withLocation from "../../components/common/WithLocation";

import "../../styles/css/scenes/settings-general.css";
import "../../styles/css/components/commons/dh-details.css";
class General extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			locationData: null,
			loading: true,
			fetchError: false,
			monthlyInviteGoal: 0,
			monthlyReviewGoal: 0,
			locationName: "",
			businessPhone: "",
			businessEmail: "",
			locationHandle: "",
			demandHubEmail: "",
			locationAddress: "",
			timezone: ""
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchLocation();
	}

	onLocationChanged = async () => {
		await this.fetchLocation();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	handleSaveClick = async () => {
		const { locationName, businessPhone, businessEmail, locationHandle } = this.state;
		let { t } = this.props;

		const locationData = {};
		locationData.locationId = UserService.getActiveLocation().id;
		locationData.company = UserService.getActiveCompany().id;
		locationData.name = locationName;
		locationData.phone = businessPhone;
		locationData.email = businessEmail;
		locationData.handle = locationHandle;
		let success = await LocationService.update(locationData);
		if (!success) {
			await this.update({ loading: false });
			ToastService.error(t("Sorry, an error was encountered while saving the settings. Please try again."));
			return;
		}
		await this.update({ loading: false });
		ToastService.info(t("Location saved successfully."));
		await this.fetchLocation();
	};

	async fetchLocation() {
		let { t } = this.props;
		try {
			await this.update({ loading: true });

			let location = await LocationService.fetchLocation(UserService.getActiveLocation().id);
			let timezone = await LocationService.fetchTimeZone();

			await this.update({
				locationData: location,
				loading: false,
				locationName: location.name,
				businessPhone: location.phone,
				businessEmail: location.email,
				locationHandle: location.handle,
				demandHubEmail: location.demandHubEmail,
				locationAddress: LocationService.getFullAddress(location),
				timezone: timezone,
				fetchError: false
			});
		} catch (error) {
			await this.update({ loading: false, fetchError: true });
			ToastService.error(t("Sorry, not able to fetch location data. Please try again."));

			console.log(error);
		}
	}

	handleGenericEventHandler(event) {
		this.update({ [event.target.name]: event.target.value });
	}

	handleMonthlyInviteGoalOnChange(e) {
		this.update({ monthlyInviteGoal: parseInt(e.target.value, 10) });
	}

	handleMonthlyReviewGoalOnChange(e) {
		this.update({ monthlyReviewGoal: parseInt(e.target.value, 10) });
	}

	renderPage() {
		const { locationData, fetchError, locationName, businessPhone, businessEmail, locationHandle, demandHubEmail, timezone } = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let location = UserService.getActiveLocation();

		let primaryPhone = "";
		if (locationData) {
			primaryPhone =
				locationData.numbers && locationData.numbers.filter(n => n.safe === 1).length > 0 ? locationData.numbers.filter(n => n.safe)[0].number : "";
		}

		return (
			<div id="settings-page">
				<div className="settings-page__form">
					<div className="dh-details settings-page__form__details">
						{fetchError && (
							<div className="dh-details-item">
								<label>{t("An error occurred. Please try again.")}</label>
							</div>
						)}
						{locationData && (
							<div className="dh-details-info">
								<div className="dh-details-title">{t("Location Details")}</div>

								<div className="dh-details-item">
									<label>{t("Location Name")}</label>
									{user.GroupPermission.update_locations ? (
										<input
											name="locationName"
											id="location_name"
											type="text"
											value={locationName}
											className="Common__input"
											onChange={e => this.handleGenericEventHandler(e)}
										/>
									) : (
										<div>{locationName}</div>
									)}
								</div>
								<div className="dh-details-item">
									<label>{t("Business Phone Number")}</label>
									{user.GroupPermission.update_locations ? (
										<input
											name="businessPhone"
											id="phone"
											type="text"
											value={businessPhone}
											className="Common__input"
											onChange={e => this.handleGenericEventHandler(e)}
										/>
									) : (
										<div>{businessPhone}</div>
									)}
								</div>
								<div className="dh-details-item">
									<label>{t("Business Email")}</label>
									{user.GroupPermission.update_locations ? (
										<input
											name="businessEmail"
											id="email"
											type="text"
											value={businessEmail}
											className="Common__input"
											onChange={e => this.handleGenericEventHandler(e)}
										/>
									) : (
										<div>{businessEmail}</div>
									)}
								</div>
								<div className="dh-details-item">
									<label>{t("Location Website(s)")}</label>
									<div>{location.website}</div>
								</div>
								<div className="dh-details-item">
									<label>{t("Location Handle")}</label>
									<div>{locationHandle}</div>
								</div>
								<div className="dh-details-item">
									<label>{t("DemandHub Phone Number")}</label>
									<div>{primaryPhone}</div>
								</div>
								<div className="dh-details-item">
									<label>{t("DemandHub Email")}</label>
									<div>{demandHubEmail}</div>
								</div>
								<div className="dh-details-item">
									<label>{t("Address")}</label>
									<div>{`${location.address_1} ${location.address_2}`}</div>
								</div>
								<div className="dh-details-item">
									<label>{t("Zip/Postal Code")}</label>
									<div>{locationData.zip_postal}</div>
								</div>
								<div className="dh-details-item">
									<label>{t("City")}</label>
									<div>{`${location.city}, ${location.state_prov}, ${location.country}`}</div>
								</div>
								<div className="dh-details-item">
									<label>{t("Time Zone")}</label>
									<div>
										{t("Time Zone")}: {timezone}
									</div>
									<div>
										{t("Local Time")}:{" "}
										{timezone
											? `${moment()
													.tz(timezone)
													.format("ddd MMM DD YYYY HH:mm:ss")} GMT${moment()
													.tz(timezone)
													.format("Z")}`
											: ""}
									</div>
								</div>
								<div className="dh-details-item">
									<label>{t("Important Information")}</label>
									<div>
										<a href="https://www.demandhub.co/privacy/" target="_blank" rel="noopener noreferrer">
											{t("Privacy Policy")}
										</a>
										<br />
										<a href="https://www.demandhub.co/tou/" target="_blank" rel="noopener noreferrer">
											{t("Terms of Use")}
										</a>
									</div>
								</div>

								{user.GroupPermission.update_locations && (
									<div className="dh-details__save">
										<div id="save" className="mb-button" onClick={this.handleSaveClick}>
											{t("Save")}
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}

	render() {
		let { t } = this.props;
		return (
			<Page>
				<Header title={t("General")} />
				{this.renderPage()}
			</Page>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(General));
