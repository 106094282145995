import React, { Component } from "react";
import posed from "react-pose";
import { withTranslation, Trans } from "react-i18next";

import UserService from "../../../../services/UserService";
import TeamChatService from "../../../../services/TeamChatService";
import NotificationService from "../../../../services/NotificationService";

import { KEYS } from "../../../../constants/Messenger";

import "./mentions.css";

const Box = posed.div({
	visible: {
		y: -120,
		opacity: 1
	},
	hidden: {
		y: 800,
		opacity: 0
	}
});

class Mentions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			allMentions: [],
			possibleMentions: [],
			show: false,
			selectedIndex: 0
		};

		this.current = null;
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async fetchData() {
		let { t } = this.props;

		// Get the members of this team chat / dm
		const conversationId = this.props.conversationId;
		let teamChatDetails = await TeamChatService.fetchConversationDetails(conversationId);

		if (!teamChatDetails) {
			return;
		}

		let allMentions = teamChatDetails.members;

		// Get the user that currently has this conversation open
		let user = UserService.get();

		// If the user is in this conversation, prevent them from being able to mention themselves
		for (let i = 0; i < allMentions.length; i++) {
			let currentUserWeCanMention = allMentions[i];

			if (currentUserWeCanMention.id === user.id) {
				// If we are that user, remove us from the list
				allMentions.splice(i, 1);
				break;
			}
		}

		allMentions.push({
			first_name: t("Everyone"),
			handle: "all",
			last_name: ""
		});

		await this.update({ allMentions });

		await this.resetComponent();
	}

	async componentDidMount() {
		// If we have changed the members associated with the channel, refetch the members
		NotificationService.subscribeOnce("teamChatUpdate", "reFetchData", () => {
			this.fetchData();
		});

		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		let { conversationId, isMentioning, mentionText, show } = this.props;

		if (prevProps.show !== show) {
			this.update({ show });
		}

		if (
			(typeof prevProps.isMentioning !== "undefined" && prevProps.isMentioning !== isMentioning) ||
			(typeof prevProps.mentionText !== "undefined" && prevProps.mentionText !== mentionText)
		) {
			this.resetComponent();
		}

		// Check if we have changed which team chat we're in
		if (conversationId !== prevProps.conversationId) {
			// Refetch the data
			this.fetchData();
		}
	}

	async triggerKeyboardEvent(keyCode) {
		let { selectedIndex, possibleMentions } = this.state;

		if (keyCode === KEYS.enter || keyCode === KEYS.tab) {
			let mention = possibleMentions[selectedIndex];
			await this.onMentionSelected(mention);
		}

		if (keyCode === KEYS.down) {
			selectedIndex++;
		} else if (keyCode === KEYS.up) {
			selectedIndex--;
		}

		if (selectedIndex < 0) {
			selectedIndex = 0;
		} else if (selectedIndex >= possibleMentions.length) {
			selectedIndex = possibleMentions.length - 1;
		}

		await this.update({
			selectedIndex
		});

		if (this.current) {
			this.current.scrollIntoView(false);
		}
	}

	async resetComponent() {
		let { isMentioning, mentionText } = this.props;

		let possibleMentions = this.findPossibleMentions(mentionText);

		if (possibleMentions.length === 0) {
			await this.update({
				possibleMentions: [],
				selectedIndex: 0
			});

			return;
		}

		await this.update({
			possibleMentions,
			selectedIndex: 0
		});
	}

	findPossibleMentions(match) {
		let { allMentions } = this.state;

		if (match.length === 0) {
			return allMentions;
		}

		return allMentions.filter(member => {
			var name = UserService.createFullName({ firstName: member.first_name, lastName: member.last_name });
			return name.toLowerCase().indexOf(match.toLowerCase()) !== -1 || (member.handle && member.handle.toLowerCase().indexOf(match.toLowerCase()) !== -1);
		});
	}

	async onMentionSelected(mention) {
		let mentionHandle = mention.handle;

		if (this.props.onMentionSelected) {
			await this.props.onMentionSelected(mentionHandle);
		}
	}

	render() {
		let { show, possibleMentions, selectedIndex } = this.state;
		let { t } = this.props;

		return (
			<Box className="mb-mentions" pose={show ? "visible" : "hidden"}>
				<div className="mb-mentions-header">
					<div className="mb-mentions-header-title">{t("Select a mention ...")}</div>
				</div>
				<div className="mb-mentions-list">
					{possibleMentions.map((mention, index) => {
						var fullName = UserService.createFullName({ firstName: mention.first_name, lastName: mention.last_name });
						return (
							<div
								ref={ref => {
									if (selectedIndex === index) {
										this.current = ref;
									}
								}}
								key={index}
								className={`mb-mentions-list-item ${selectedIndex === index ? "mb-mentions-list-item--selected" : ""}`}
								onClick={() => this.onMentionSelected(mention)}
							>
								{fullName}
							</div>
						);
					})}
				</div>
			</Box>
		);
	}
}

export default withTranslation(null, { withRef: true })(Mentions);
