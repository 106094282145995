import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";
import moment from "moment";
import ReactTable from "react-table";
import * as Icon from "react-feather";
import cronstrue from "cronstrue";
import ReactTooltip from "react-tooltip";

import GAService from "../../services/GAService";
import UserService from "../../services/UserService";
import CompanyService from "../../services/CompanyService";

import Spinners from "../../components/common/Spinners";
import NoDataComponent from "../../components/common/NoDataComponent";
import Alert from "../../components/common/Alert";
import DHModal from "../../components/common/DHModal";
import LegacyPage from "../../components/common/LegacyPage";

import GROUP_PERMISSIONS from "../../constants/GroupPermissions";

import "../../styles/css/components/commons/react-table.css";
import "../../styles/css/common.css";

class Jobs extends Component {
	constructor(props) {
		super(props);
		let { t } = this.props;

		this.state = {
			user: null,
			cronTasks: [],
			saveError: false,
			saveErrorMessage: t("Sorry, something went wrong. Please try again."),
			loading: true,
			filteredUsers: [],
			showConfirmModal: false,
			selectedFriendlyName: "",
			selectedName: "",
			confimInput: "",
			loadingFailed: false,
			showSuccessModal: false,
			successModalHeader: t("Cron task has successfully ran!"),
			successModalMessage: "",
			nameDesc: false,
			lastRunDesc: true
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchCronTasksData();
	}

	async fetchCronTasksData() {
		let { t } = this.props;

		const authToken = UserService.get() ? UserService.get().auth_token : "";
		try {
			let cronTasks = await CompanyService.fetchCronTasks();
			this.setState({ cronTasks });
			if (cronTasks.length === 0) {
				this.setState({ loadingFailed: true });
			}
			this.setState({ loading: false });
		} catch (error) {
			this.showErrorModal(t("An error occured while trying to fetch cron tasks. Please try again."));
			console.log(error);
			this.setState({ loadingFailed: true, loading: false });
		}
	}

	async handleConfirmModal(name, friendlyName) {
		this.setState({
			showConfirmModal: !this.state.showConfirmModal,
			selectedFriendlyName: friendlyName,
			selectedName: name
		});
	}

	async handleRunCronTask(name, friendlyName) {
		let { t } = this.props;

		try {
			this.setState({
				loading: true
			});

			let data = await CompanyService.runCronTask({ name });
			this.setState({
				loading: false,
				showConfirmModal: false,
				confimInput: "",
				showSuccessModal: true,
				successModalHeader: t("{{friendlyTaskName}} ran successfully!", { taskName: friendlyName }),
				successModalMessage: `${data}`
			});
		} catch (error) {
			this.showErrorModal(t("An error occured while trying to run a cron task. Please try again."));
			console.log(error);
		}
	}

	showErrorModal(message) {
		let { t } = this.props;

		if (!message) {
			message = t("Sorry, something went wrong. Please try again.");
		}

		this.setState({
			saveError: true,
			saveErrorMessage: message,
			loading: false
		});
	}

	inputOnChange = event => {
		this.setState({
			confimInput: event.target.value
		});
	};

	renderRunJobModal = () => {
		let { t } = this.props;

		return (
			<DHModal
				show={this.state.showConfirmModal}
				onHide={() => this.setState({ showConfirmModal: false, selectedFriendlyName: "", selectedName: "", confimInput: "" })}
				title={t("Are you sure?")}
			>
				<div className="modal__flex-container">
					<p>
						<Trans
							i18nKey="Are you sure you want to run <1>{{friendlyTaskName}}</1>?"
							values={{ friendlyTaskName: this.state.selectedFriendlyName }}
							components={{ 1: <strong /> }}
						>
							Are you sure you want to run <strong>{this.state.selectedFriendlyName}</strong>?
						</Trans>
					</p>
					<p>{t("Type the name above in the following input:")}</p>
					<input
						type="text"
						className="Common__input"
						placeholder={t("Enter the friendly name of the cron task")}
						onChange={e => this.inputOnChange(e)}
						value={this.state.confimInput}
					/>
					<br />
					{this.state.confimInput === this.state.selectedFriendlyName && (
						<div
							className="mb-button mb-button--fit"
							id="run-action-btn"
							onClick={e => this.handleRunCronTask(this.state.selectedName, this.state.selectedFriendlyName)}
						>
							{t("Run Task")}
						</div>
					)}
				</div>
			</DHModal>
		);
	};

	render() {
		let { t } = this.props;

		const user = UserService.get();

		if (user.GroupPermission.type !== GROUP_PERMISSIONS.super_administrator.type) {
			this.props.history.push("/dashboard");
		}
		return (
			<LegacyPage>
				<div>
					<Alert
						type="error"
						show={this.state.saveError}
						title={t("Error")}
						confirm={t("OK")}
						onClose={() => this.setState({ saveError: false, saveErrorMessage: t("Sorry, an error was encountered. Please try again.") })}
					>
						<div>{this.state.saveErrorMessage}</div>
					</Alert>
					<Alert
						type="success"
						show={this.state.showSuccessModal}
						title={this.state.successModalHeader}
						confirm={t("OK")}
						onClose={() => this.setState({ showSuccessModal: false })}
					>
						<div>{this.state.successModalMessage}</div>
					</Alert>
					{this.renderRunJobModal()}
					<div className="row wrapper border-bottom white-bg page-heading">
						<div className="col-lg-12">
							<h2>{t("Jobs")}</h2>
						</div>
					</div>
					<div className="wrapper wrapper-content border-bottom main-table-margin">
						<div className="row">
							<div className="col-lg-12">
								<div className="ibox float-e-margins">
									<div className="ibox-content">
										<ReactTable
											data={this.state.cronTasks}
											defaultSorted={[
												{
													id: "name",
													desc: false
												}
											]}
											onSortedChange={sorted => {
												if (sorted.length !== 1) {
													return;
												}
												this.setState({ [sorted[0].id + "Desc"]: sorted[0].desc });
											}}
											columns={[
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															{t("Name")} {this.state.nameDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "friendly_name",
													className: "DHReactTable__cell",
													id: "name",
													Cell: ({ value }) => {
														return <span>{value}</span>;
													}
												},
												{
													Header: () => <div className="DHReactTable__header--container">{t("Schedule")}</div>,
													headerClassName: "DHReactTable__row--header",
													accessor: row => ({
														id: row.id,
														schedule: row.schedule
													}),
													className: "DHReactTable__cell",
													id: "schedule",
													sortable: false,
													Cell: ({ value: { id, schedule } }) => {
														return (
															<>
																<span data-tip data-for={`cron-schedule-tool-tip-${id}`}>
																	{schedule}
																</span>
																<ReactTooltip id={`cron-schedule-tool-tip-${id}`} type="info" effect="solid" place="right">
																	{cronstrue.toString(schedule)}
																</ReactTooltip>
															</>
														);
													}
												},
												{
													Header: () => <div className="DHReactTable__header--container">{t("Status")}</div>,
													headerClassName: "DHReactTable__row--header",
													accessor: "status",
													className: "DHReactTable__cell",
													id: "status",
													sortable: false,
													Cell: ({ value }) => {
														return <span>{value}</span>;
													}
												},
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															{t("Last Ran At")} {this.state.lastRunDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "last_ran_at",
													className: "DHReactTable__cell",
													id: "last_ran_at",
													Cell: ({ value }) => {
														return <span>{value ? moment(value).format("MMMM Do YYYY, h:mm A") : ""}</span>;
													}
												},
												{
													Header: () => <div className="DHReactTable__header--container">{t("Action")}</div>,
													headerClassName: "DHReactTable__row--header",
													accessor: d => ({ name: d.name, friendly_name: d.friendly_name }),
													className: "DHReactTable__cell",
													id: "action",
													sortable: false,
													Cell: ({ value: { name, friendly_name } }) => (
														<div>
															<span style={{ cursor: "pointer", userSelect: "none" }} onClick={() => this.handleConfirmModal(name, friendly_name)}>
																<Icon.Zap />
															</span>
														</div>
													)
												}
											]}
											LoadingComponent={() => (
												<div className="Common__spinnerdiv--center">
													<Spinners loading={this.state.loading} type="tail-fade" size="120px" />
												</div>
											)}
											NoDataComponent={() => <NoDataComponent loading={this.state.loading} />}
											showPagination={this.state.cronTasks.length > 10}
											PreviousComponent={props => (
												<button {...props} className="btn btn-default btn-outline btn-sm pagination-item">
													<Icon.ChevronLeft style={{ paddingTop: "4px" }} size="20" />
												</button>
											)}
											NextComponent={props => (
												<button {...props} className="btn btn-default btn-outline btn-sm pagination-item">
													<Icon.ChevronRight style={{ paddingTop: "4px" }} size="20" />
												</button>
											)}
											defaultPageSize={10}
											className="-striped -highlight"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</LegacyPage>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(Jobs));
