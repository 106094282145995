import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import GAService from "../../../services/GAService";

import ReviewSitePerformance from "./ReviewSitePerformance";
import ReviewSitesResults from "./ReviewSitesResults";
import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";

import "../../../App.css";
import "../../../styles/css/scenes/analytics.css";

class ReviewSitesAnalytics extends Component {
	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
	}

	render() {
		let { t } = this.props;

		return (
			<Page>
				<Header title={t("Review Site Analytics")} />
				<div className="dh-page__cards">
					<ReviewSitePerformance />
					<ReviewSitesResults />
				</div>
			</Page>
		);
	}
}

export default withTranslation(null, { withRef: true })(ReviewSitesAnalytics);
