import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import Header from "../../../components/common/Header";
import List from "../../../components/common/List";
import Page from "../../../components/common/Page";
import Action from "../../../components/common/Action";
import withLocation from "../../../components/common/WithLocation";
import Alert from "../../../components/common/Alert";

import EditSubmissionModal from "./EditSubmissionModal";

import UserService from "../../../services/UserService";
import GAService from "../../../services/GAService";
import ToastService from "../../../services/ToastService";
import A2PService from "../../../services/A2PService";

import { A2P_SUBMISSION_COLUMNS } from "../../../constants/A2P";

class A2PSubmissions extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			submissions: [],
			loading: false,

			selectedSubmissionId: null,
			showCreateModal: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	onLocationChanged = async () => {
		await this.resetComponent();
	};

	resetComponent = async () => {
		await this.update({
			submissions: [],
			config: null,

			selectedSubmissionId: null,
			showCreateModal: false
		});

		await this.fetchSubmissions();
	};

	fetchSubmissions = async () => {
		let companyId = UserService.getActiveCompany().id;

		await this.update({ loading: true });

		let submissions = await A2PService.fetchSubmissions({ companyId });

		await this.update({ submissions, loading: false });
	};

	getHeaders() {
		let headers = {
			items: A2P_SUBMISSION_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	}

	renderRecord = recordData => {
		let { t } = this.props;

		if (!recordData) {
			return null;
		}

		return [
			recordData.id,
			recordData.submission_type,
			recordData.dh_brand_id,
			recordData.dh_campaign_id,
			recordData.tcr_brand_id,
			recordData.tcr_brand_status,
			recordData.tcr_campaign_id,
			recordData.tcr_campaign_status,
			recordData.tcr_campaign_share_status,
			recordData.mock ? t("yes") : t("no"),
			recordData.submission_status,
			recordData.submission_share_status,
			moment(recordData.created_at).format("MMM Do YYYY hh:mm a"),
			<div className="dh-list__actions">
				<Action id={`edit-${recordData.id}`} label={t("Edit")} icon={Icon.Edit} onClick={() => this.onShowCreateModal(recordData.id)} />
				<Action id={`submit-${recordData.id}`} label={t("Submit")} icon={Icon.Cloud} onClick={() => this.onSubmitSubmission(recordData.id)} />
			</div>
		];
	};

	onShowCreateModal = async dhSubmissionId => {
		await this.update({
			selectedSubmissionId: dhSubmissionId,
			showCreateModal: true
		});
	};

	onHideCreateModal = async () => {
		await this.update({
			selectedSubmissionId: null,
			showCreateModal: false
		});
	};

	onSave = async () => {
		let { t } = this.props;

		ToastService.info(t("A2P Ready To Submit!"));
		await this.update({
			selectedSubmissionId: null,
			showCreateModal: false
		});
		await this.fetchSubmissions();
	};

	onSubmitSubmission = async submissionId => {
		let { t } = this.props;

		try {
			let response = await A2PService.submitSubmission({ submissionId });

			if (response) {
				ToastService.info(t("A2P Request Submitted!"));
			} else {
				ToastService.error(t("Failed to submit a2p request"));
			}

			await this.fetchSubmissions();
		} catch (error) {
			console.log(error);
		}
	};

	render() {
		let { submissions, loading, showCreateModal, selectedSubmissionId } = this.state;
		let { t } = this.props;

		return (
			<Page>
				<Header>
					<Action id="create" label={t("Create Submission")} icon={Icon.Plus} onClick={() => this.onShowCreateModal()} />
				</Header>

				<List
					items={submissions}
					loading={loading}
					loadedAll={true}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={() => {}}
					noDataTitle={t("No submission found...")}
					noDataIcon={<Icon.AlertCircle />}
				/>

				<EditSubmissionModal show={showCreateModal} submissionId={selectedSubmissionId} onHide={this.onHideCreateModal} onSave={this.onSave} />
			</Page>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(A2PSubmissions)));
