import React from "react";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";

import UserService from "../../services/UserService";
import { AnalyticsService } from "../../services/AnalyticsService";
import SupportChatService from "../../services/SupportChatService";
import CrmIntegrationsService from "../../services/CrmIntegrationsService";

import withLocation from "../../components/common/WithLocation";
import ConversionSettingsModal from "../Settings/Reengagements/ConversionSettingsModal";
import DashboardCard from "./DashboardCard";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class LifetimeReengagementRevenue extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			reengagementConversions: 0,
			reengagementRevenue: 0,
			hasCrmIntegrations: false,
			showConversionSettingsModal: false
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	onLocationChanged = () => {
		this.fetchData();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	fetchData = async () => {
		try {
			let locationId = UserService.getActiveLocation().id;
			let result = await AnalyticsService.getLifetimeReengagementConversions({ locationId });

			let crmIntegrations = await CrmIntegrationsService.fetch({
				locationId,
				params: { limit: 1, status: ["active"] }
			});

			// Get average appointment cost stored in the location metadata
			let location = await UserService.getActiveLocation();
			let averageConversionValue = location.average_conversion_value;
			let totalConversions = result.conversionCount;
			let reengagementRevenue = Math.floor(totalConversions * averageConversionValue);

			await this.update({
				reengagementConversions: totalConversions,
				reengagementRevenue: reengagementRevenue,
				hasCrmIntegrations: crmIntegrations && crmIntegrations.length > 0 ? true : false
			});
		} catch (error) {
			console.log(error);
		}
	};

	onReengagementsGetStarted = () => {
		SupportChatService.showNewMessage("Hi, I would like to enable Reengagement features. Would you be able to help?");
	};

	onOpenConversionSettingsModal = async id => {
		await this.update({ showConversionSettingsModal: true });
	};

	onHideConversionSettingsModal = async resetComponent => {
		await this.update({ showConversionSettingsModal: false });

		if (resetComponent) {
			this.fetchData();
		}
	};
	renderHeader = () => {
		const { isEnabled } = this.props;
		let { hasCrmIntegrations, reengagementConversions } = this.state;

		return (
			<div className="dashboard__card__header Common__cursor--pointer" onClick={this.onOpenConversionSettingsModal}>
				<div className="dashboard__card__header__icon">
					<Icon.DollarSign size="42" />
				</div>
				<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">
					Lifetime Reengagement Revenue <Icon.Info size={20} data-tip data-for="dashboard-widget-reengagements-revenue-info" />{" "}
				</div>
				<ReactTooltip
					id="dashboard-widget-reengagements-revenue-info"
					className="mb-react-tooltip mb-react-tooltip--large"
					arrowColor="#333"
					type="info"
					effect="solid"
					place="right"
				>
					{reengagementConversions ? (
						<>
							A total of {reengagementConversions} conversions were counted.
							<hr />
						</>
					) : null}
					Shows the revenue generated through an automated reengagement to a past customer or client. Every appointment after a contact is reengaged counts as a
					conversion. This continues until there is a 60-day gap between appointments. Shows cumulative data for all time.
					{isEnabled && !isEnabled() ? " Requires the Reengagement module to be enabled." : null}
					{!hasCrmIntegrations ? " Requires a CRM Integration to be active." : null}
					<br />
					Click to update average contract value.
				</ReactTooltip>
			</div>
		);
	};

	render = () => {
		let { reengagementRevenue, hasCrmIntegrations, showConversionSettingsModal } = this.state;
		const { isEnabled, isPermissible } = this.props;

		if (isEnabled && !isEnabled()) {
			return (
				<DashboardCard
					className={DASHBOARD_CARDS.lifetimeGeneratedRevenueReengagement.className}
					height={DASHBOARD_CARDS.lifetimeGeneratedRevenueReengagement.h}
				>
					{this.renderHeader()}
					<div className="dashboard__widget__conversions">
						<div className="mb-button" onClick={this.onReengagementsGetStarted}>
							Get Started
						</div>
					</div>
				</DashboardCard>
			);
		}

		let content = "N/A";

		// Only show if there are active crm integrations and there is at least some revenue
		if (hasCrmIntegrations && reengagementRevenue && reengagementRevenue > 0) {
			content = `$ ${reengagementRevenue.toLocaleString()}`;
		}
		if (isPermissible && !isPermissible()) {
			content = "N/A";
		}

		return (
			<>
				<DashboardCard
					className={DASHBOARD_CARDS.lifetimeGeneratedRevenueReengagement.className}
					height={DASHBOARD_CARDS.lifetimeGeneratedRevenueReengagement.h}
				>
					{this.renderHeader()}
					<div className="dashboard__card__stat">
						<div className="dashboard__card__stat__count Common__cursor--pointer" onClick={this.onOpenConversionSettingsModal}>
							{content}
						</div>
					</div>
				</DashboardCard>
				<ConversionSettingsModal show={showConversionSettingsModal} onHide={this.onHideConversionSettingsModal} />
			</>
		);
	};
}

export default withLocation(LifetimeReengagementRevenue);
