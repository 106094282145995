import i18n from "../i18n/config";

export const SM_COLUMNS = {
	content: {
		id: "content",
		value: i18n.t("Content"),
		sortable: true,
		sortField: "content",
		width: 2
	},
	contact: {
		id: "contact",
		value: i18n.t("Contact"),
		sortable: false,
		sortField: "contact",
		width: 2
	},
	recipients: {
		id: "recipients",
		value: i18n.t("Recipients"),
		sortable: true,
		sortField: "recipients",
		width: 1,
		tooltip: i18n.t("Only contacts that have enabled receiving transactional messages will receive a message.")
	},
	responses: {
		id: "responses",
		value: i18n.t("Responses"),
		sortable: true,
		sortField: "responses",
		width: 1
	},
	sender: {
		id: "sender",
		value: i18n.t("Sender"),
		sortable: true,
		sortField: "sender",
		width: 1
	},
	send_after: {
		id: "send_after",
		value: i18n.t("Send At"),
		sortable: true,
		sortField: "send_after",
		width: 2
	},
	created_at: {
		id: "created_at",
		value: i18n.t("Date Created"),
		sortable: true,
		sortField: "created_at",
		width: 2,
		widthMd: 0
	},
	actions: {
		id: "actions",
		value: i18n.t("Actions"),
		sortable: false,
		width: 2,
		notClickable: true
	}
};

export const SM_STATES = {
	pending: "pending",
	sending: "sending",
	sent: "sent",
	error: "error"
};

export const SM_TYPES = {
	general: "general",
	nps: "nps",
	reviewInvite: "review_invite",
	reengagement: "reengagement"
};

export const SM_TYPES_FULL = {
	general: i18n.t("Message"),
	nps: i18n.t("NPS Request"),
	reviewInvite: i18n.t("Review Invite"),
	reengagement: i18n.t("Reengagement")
};

export const SM_FILTERS_STATE = {
	pending: {
		id: "pending",
		value: i18n.t("Pending"),
		order: 1
	},
	sent: {
		id: "sent",
		value: i18n.t("Sent"),
		order: 2
	},
	cancelled: {
		id: "cancelled",
		value: i18n.t("Cancelled"),
		order: 3
	}
};

export const SM_TABS = {
	general: {
		id: "general",
		value: i18n.t("General"),
		route: "/scheduled-messages/general"
	},
	nps: {
		id: "nps",
		value: i18n.t("NPS"),
		route: "/scheduled-messages/nps"
	},
	reviewInvite: {
		id: "reviewInvite",
		value: i18n.t("Review Invite"),
		route: "/scheduled-messages/review-invite"
	},
	reengagement: {
		id: "reengagement",
		value: i18n.t("Reengagement"),
		route: "/scheduled-messages/reengagement"
	}
};
