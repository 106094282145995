import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";

import LocationService from "../../services/LocationService";
import UserService from "../../services/UserService";

import withLocation from "../../components/common/WithLocation";
import DashboardCard from "./DashboardCard";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class ReviewGoal extends PureComponent {
	state = { monthlyReviews: 0, monthlyReviewGoal: 0 };

	componentDidMount() {
		this.fetchData();
	}

	onLocationChanged = () => {
		this.fetchData();
	};

	fetchData = async () => {
		let data = await LocationService.fetchReviewGoals(UserService.getActiveLocation().id);

		if (!data) {
			this.setState({ monthlyReviews: 0, monthlyReviewGoal: 0 });
			return;
		}

		this.setState({
			monthlyReviews: data.monthlyReviewCount,
			monthlyReviewGoal: data.monthlyReviewGoal
		});
	};

	render() {
		const { monthlyReviews, monthlyReviewGoal } = this.state;
		const { cardName, isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		if ((isEnabled && !isEnabled()) || (isPermissible && !isPermissible())) {
			return (
				<DashboardCard className={DASHBOARD_CARDS.reviewGoalThisMonth.className} height={DASHBOARD_CARDS.reviewGoalThisMonth.h}>
					<div>{cardName ? cardName : t("Invitation Goal This Month")}</div>
					<div className="text-center">
						{!isEnabled() ? <>{t("Review Invites are not enabled. Contact support to get started.")}</> : <>{t("Review Invite Data Restricted.")}</>}
					</div>
				</DashboardCard>
			);
		}

		let reviewPercent = 0;
		if (monthlyReviewGoal >= 0) {
			reviewPercent = monthlyReviews / monthlyReviewGoal;
		}

		return (
			<DashboardCard className={DASHBOARD_CARDS.reviewGoalThisMonth.className} height={DASHBOARD_CARDS.reviewGoalThisMonth.h}>
				<div>
					<span>{cardName ? cardName : t("Review Goal This Month")}</span>
					<small className="pull-right">
						{monthlyReviews}/{monthlyReviewGoal}
					</small>
				</div>
				<div className="progress progress-large">
					<div style={{ width: parseInt(reviewPercent * 100, 10) + "%" }} className="progress-bar progress-bar-success" />
				</div>
				<div className="text-center">{t("Review stats are updated every 24 hours.")}</div>
			</DashboardCard>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(ReviewGoal));
