import React, { Component } from "react";
import ReactSelect from "react-select";
import { withTranslation, Trans } from "react-i18next";
import { withRouter } from "react-router-dom";

import UserService from "../../../services/UserService";
import ToastService from "../../../services/ToastService";
import BookingRequestService from "../../../services/BookingRequestService";
import TranslationService from "../../../services/TranslationService";

import DHModal from "../../../components/common/DHModal";
import Input from "../../../components/common/Input";
import LanguageSelectorMini from "../../../components/common/LanguageSelectorMini";

import "../../../styles/css/scenes/booking-widget-services.css";

class CreateBookingSetting extends Component {
	constructor(props) {
		super(props);

		this.state = {
			duration: "",
			textFields: {
				name: {
					en_CA: "",
					fr_CA: "",
					en_US: "",
					es_US: "",
					en_GB: ""
				},
				description: {
					en_CA: "",
					fr_CA: "",
					en_US: "",
					es_US: "",
					en_GB: ""
				}
			},
			users: [],
			selectedUser: { label: "", value: "" },
			limit: 100,

			languageSectionSupportedOptions: [],
			languageSection: "en_CA"
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		this.resetComponent();
	}

	async resetComponent() {
		if (this.props.appointmentSetting) {
			if (this.props.appointmentSetting.duration) {
				await this.update({ duration: this.props.appointmentSetting.duration });
			}

			if (this.props.appointmentSetting.text_fields) {
				try {
					let textFields = JSON.parse(this.props.appointmentSetting.text_fields);
					await this.update({ textFields: textFields });
				} catch (error) {
					console.log("Booking Config: An error occured reading the text_fields");
				}
			}
		}

		await this.setLanguageSelectionOptions();
		await this.fetchUsers();
	}

	setLanguageSelectionOptions = async () => {
		let locationLanguage = TranslationService.getLocationLanguage();
		let supportedLocationOptions = TranslationService.getSupportedLanguagesOptions();

		await this.update({
			languageSectionSupportedOptions: supportedLocationOptions,
			languageSection: locationLanguage
		});
	};

	onNameInputChange(event) {
		let { languageSection, textFields } = this.state;

		if (event.target.value.length > 24) {
			return;
		}

		let newTextFields = JSON.parse(JSON.stringify(textFields));
		newTextFields["name"][languageSection] = event.target.value;

		this.update({ textFields: newTextFields });
	}

	onDescriptionInputChange(event) {
		let { languageSection, textFields } = this.state;

		if (event.target.value.length > 24) {
			return;
		}

		let newTextFields = JSON.parse(JSON.stringify(textFields));
		newTextFields["description"][languageSection] = event.target.value;

		this.update({ textFields: newTextFields });
	}

	onDurationInputChange(event) {
		this.update({ duration: event.target.value });
	}

	async fetchUsers() {
		const { appointmentSetting } = this.props;
		let { limit } = this.state;

		const response = await UserService.fetchUsers({ companyId: UserService.getActiveCompany().id, limit });

		const dropDownUsers = response.users.map(aUser => {
			var fullName = UserService.createFullName({ firstName: aUser.first_name, lastName: aUser.last_name });
			return { label: `${fullName}`, value: aUser.id };
		});

		await this.update({ users: dropDownUsers });

		let selectedUser;
		if (!appointmentSetting) {
			selectedUser = dropDownUsers.find(aUser => aUser.value === UserService.get().id);
		} else {
			var propsUser = appointmentSetting.User;

			var fullName = UserService.createFullName({ firstName: propsUser.first_name, lastName: propsUser.last_name });
			selectedUser = { label: `${fullName}`, value: appointmentSetting.user_id };
		}

		await this.update({ selectedUser });
	}

	async onSubmit() {
		const { textFields, duration, selectedUser } = this.state;
		const { onSubmit, onHide, appointmentSetting } = this.props;
		let { t } = this.props;

		if (!selectedUser || !selectedUser.value) {
			ToastService.error(t("Please select a user from the dropdown"));
			return;
		}

		const data = {
			textFields: JSON.stringify(textFields),
			duration,
			user_id: selectedUser.value
		};

		try {
			// If creating an appointment setting
			if (!appointmentSetting) {
				data.locationId = UserService.getActiveLocation().id;
				await BookingRequestService.createAppointmentSetting(data);
				ToastService.info(t("Appointment Service Created!"));
			} else {
				// On update
				data.id = appointmentSetting.id;
				await BookingRequestService.updateAppointmentSetting(data);
				ToastService.info(t("Appointment Service Updated!"));
			}

			if (onSubmit) {
				await onSubmit();
			}

			onHide();
		} catch (error) {
			console.log(error);
			if (!appointmentSetting) {
				ToastService.error(t(`Error creating appointment service!`));
			} else {
				ToastService.error(t(`Error updating appointment service!`));
			}
		}
	}

	onUserSelect(value) {
		this.update({ selectedUser: value });
	}

	renderForm() {
		const { users, duration, textFields, languageSection, languageSectionSupportedOptions, selectedUser } = this.state;
		let { t } = this.props;

		let showLanguageSelectors = languageSectionSupportedOptions.length > 1;

		let name = textFields.name[languageSection];
		let description = textFields.description[languageSection];

		return (
			<form className="form-horizontal" style={{ width: "100%" }}>
				{showLanguageSelectors && (
					<div className="form-group cbs__create_modal__language_container">
						<LanguageSelectorMini
							language={languageSection}
							languageOptions={languageSectionSupportedOptions}
							onChange={newLanguage => {
								this.update({ languageSection: newLanguage });
							}}
						/>
					</div>
				)}
				<div className="form-group">
					<label htmlFor="service" className="col-sm-4 control-label">
						{t("Service")}
					</label>
					<div className="col-sm-8">
						<input id="service" type="text" className="Common__input" value={name} onChange={event => this.onNameInputChange(event)} />
					</div>
				</div>
				<div className="form-group">
					<label htmlFor="description" className="col-sm-4 control-label">
						{t("Description")}
					</label>
					<div className="col-sm-8">
						<Input id="description" type="textarea" value={description} onChange={event => this.onDescriptionInputChange(event)} />
					</div>
				</div>
				<div className="form-group">
					<label htmlFor="description" className="col-sm-4 control-label">
						{t("Offered By")}
					</label>
					<div className="col-sm-8">
						<ReactSelect id="offeredBy" value={selectedUser} onChange={value => this.onUserSelect(value)} options={users} placeholder={t("Select a user...")} />{" "}
					</div>
				</div>

				<div className="form-group">
					<label htmlFor="duration" className="col-sm-4 control-label">
						{t("Duration (minutes)")}
					</label>
					<div className="col-sm-8">
						<input id="duration" type="number" className="Common__input" value={duration} onChange={event => this.onDurationInputChange(event)} />
					</div>
				</div>
				<br />
				<div type="submit" className="mb-button mb-button--fit" onClick={e => this.onSubmit()} style={{ marginLeft: "auto" }}>
					{t("Save")}
				</div>
			</form>
		);
	}

	render() {
		const { showModal, appointmentSetting, onHide } = this.props;
		let { t } = this.props;

		let title = "";
		if (!appointmentSetting) {
			title = t("Create Service");
		} else {
			title = t("Update Service");
		}

		return (
			<DHModal
				title={title}
				show={showModal}
				onHide={() => {
					if (onHide) {
						onHide();
					}
				}}
			>
				<div style={{ width: 350 }}>{this.renderForm()}</div>
			</DHModal>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(CreateBookingSetting));
