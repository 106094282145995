import React from "react";
import { withTranslation } from "react-i18next";

import Modal from "../../../components/common/DHModal";
import Input from "../../../components/common/Input";
import SearchInput from "../../../components/common/SearchInput";

import ToastService from "../../../services/ToastService";
import CompanyService from "../../../services/CompanyService";

class DeleteRedisKeyModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			key: ""
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onHide = () => {
		let { onHide } = this.props;

		this.update({ key: "" });

		if (this.props.onHide) {
			onHide();
		}
	};

	onDeleteKey = async () => {
		if (!this.canSave()) {
			return;
		}

		let { key } = this.state;

		let success = await CompanyService.deleteCachedKey({ key });

		if (!success) {
			ToastService.error("Failed to delete key.");
			return;
		}

		ToastService.info(`Key: ${key} deleted.`);

		this.onHide();
	};

	canSave = () => {
		let { key } = this.state;

		if (!key || key.length < 1) {
			return false;
		}

		return true;
	};

	handleGenericEventHandler = event => {
		let name = event.target.name;
		let value = event.target ? event.target.value : event;
		this.update({ [name]: value, edited: true });
	};

	render = () => {
		let { show } = this.props;
		let { key } = this.state;
		let { t } = this.props;

		return (
			<Modal show={show} title={t("Delete Cached Redis Key")} onHide={this.onHide}>
				<div className="modal__body">
					<Input id="key" name="key" label={"Key"} type="text" onChange={this.handleGenericEventHandler} value={key} />

					<div className="modal__actions">
						<div className="mb-button mb-button--cancel" onClick={this.onHide}>
							{t("Cancel")}
						</div>
						<div className={`mb-button ${this.canSave() ? "" : "mb-button--disabled"}`} onClick={this.onDeleteKey}>
							{t("Delete Key")}
						</div>
					</div>
				</div>
			</Modal>
		);
	};
}

export default withTranslation(null, { withRef: true })(DeleteRedisKeyModal);
