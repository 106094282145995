import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { withTranslation } from "react-i18next";

import CsvService from "../../services/CsvService";

import Modal from "../../components/common/DHModal";

class ExportContactsModal extends Component {
	render() {
		const { show, onHide, exportContacts } = this.props;
		const locationName = CsvService.filterActiveLocationName();
		let { t } = this.props;

		return (
			<Modal show={show} onHide={onHide} title={t("Download Contacts")}>
				<div className="modal__flex-container">
					{exportContacts && exportContacts.length > 0 ? (
						<>
							<p>{t("Are you sure you want to download these contacts?")}</p>
							<div className="mb-button mb-button--fit">
								<CSVLink data={exportContacts} filename={`${locationName}-contacts.csv`} target="_self">
									<span onClick={onHide}>{t("Yes")}</span>
								</CSVLink>
							</div>
						</>
					) : (
						<p>{t("No contacts selected.")}</p>
					)}
				</div>
			</Modal>
		);
	}
}

export default withTranslation(null, { withRef: true })(ExportContactsModal);
