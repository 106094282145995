import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";

import withLocation from "../../components/common/WithLocation";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Tabs from "../../components/common/Tabs";
import Invites from "./Invites";

import NotificationService from "../../services/NotificationService";

import { INVITES_TAB } from "../../constants/Invites";

import "../../styles/css/scenes/invites.css";

class InvitesPage extends Component {
	constructor(props) {
		super(props);

		let selectedTab = this.setSelectedTab(this.props.location);

		this.state = {
			selectedTab
		};
	}

	componentDidMount() {
		NotificationService.subscribe("onUrlChange", ({ location, action }) => {
			let selectedTab = this.setSelectedTab(location);

			this.update({
				selectedTab
			});
		});
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = () => {};

	setSelectedTab = location => {
		let selectedTab = INVITES_TAB.invites.id;
		let routes = Object.values(INVITES_TAB);

		for (let i = 0; i < routes.length; i++) {
			const route = routes[i];

			if (route.route === location.pathname) {
				selectedTab = route.id;
				break;
			}
		}

		return selectedTab;
	};

	onTabSelect = async tab => {
		await this.update({ selectedTab: tab.id });
		this.props.history.push(INVITES_TAB[tab.id].route);
	};

	render() {
		let { selectedTab } = this.state;
		let { t } = this.props;

		if (!this.props.match.params.tab) {
			return <Redirect to={INVITES_TAB.invites.route} />;
		}

		return (
			<Page>
				<Header title={t("Review Invites")}></Header>
				<Tabs onSelect={this.onTabSelect} selected={selectedTab} routing={true} tabs={Object.values(INVITES_TAB)}>
					<Switch>
						<Route key="review-invites" path={INVITES_TAB.invites.route}>
							<Invites />
						</Route>

						<Route key="reminders" path={INVITES_TAB.reminder.route}>
							<Invites reminders={true} />
						</Route>
					</Switch>
				</Tabs>
			</Page>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(InvitesPage)));
