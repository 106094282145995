import React, { Component } from "react";
import * as Icon from "react-feather";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import { withTranslation, Trans } from "react-i18next";

import Modal from "../../../components/common/DHModal";
import SearchInput from "../../../components/common/SearchInput";
import Input from "../../../components/common/Input";

import { CONVERSATION, CHANNEL_TYPE } from "../../../constants/Messenger";

import UserService from "../../../services/UserService";
import TeamChatService from "../../../services/TeamChatService";

import "./create-conversation.css";

class CreateConversation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			searchInput: "",
			selectedList: [],
			filteredList: [],
			users: [],
			name: "",
			channelType: { value: CHANNEL_TYPE.general.id, label: CHANNEL_TYPE.general.label },
			channelTypeOptions: [
				{ value: CHANNEL_TYPE.general.id, label: CHANNEL_TYPE.general.label },
				{ value: CHANNEL_TYPE.broadcast.id, label: CHANNEL_TYPE.broadcast.label }
			],
			step: 2
		};

		this.searchInput = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { show, type } = this.props;
		let user = UserService.get();
		let location = UserService.getActiveLocation();

		let users = await TeamChatService.fetchUsers({ companyId: location.company_id, enableTeamChat: true });

		let index = users.findIndex(u => {
			return u.id === user.id;
		});

		users.splice(index, 1);

		await this.update({
			show: show,
			users: users,
			filteredList: users,
			selectedList: [],
			type: type,
			name: "",
			searchInput: "",
			step: type === CONVERSATION.channel ? 1 : 2,
			channelType: { value: CHANNEL_TYPE.general.id, label: CHANNEL_TYPE.general.label }
		});

		if (this.nameInput) {
			this.nameInput.focus();
		}
	}

	isNextValid() {
		let { step, name } = this.state;

		return step === 1 && name.length > 0;
	}

	isValid() {
		let { selectedList, name, type } = this.state;

		if (type === CONVERSATION.dm) {
			return selectedList.length > 0;
		}

		return selectedList.length > 0 && name.length > 0;
	}

	onCancel = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onNext = async () => {
		if (!this.isNextValid()) {
			return;
		}

		let { step } = this.state;

		await this.update({
			step: step + 1
		});

		if (this.searchInput) {
			this.searchInput.focus();
		}
	};

	onCreate = () => {
		let { selectedList, type, name, channelType } = this.state;

		if (!this.isValid()) {
			selectedList = [];
		}

		if (this.props.onClose) {
			this.props.onClose(selectedList, type, name, channelType.value);
		}
	};

	async onUserRemove(user) {
		let { selectedList, filteredList } = this.state;

		if (selectedList.length === 0) {
			return;
		}

		let index = selectedList.findIndex(selectedUser => {
			return selectedUser.id === user.id;
		});

		if (index === -1) {
			return;
		}

		selectedList.splice(index, 1);
		filteredList.push(user);

		await this.update({
			selectedList: selectedList,
			filteredList: filteredList
		});
	}

	onUserSelected = async user => {
		let { selectedList } = this.state;

		selectedList.push(user);

		await this.update({
			selectedList: selectedList
		});

		await this.filterUsers();
	};

	async filterUsers() {
		let { filteredList, selectedList } = this.state;

		filteredList = filteredList.filter(user => {
			let isSelected = selectedList.some(selectedUser => {
				return selectedUser.id === user.id;
			});

			if (isSelected) {
				return false;
			}

			return true;
		});

		await this.update({
			filteredList: filteredList
		});
	}

	isUserSelected(userId) {
		let { selectedList } = this.state;

		return selectedList.some(selectedUser => {
			return selectedUser.id === userId;
		});
	}

	onSearchInput = async value => {
		let { users, selectedList } = this.state;
		let searchInput = value.toLowerCase();

		await this.update({
			searchInput: searchInput
		});

		let filteredList = users.filter(user => {
			let isSelected = selectedList.some(selectedUser => {
				return selectedUser.id === user.id;
			});

			if (isSelected) {
				return false;
			}

			var firstName = user.first_name || "";
			var lastName = user.last_name || "";
			var userEmail = user.email || "";

			return (
				firstName.toLowerCase().indexOf(searchInput) !== -1 ||
				lastName.toLowerCase().indexOf(searchInput) !== -1 ||
				userEmail.toLowerCase().indexOf(searchInput) !== -1
			);
		});

		await this.update({
			filteredList: filteredList
		});
	};

	onNameInput = async event => {
		await this.update({
			name: event.target.value
		});
	};

	renderStepOne = () => {
		let { step, channelType, channelTypeOptions } = this.state;
		let { t } = this.props;

		if (step === 2) {
			return null;
		}

		return (
			<>
				<Input ref={ref => (this.nameInput = ref)} id="mb-cc__search-input" placeholder={t("Channel name ...")} onChange={this.onNameInput} fullWidth={true} />

				<br />
				<div data-tip data-for="mb-cc__channel-type">
					{t("Channel Type")} <Icon.Info size={14} />
				</div>
				<ReactTooltip id="mb-cc__channel-type" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
					<div>{t("In a Broadcast Channel only channel admins are able to send messages.")}</div>
				</ReactTooltip>
				<div className="mb-cc__channel-type__select">
					<Select
						id="channel_type"
						name="channel_type"
						options={channelTypeOptions}
						value={channelType}
						placeholder={t("Channel Type...")}
						onChange={option => this.update({ channelType: option })}
					/>
				</div>

				<div onClick={this.onNext} id="mb-button" className={`mb-button ${this.isNextValid() ? "" : "mb-button--disabled"}`}>
					{t("Next")}
				</div>
			</>
		);
	};

	renderStepTwo = () => {
		let { filteredList, selectedList, step } = this.state;
		let { t } = this.props;

		if (step === 1) {
			return null;
		}

		return (
			<>
				<SearchInput
					ref={ref => (this.searchInput = ref)}
					id="mb-cc__search-input"
					placeholder={t("Search ...")}
					onChange={this.onSearchInput}
					debounce={false}
					showSearchIcon={false}
					fullWidth={true}
				/>

				<div className="mb-cc__search">
					<div className="mb-cc__search__members">
						{filteredList.slice(0, 10).map(user => {
							return (
								<div key={user.id} className="mb-cc__search__members__item">
									<div>
										{user.first_name} {user.last_name}
									</div>
									<div className="mb-cc__search__members__item__add-icon" onClick={() => this.onUserSelected(user)}>
										<Icon.PlusCircle size="16" />
									</div>
								</div>
							);
						})}
					</div>
					{selectedList.length > 0 && (
						<div className="mb-cc__search__members">
							{selectedList.map(user => {
								return (
									<div key={user.id} className="mb-cc__search__members__item">
										<div>
											{user.first_name} {user.last_name}
										</div>
										<div className="mb-cc__search__members__item__remove-icon" onClick={() => this.onUserRemove(user)}>
											<Icon.XCircle size="16" />
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>

				<div onClick={this.isValid() ? this.onCreate : null} id="mb-button" className={`mb-button ${this.isValid() ? "" : "mb-button--disabled"}`}>
					{t("Create")}
				</div>
			</>
		);
	};

	render() {
		let { show, type } = this.state;
		let { t } = this.props;

		let title = t("Direct Message");

		if (type === CONVERSATION.channel) {
			title = t("Create a Channel");
		}

		return (
			<Modal show={show} onHide={this.onCancel} title={title}>
				<div className="mb-cc__subtitle">
					{type === CONVERSATION.channel
						? t("Create a purpose driven chat for colleagues in a department or division.")
						: t("Start a direct chat with your colleagues. You can have two or more people in a direct chat.")}
				</div>
				{this.renderStepOne()}
				{this.renderStepTwo()}
			</Modal>
		);
	}
}

export default withTranslation(null, { withRef: true })(CreateConversation);
