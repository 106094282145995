import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";

import Modal from "../../../components/common/DHModal";
import Input from "../../../components/common/Input";
import DHSelect from "../../../components/common/DHSelect";

import { CONVERSATION } from "../../../constants/Messenger";
import { STATUS } from "../../../constants/CommonConstants";

import TeamChatService from "../../../services/TeamChatService";
import ToastService from "../../../services/ToastService";

import "./create-conversation.css";

class ManageConversationGroup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			selectedConversations: [],
			assignableConversations: []
		};

		this.searchInput = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { group, type } = this.props;

		let name = "";

		if (group) {
			name = group.group_name;
		}

		let conversationGroups = {};
		let selectedConversations = [];
		let assignableConversations = [];

		if (group && type === CONVERSATION.channel) {
			conversationGroups = await TeamChatService.fetchTeamChatConversationGroups({ groupId: group.group_id, conversationType: type });
		} else if (group && type === CONVERSATION.dm) {
			conversationGroups = await TeamChatService.fetchTeamChatConversationGroups({ groupId: group.group_id, conversationType: type });
		}

		if (conversationGroups.groups || conversationGroups.otherConversations) {
			let groups = conversationGroups.groups;
			let otherConversations = conversationGroups.otherConversations;

			// Get all assignable conversations
			assignableConversations = [];

			if (groups) {
				for (let i = 0; i < groups.length; i++) {
					const group = groups[i];

					assignableConversations.push(...group.conversations);
					selectedConversations.push(...group.conversations);
				}
			}

			if (otherConversations) {
				assignableConversations.push(...otherConversations);
			}
		}

		assignableConversations = assignableConversations.map(convo => {
			return {
				value: convo.id,
				label: convo.name
			};
		});

		selectedConversations = selectedConversations.map(convo => {
			return {
				value: convo.id,
				label: convo.name
			};
		});

		await this.update({
			name,
			selectedConversations,
			assignableConversations
		});

		if (this.nameInput) {
			this.nameInput.focus();
		}
	}

	isCreateMode() {
		return !this.props.group;
	}

	onClose = group => {
		if (this.props.onClose) {
			this.props.onClose(group);
		}

		this.update({
			name: "",
			selectedConversations: [],
			assignableConversations: []
		});
	};

	isValid() {
		let { name } = this.state;
		return name && name.length > 0;
	}

	onNameInput = async event => {
		await this.update({
			name: event.target.value
		});
	};

	onSave = async () => {
		let { type, group } = this.props;
		let { name, selectedConversations } = this.state;
		let { t } = this.props;

		if (this.isCreateMode()) {
			let group = await TeamChatService.createConversationGroup({ name, conversationType: type });

			if (!group) {
				ToastService.info(t("Error while creating folder. Please try again."));
				return;
			}

			ToastService.info(t(`Conversation folder has been created.`));

			this.onClose(group);

			return;
		}

		let groupId = group.group_id;

		let response = await TeamChatService.updateConversationGroup({
			id: groupId,
			name,
			conversationIds: selectedConversations.map(convo => convo.value)
		});

		if (!response) {
			ToastService.info(t("Error while updating group. Please try again."));
			return;
		}

		ToastService.info(t(`Group has been updated.`));

		await this.unHideConversations();

		this.onClose(response);
	};

	unHideConversations = async () => {
		let { selectedConversations } = this.state;

		for (let i = 0; i < selectedConversations.length; i++) {
			const convo = selectedConversations[i];
			try {
				await TeamChatService.unhideConversation(convo.value);
			} catch (error) {
				console.log(error);
			}
		}
	};

	onDelete = async () => {
		let { group } = this.props;
		let { name } = this.state;
		let { t } = this.props;

		if (this.isCreateMode()) {
			return;
		}

		let groupId = group.group_id;

		let response = await TeamChatService.updateConversationGroup({
			id: groupId,
			status: STATUS.deleted,
			conversationIds: [] // Ensure no conversations are assigned
		});

		if (!response) {
			ToastService.info(t("Error while updating conversation folder. Please try again."));
			return;
		}

		ToastService.info(t(`Conversation folder has been updated.`));

		await this.unHideConversations();

		this.onClose(response);
	};

	renderUpdateSettings() {
		let { selectedConversations, assignableConversations } = this.state;
		let { type } = this.props;
		let { t } = this.props;

		return (
			<DHSelect
				label={type === CONVERSATION.channel ? t("Channels") : t("DMs")}
				placeholder={"Select Conversations..."}
				name="group-conversations"
				id="group-conversations"
				className={"manage-conversation-group__select"}
				onChange={value => {
					this.update({
						selectedConversations: value
					});
				}}
				value={selectedConversations}
				options={assignableConversations}
				isMulti={true}
				isClearable={false}
			/>
		);
	}

	render() {
		let { show, type } = this.props;
		let { name, selectedConversations } = this.state;
		let { t } = this.props;

		let title = type === CONVERSATION.channel ? t("Create Channel Folder") : t("Create Direct Messaging Folder");

		if (!this.isCreateMode()) {
			title = type === CONVERSATION.channel ? t("Update Channel Folder") : t("Update Direct Messaging Folder");
		}

		return (
			<Modal show={show} onHide={this.onClose} title={title}>
				<Input
					ref={ref => (this.nameInput = ref)}
					id="fds"
					label={t("Folder Name")}
					placeholder={t("Folder name ...")}
					value={name}
					onChange={this.onNameInput}
					fullWidth={true}
				/>

				{!this.isCreateMode() && this.renderUpdateSettings()}

				<div className="modal__actions">
					{!this.isCreateMode() && (
						<div onClick={this.onDelete} id="delete-button" className={`mb-button mb-button--cancel`}>
							{t("Delete")}
						</div>
					)}

					{type === CONVERSATION.dm && selectedConversations && selectedConversations.length > 0 && (
						<div onClick={this.unHideConversations} id="unhide-button" className={`mb-button`}>
							{t("Unhide All Conversations")}
						</div>
					)}

					<div onClick={this.isValid() ? this.onSave : null} id="save-button" className={`mb-button ${this.isValid() ? "" : "mb-button--disabled"}`}>
						{t("Save")}
					</div>
				</div>
			</Modal>
		);
	}
}

export default withTranslation(null, { withRef: true })(ManageConversationGroup);
