import React from "react";

import MediaGridItem from "./MediaGridItem";

import "../../styles/css/components/commons/media-grid.css";

class MediaGrid extends React.Component {
	onMediaClicked = url => {
		if (this.props.onMediaClicked) {
			this.props.onMediaClicked(url);
		}
	};

	render = () => {
		let { mediaList, readOnly, referrerPolicy } = this.props;

		return (
			<div className="media-grid">
				{mediaList.map((mediaItem, index) => {
					let url = mediaItem.download_url;

					return (
						<MediaGridItem
							key={index}
							mediaItem={mediaItem}
							onMediaClicked={() => this.onMediaClicked(url)}
							readOnly={readOnly}
							referrerPolicy={referrerPolicy}
						/>
					);
				})}
			</div>
		);
	};
}

export default MediaGrid;
