import UserService from "../services/UserService";
import GROUP_PERMISSIONS from "./GroupPermissions";

import i18n from "../i18n/config";

export const SHORT_LINK_COLUMNS = {
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: true,
		sortField: "name",
		width: 1
	},
	type: {
		id: "type",
		value: i18n.t("Type"),
		sortable: true,
		sortField: "type",
		width: () => (UserService.isSuperAdminOrCustomerSuccess() ? 1 : 0)
	},
	short_url: {
		id: "short_url",
		value: i18n.t("Short Url"),
		sortable: true,
		sortField: "short_url",
		width: 2
	},
	long_url: {
		id: "long_url",
		value: i18n.t("Long Url"),
		sortable: true,
		sortField: "long_url",
		width: 3
	},
	open_count: {
		id: "open_count",
		value: i18n.t("Open Count"),
		sortable: true,
		sortField: "open_count",
		width: 1
	},
	last_open_time: {
		id: "last_open_time",
		value: i18n.t("Last Open Time"),
		sortable: true,
		sortField: "last_open_time",
		width: 2
	},
	expire_at: {
		id: "expire_at",
		value: i18n.t("Expire At"),
		sortable: true,
		sortField: "expire_at",
		width: 2
	},
	action: {
		id: "action",
		value: i18n.t("Action"),
		sortable: false,
		sortField: "action",
		width: 1,
		notClickable: true
	}
};

export const SHORT_LINK_TABS = {
	custom: {
		id: "custom",
		value: i18n.t("Custom")
	},
	details: {
		id: "details",
		value: i18n.t("Details")
	},
	qrCode: {
		id: "qrCode",
		value: i18n.t("QR Code")
	}
};

export const SHORT_LINK_TYPES = {
	general: "general",
	payments: "payments",
	secureChat: "secure_chat",
	videoChat: "video_chat",
	media: "media",
	reviewRequest: "review_request",
	bookingRequest: "booking_request",
	custom: "custom"
};

export const SHORT_LINK_TYPE_LABEL = {
	general: i18n.t("General"),
	payments: i18n.t("Payments"),
	secureChat: i18n.t("Secure Chat"),
	videoChat: i18n.t("Video Chat"),
	media: i18n.t("Media"),
	reviewRequest: i18n.t("Review Request"),
	bookingRequest: i18n.t("Booking Request"),
	custom: i18n.t("Custom")
};

export const SHORT_LINK_TYPE_FILTERS = {
	all: {
		id: "all",
		display: i18n.t("All"),
		order: 0
	},
	custom: {
		id: "custom",
		display: i18n.t("Custom"),
		order: 1
	},
	bookingRequest: {
		id: "booking_request",
		display: i18n.t("Booking Request"),
		order: 2
	},
	reviewRequest: {
		id: "review_request",
		display: i18n.t("Review Request"),
		order: 3
	},
	media: {
		id: "media",
		display: i18n.t("Media"),
		order: 4
	},
	videoChat: {
		id: "video_chat",
		display: i18n.t("Video Chat"),
		order: 5
	},
	secureChat: {
		id: "secure_chat",
		display: i18n.t("Secure Chat"),
		order: 6
	},
	payments: {
		id: "payments",
		display: i18n.t("Payments"),
		order: 7
	}
};

export const SHORT_LINK_EXPIRED_FILTERS = {
	all: {
		id: "all",
		display: i18n.t("All"),
		order: 0
	},
	valid: {
		id: "valid",
		display: i18n.t("Valid Only"),
		order: 1
	},
	expired: {
		id: "expired",
		display: i18n.t("Expired"),
		order: 2
	}
};

export const SHORT_LINK_LIMIT = 10;
