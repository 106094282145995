import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";

import MessagesService from "../../../../services/MessagesService";
import UserService from "../../../../services/UserService";
import GAService from "../../../../services/GAService";

import { KEYS } from "../../../../constants/Messenger";
import { GA_CATEGORIES, GA_ACTIONS } from "../../../../constants/GAConstants";

import "./edit-contact-name.css";
class EditContactName extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			editMode: false,
			enableSave: false
		};

		this.nameInput = null;
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { contactId, locationId, name } = this.props;

		if (prevProps.contactId !== contactId || prevProps.locationId !== locationId || prevProps.name !== name) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { contactId, locationId, name } = this.props;

		await this.update({
			contactId: contactId,
			locationId: locationId,
			originalName: name,
			name: name,
			editMode: name.length === 0
		});

		if (name.length === 0) {
			this.nameInput.focus();
		}
	}

	onEditName = async () => {
		let user = UserService.get();

		if (!user.GroupPermission.update_contacts) {
			return;
		}

		await this.update({
			editMode: true
		});

		this.nameInput.focus();

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.thread,
			action: GA_ACTIONS.messenger.thread.customer.topControls.openRenameContactTool,
			label: GA_ACTIONS.messenger.thread.customer.topControls.openRenameContactTool
		});
	};

	onNameInput = e => {
		let { originalName } = this.state;
		let value = e.target.value;

		let enableSave = value.length > 0 && value !== originalName;

		this.update({
			name: value,
			enableSave: enableSave
		});
	};

	onCancel = async () => {
		let { originalName } = this.state;

		await this.update({
			editMode: false,
			name: originalName
		});
	};

	onSave = async () => {
		let { enableSave, locationId, contactId, name } = this.state;

		if (!enableSave) {
			return;
		}

		let nameChanged = await MessagesService.updateContactName(locationId, contactId, name);

		if (!nameChanged) {
			// alert modal here
			return;
		}

		if (this.props.onNameChanged) {
			this.props.onNameChanged(name);
		}

		await this.update({
			editMode: false,
			originalName: name,
			enableSave: false
		});
	};

	onKeyDown = async event => {
		if (event.keyCode === KEYS.enter) {
			event.preventDefault();
			this.onSave();
		} else if (event.keyCode === KEYS.esc) {
			event.preventDefault();
			this.onCancel();
		}
	};

	render() {
		let { name, editMode, enableSave } = this.state;
		let { t } = this.props;

		return (
			<div className="mb-edit-contact-name">
				{!editMode && (
					<div id="mb-edit-contact-name-text" className="mb-edit-contact-name-text" onClick={this.onEditName}>
						{name || t("Unknown")}
					</div>
				)}
				{editMode && (
					<div className="mb-edit-contact-name-edit">
						<input
							ref={ref => (this.nameInput = ref)}
							id="mb-edit-contact-name-input"
							className="mb-edit-contact-name-input"
							placeholder={t("Edit contact name ...")}
							value={name}
							onChange={this.onNameInput}
							onKeyDown={this.onKeyDown}
						/>
						<div
							id="mb-edit-contact-button--success"
							onClick={this.onSave}
							className={`mb-edit-contact-button mb-edit-contact-button--success ${enableSave ? "" : "mb-edit-contact-button--disabled"}`}
						>
							{t("Save")}
						</div>
						<div onClick={this.onCancel} className="mb-edit-contact-button mb-edit-contact-button--cancel">
							{t("Cancel")}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(EditContactName);
