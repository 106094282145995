import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";

import moment from "moment";
import c3 from "c3";
import { CSVLink } from "react-csv";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import * as Icon from "react-feather";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import { CsvService } from "../../services/CsvService";
import { AnalyticsService } from "../../services/AnalyticsService";

import Spinners from "../../components/common/Spinners";
import Action from "../../components/common/Action";
import withLocation from "../../components/common/WithLocation";
import DashboardCard from "../Dashboard/DashboardCard";

import { AnalyticsConstants } from "../../constants/AnalyticsConstants";
import { DASHBOARD_CARDS } from "../../constants/Dashboard";

import "react-day-picker/lib/style.css";
import "../../App.css";
import "../../styles/css/scenes/analytics.css";

class WidgetAnalytics extends Component {
	constructor(props) {
		super(props);
		let { t } = this.props;

		this.state = {
			data: null,
			loading: false,
			loadingError: false,
			start: moment()
				.subtract(30, "days")
				.toDate(),
			end: moment().toDate(),
			csvData: null,

			chartTitle: t("Webchat Statistics"),
			selectedTab: this.props.selectedTab ? this.props.selectedTab : AnalyticsConstants.APP_NAME.webchat
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedTab !== this.props.selectedTab) {
			this.fetchData();
		}
	}

	onLocationChanged = async () => {
		this.fetchData();
	};

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.setState({ start });
	};

	handleToChange = end => {
		this.setState({ end }, this.showFromMonth);
	};

	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}

	handleUpdateDate() {
		this.fetchData();
	}

	fetchData = async () => {
		try {
			await this.update({ loading: true });

			const { start, end } = this.state;
			const { selectedTab } = this.props;

			let locationId = UserService.getActiveLocation().id;

			let data = await AnalyticsService.getWidgetAnalytics(locationId, selectedTab, start, end);

			if (!data) {
				await this.update({
					loading: false,
					loadingError: true
				});
				return;
			}

			await this.update({
				data: data
			});
			this.updateChart();
		} catch (error) {
			console.log(error);
		}
		this.setState({ loading: false });
	};

	async updateChart() {
		let { t } = this.props;
		await this.update({ loading: false });
		const xAxis = this.state.data.map(anInbound => anInbound.date);
		xAxis.unshift("x");

		let dateColumns = [...xAxis];
		dateColumns[0] = t("Date");

		let y1 = [];
		let y2 = [];

		const { selectedTab } = this.props;

		if (selectedTab === AnalyticsConstants.APP_NAME.webchat) {
			y1 = this.state.data.map(anOpened => anOpened.webchat_loaded);
			y1.unshift(t("Loaded"));

			y2 = this.state.data.map(anOpened => anOpened.webchat_opened);
			y2.unshift(t("Opened"));
		} else if (selectedTab === AnalyticsConstants.APP_NAME.reviewsWidget) {
			y1 = this.state.data.map(anOpened => anOpened.reviews_widget_loaded);
			y1.unshift(t("Loaded"));

			y2 = this.state.data.map(anOpened => anOpened.reviews_widget_opened);
			y2.unshift(t("Opened"));
		} else if (selectedTab === AnalyticsConstants.APP_NAME.bookingWidget) {
			y1 = this.state.data.map(anOpened => anOpened.booking_widget_opened);
			y1.unshift(t("Opened"));

			y2 = this.state.data.map(anOpened => anOpened.booking_widget_submitted);
			y2.unshift(t("Submitted"));
		}

		let csvData = [dateColumns, y1, y2];
		csvData = csvData[0].map((col, i) => csvData.map(row => row[i])); // transpose the data

		await this.update({
			data: [xAxis, y1, y2],
			csvData: csvData
		});

		this.generateChart(`#${this.chartId()}`, this.state.data);
	}

	generateChart(id, columns) {
		try {
			let { t } = this.props;
			const { start, end } = this.state;
			const isMonth = moment(end).diff(moment(start), "days") >= 90;
			c3.generate({
				bindto: id,
				data: {
					x: "x",
					columns: columns,
					type: "line"
				},
				size: {
					height: 260
				},
				axis: {
					x: {
						label: t("Day"),
						type: "timeseries",
						tick: {
							format: function(x) {
								if (isMonth) {
									const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
									return "" + x.getFullYear() + " " + monthString[x.getMonth()];
								}
								//return monthString[x.getMonth()];
								return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
							},
							rotate: window.innerWidth > 768 ? 0 : 75,
							multiline: false
							//format: '%Y' // format string is also available for timeseries data
						}
					},
					y: {
						label: t("Counts")
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
	}

	chartId = () => {
		let chartId = `widget-stats-chart`;
		const { selectedTab } = this.props;

		if (selectedTab === AnalyticsConstants.APP_NAME.reviewsWidget) {
			chartId = `reviews-widget-stats-chart`;
		} else if (selectedTab === AnalyticsConstants.APP_NAME.bookingWidget) {
			chartId = `booking-widget-stats-chart`;
		}
		return chartId;
	};

	renderDownloadAction = () => {
		const { csvData } = this.state;
		const { selectedTab } = this.props;
		const locationName = CsvService.filterActiveLocationName();
		let { t } = this.props;

		let fileName = `${locationName}-webchat_stats.csv`;

		if (selectedTab === AnalyticsConstants.APP_NAME.reviewsWidget) {
			fileName = `${locationName}-reviews_widget_stats.csv`;
		} else if (selectedTab === AnalyticsConstants.APP_NAME.bookingWidget) {
			fileName = `${locationName}-booking_widget_stats.csv`;
		}

		if (!csvData) {
			return null;
		}

		return (
			<CSVLink data={csvData} filename={fileName} target="_self">
				<Action label={t("Download")} icon={Icon.Download} className="Common__csv-link">
					<Icon.Download />
				</Action>
			</CSVLink>
		);
	};

	renderDatepicker = () => {
		const { start, end } = this.state;
		let { t } = this.props;

		const modifiers = { start, end };

		return (
			<div className="dashboard__card___datepicker">
				<label>{t("Date Range")}&nbsp;&nbsp;</label>
				<div className="input-group" id="datepicker">
					<div className="InputFromTo">
						<DayPickerInput
							value={start}
							placeholder={t(" From")}
							format="LL"
							formatDate={formatDate}
							parseDate={parseDate}
							dayPickerProps={{
								selectedDays: [start, { from: start, to: end }],
								disabledDays: { after: end },
								toMonth: end,
								modifiers,
								numberOfMonths: 2,
								onDayClick: () => this.to.getInput().focus()
							}}
							onDayChange={start => this.handleFromChange(start)}
						/>{" "}
						<span className="InputFromTo-to">
							<DayPickerInput
								ref={el => (this.to = el)}
								value={end}
								placeholder={t(" To")}
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { before: start },
									modifiers,
									month: start,
									fromMonth: start,
									numberOfMonths: 2
								}}
								onDayChange={this.handleToChange}
							/>
						</span>
					</div>
				</div>{" "}
				<button id="date-update" className="mb-button" onClick={e => this.handleUpdateDate(e)}>
					{t("Update Date")}
				</button>
			</div>
		);
	};

	renderBody = () => {
		const { loading } = this.state;
		const { isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		if (isEnabled && !isEnabled()) {
			return <div>{t("Messenger is not enabled. Contact support to get started.")}</div>;
		}

		if (isPermissible && !isPermissible()) {
			return <div>{t("Messenger and widget data is restricted.")}</div>;
		}

		if (loading) {
			return (
				<div className="analytics__section__chart__spinner">
					<Spinners type="tail-fade" loading={true} size="60px" />
				</div>
			);
		}

		return (
			<div>
				<div id={this.chartId()} />
			</div>
		);
	};

	render() {
		let { cardName } = this.props;

		return (
			<DashboardCard className={DASHBOARD_CARDS.widgetWebchatAnalytics.className} height={DASHBOARD_CARDS.widgetWebchatAnalytics.h}>
				<div className="dashboard__card__header dashboard__card__header--full-width">
					<div className="dashboard__card__header__name">{cardName}</div>
					<div className="dashboard__card__header__actions">{this.renderDownloadAction()}</div>
				</div>
				{this.renderDatepicker()}
				<div>{this.renderBody()}</div>
			</DashboardCard>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(WidgetAnalytics)));
