import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import { withTranslation, Trans } from "react-i18next";

import withLocation from "../../../components/common/WithLocation";

import WebchatPreviewBubble from "./WebchatPreviewBubble";
import WebchatPreviewTooltip from "./WebchatPreviewTooltip";

import "react-toastify/dist/ReactToastify.css";
import "../../../styles/css/scenes/web-chat-config.css";

class WebchatPreview extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	renderTooltip() {
		return null;
	}

	render() {
		let { t } = this.props;

		return (
			<div className="wcc__preview__core">
				<div className="wcc__preview__core__header">
					<Icon.Eye size="18" /> {t("Preview")}
				</div>
				<div className="wcc__preview__iframe">
					<WebchatPreviewBubble bubble={this.props.bubble} languageSectionBubbleText={this.props.languageSectionBubbleText} position={this.props.position} />
					<WebchatPreviewTooltip
						chatHead={this.props.chatHead}
						showTooltip={this.props.showTooltip}
						tooltipGreeting={this.props.tooltipGreeting}
						tooltipImage={this.props.tooltipImage}
						tooltipBackground={this.props.tooltipBackground}
						tooltipForeground={this.props.tooltipForeground}
						boldTooltip={this.props.tooltipBold}
						language={this.props.languageSectionTooltipGreeting}
						position={this.props.position}
					/>
				</div>
			</div>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(WebchatPreview)));
