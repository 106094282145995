import i18n from "../i18n/config.js";

export const GROUP_COLUMNS = {
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: true,
		sortField: "name",
		width: 2
	},
	created_at: {
		id: "created_at",
		value: i18n.t("Date Created"),
		sortable: true,
		sortField: "created_at",
		width: 2,
		widthMd: 0
	},
	updated_at: {
		id: "updated_at",
		value: i18n.t("Last Updated"),
		sortable: true,
		sortField: "updated_at",
		width: 2,
		widthMd: 0
	},
	actions: {
		id: "actions",
		value: i18n.t("Actions"),
		sortable: false,
		width: 2,
		notClickable: true
	}
};
