import i18n from "../i18n/config";

export const USERS_COLUMNS = {
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: true,
		sortField: "name",
		width: 2
	},
	email: {
		id: "email",
		value: i18n.t("Email"),
		sortable: true,
		sortField: "email",
		width: 2
	},
	confirmed: {
		id: "type",
		value: i18n.t("Type"),
		sortable: true,
		sortField: "type",
		width: 1
	},
	lastLogin: {
		id: "login",
		value: i18n.t("Last Login"),
		sortable: true,
		sortField: "last_login_date",
		width: 2
	},
	actions: {
		id: "actions",
		value: i18n.t("Actions"),
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const MANAGE_USER_TABS = {
	user: {
		id: "user",
		value: i18n.t("User")
	},
	sessions: {
		id: "sessions",
		value: i18n.t("Sessions")
	}
};

export const SESSIONS_COLUMNS = {
	origin: {
		id: "origin",
		value: i18n.t("Origin"),
		sortable: true,
		sortField: "origin",
		width: 1
	},
	usage_count: {
		id: "usage_count",
		value: i18n.t("Usage Count"),
		sortable: true,
		sortField: "usage_count",
		width: 1
	},
	time_elapsed: {
		id: "time_elapsed",
		value: i18n.t("Time Elapsed"),
		sortable: false,
		sortField: "time_elapsed",
		width: 1
	},
	updated_at: {
		id: "updated_at",
		value: i18n.t("Last Activity"),
		sortable: true,
		sortField: "updated_at",
		width: 2
	},
	created_at: {
		id: "created_at",
		value: i18n.t("Date Created"),
		sortable: true,
		sortField: "created_at",
		width: 2
	}
};

export const USER_INVITES_COLUMNS = {
	email: {
		id: "email",
		value: i18n.t("Email"),
		sortable: true,
		sortField: "email",
		width: 2
	},
	enable_teamchat: {
		id: "enable_teamchat",
		value: i18n.t("Teamchat Enabled"),
		sortable: false,
		sortField: "enable_teamchat",
		width: 1
	},
	status: {
		id: "status",
		value: i18n.t("Status"),
		sortable: false,
		sortField: "status",
		width: 1
	},
	expiry_date: {
		id: "expiry_date",
		value: i18n.t("Expiry Date"),
		sortable: false,
		sortField: "expiry_date",
		width: 1
	},
	created_at: {
		id: "created_at",
		value: i18n.t("Date Created"),
		sortable: true,
		sortField: "created_at",
		width: 2
	},
	actions: {
		id: "actions",
		value: i18n.t("Actions"),
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const LOGIN_METHOD = {
	email: {
		id: "email",
		label: i18n.t("Email")
	},
	twoFactor: {
		id: "twoFactor",
		label: i18n.t("Two Factor")
	},
	google: {
		id: "google",
		label: i18n.t("Google")
	}
};

export const USERS_OVERVIEW_TABS = {
	users: {
		id: "users",
		value: i18n.t("Users")
	},
	invites: {
		id: "invites",
		value: i18n.t("Invites")
	}
};

export const EMAIL_RECIPIENT_TYPE = {
	to: {
		value: "to",
		label: i18n.t("To")
	},
	cc: {
		value: "cc",
		label: i18n.t("CC")
	},
	bcc: {
		value: "bcc",
		label: i18n.t("BCC")
	}
};
