import i18n from "../i18n/config";
// Let's not translate this one.

export const A2P_BRAND_COLUMNS = {
	id: {
		id: "id",
		value: "ID",
		sortable: false,
		sortField: "id",
		width: 1
	},
	company_id: {
		id: "company_id",
		value: "Company ID",
		sortable: false,
		sortField: "company_id",
		width: 1
	},
	display_name: {
		id: "display_name",
		value: "Display Name",
		sortable: false,
		sortField: "display_name",
		width: 1
	},
	vertical: {
		id: "vertical",
		value: "Vertical",
		sortable: false,
		sortField: "vertical",
		width: 1
	},
	created_at: {
		id: "created_at",
		value: "Created At",
		sortable: false,
		sortField: "created_at",
		width: 1
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		sortField: "actions",
		width: 1
	}
};

export const A2P_CAMPAIGN_COLUMNS = {
	id: {
		id: "id",
		value: "ID",
		sortable: false,
		sortField: "id",
		width: 1
	},
	location_id: {
		id: "location_id",
		value: "Location ID",
		sortable: false,
		sortField: "location_id",
		width: 1
	},
	usecase: {
		id: "usecase",
		value: "Usecase",
		sortable: false,
		sortField: "usecase",
		width: 1
	},
	description: {
		id: "description",
		value: "Description",
		sortable: false,
		sortField: "description",
		width: 1
	},
	auto_renewal: {
		id: "auto_renewal",
		value: "Auto Renewal",
		sortable: false,
		sortField: "auto_renewal",
		width: 1
	},
	created_at: {
		id: "created_at",
		value: "Created At",
		sortable: false,
		sortField: "created_at",
		width: 1
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		sortField: "actions",
		width: 1
	}
};

export const A2P_SUBMISSION_COLUMNS = {
	id: {
		id: "id",
		value: "ID",
		sortable: false,
		sortField: "id",
		width: 1
	},
	submission_type: {
		id: "submission_type",
		value: "Type",
		sortable: false,
		sortField: "submission_type",
		width: 2
	},
	dh_brand_id: {
		id: "dh_brand_id",
		value: "Brand",
		sortable: false,
		sortField: "dh_brand_id",
		width: 1
	},
	dh_campaign_id: {
		id: "dh_campaign_id",
		value: "Campaign",
		sortable: false,
		sortField: "dh_campaign_id",
		width: 1
	},
	tcr_brand_id: {
		id: "tcr_brand_id",
		value: "TCR Brand ID",
		sortable: false,
		sortField: "tcr_brand_id",
		width: 2
	},
	tcr_brand_status: {
		id: "tcr_brand_status",
		value: "Brand Status",
		sortable: false,
		sortField: "tcr_brand_status",
		width: 2
	},
	tcr_campaign_id: {
		id: "tcr_campaign_id",
		value: "TCR Camp ID",
		sortable: false,
		sortField: "tcr_campaign_id",
		width: 2
	},
	tcr_campaign_status: {
		id: "tcr_campaign_status",
		value: "Camp Status",
		sortable: false,
		sortField: "tcr_campaign_status",
		width: 2
	},
	tcr_campaign_share_status: {
		id: "tcr_campaign_share_status",
		value: "Share Status",
		sortable: false,
		sortField: "tcr_campaign_share_status",
		width: 2
	},
	mock: {
		id: "mock",
		value: "Mock",
		sortable: false,
		sortField: "mock",
		width: 1
	},
	submission_status: {
		id: "submission_status",
		value: "Status",
		sortable: false,
		sortField: "submission_status",
		width: 1
	},
	submission_share_status: {
		id: "submission_share_status",
		value: "Share Status",
		sortable: false,
		sortField: "submission_share_status",
		width: 1
	},
	created_at: {
		id: "created_at",
		value: "Created At",
		sortable: false,
		sortField: "created_at",
		width: 1
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		sortField: "actions",
		width: 2
	}
};

export const A2P_WEBHOOK_EVENT_COLUMNS = {
	id: {
		id: "id",
		value: "ID",
		sortable: false,
		sortField: "id",
		width: 1
	},
	company_id: {
		id: "company_id",
		value: "Company ID",
		sortable: false,
		sortField: "company_id",
		width: 1
	},
	location_id: {
		id: "location_id",
		value: "Location ID",
		sortable: false,
		sortField: "location_id",
		width: 1
	},
	tcr_brand_id: {
		id: "tcr_brand_id",
		value: "TCR Brand",
		sortable: false,
		sortField: "tcr_brand_id",
		width: 1
	},
	tcr_campaign_id: {
		id: "tcr_campaign_id",
		value: "TCR Campaign",
		sortable: false,
		sortField: "tcr_campaign_id",
		width: 1
	},
	event_type: {
		id: "event_type",
		value: "Event Type",
		sortable: false,
		sortField: "event_type",
		width: 2
	},
	description: {
		id: "description",
		value: "Description",
		sortable: false,
		sortField: "event_description",
		width: 3
	},
	created_at: {
		id: "created_at",
		value: "Created At",
		sortable: false,
		sortField: "created_at",
		width: 2
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		sortField: "actions",
		width: 1
	}
};

export const A2P_NUMBER_ASSOCIATIONS_COLUMNS = {
	id: {
		id: "id",
		value: "ID",
		sortable: false,
		width: 1
	},
	number: {
		id: "number",
		value: "Number",
		sortable: false,
		width: 1
	},
	tcrCampaignId: {
		id: "tcrCampaignId",
		value: "TCR Campaign ID",
		sortable: false,
		width: 1
	},
	cnpId: {
		id: "cnpId",
		value: "CNP",
		sortable: false,
		width: 1
	},
	submissionStatus: {
		id: "submissionStatus",
		value: "Submission Status",
		sortable: false,
		width: 1
	},
	association_status: {
		id: "associationStatus",
		value: "Association Status",
		sortable: false,
		width: 1
	},
	created_at: {
		id: "created_at",
		value: "Created At",
		sortable: false,
		width: 1
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		sortField: "actions",
		width: 1
	}
};

export const EDIT_BRAND_TABS = {
	contact: {
		id: "contact",
		value: "Contact"
	},
	address: {
		id: "address",
		value: "Address"
	},
	businessProfile: {
		id: "businessProfile",
		value: "Business Profile"
	},
	misc: {
		id: "misc",
		value: "Misc"
	}
};

export const EDIT_CAMPAIGN_TABS = {
	basics: {
		id: "basics",
		value: "Basics"
	},
	options: {
		id: "options",
		value: "Options"
	},
	samples: {
		id: "samples",
		value: "Samples"
	},
	keywords: {
		id: "keywords",
		value: "Keywords"
	},
	misc: {
		id: "misc",
		value: "Misc"
	}
};
