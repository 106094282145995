import React from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isListItemNode } from "@lexical/list";
import { $getSelection, $isRangeSelection } from "lexical";
import { KEY_ENTER_COMMAND } from "lexical";

const LowPriority = 1;

class PressEnterPluginClass extends React.Component {
	componentDidMount() {
		const { editor, onEnter } = this.props;

		this.unregisterEnter = editor.registerCommand(
			KEY_ENTER_COMMAND,
			event => {
				// Someone else wants the keyboard enter event
				if (editor.shouldDisableEnterPlugin) {
					return true;
				}

				const selection = $getSelection();

				// Ensure we have a valid range selection
				if (!$isRangeSelection(selection)) {
					return false;
				}

				// Check if any parent node of the current anchor is a ListItemNode
				const anchorNode = selection.anchor.getNode();
				let currentNode = anchorNode;

				while (currentNode) {
					if ($isListItemNode(currentNode)) {
						// Allow default behavior (new list item)
						return false; // Returning false allows propagation
					}
					currentNode = currentNode.getParent();
				}

				// Check if Shift key is pressed
				if (event.shiftKey) {
					// Allow Shift+Enter to create a new line
					return false; // Returning false allows propagation
				}

				// Prevent the default behavior of adding a new line
				event.preventDefault();

				// Call the provided callback function for Enter without Shift
				if (onEnter) {
					onEnter(event);
				}

				// Return true to stop further propagation
				return true;
			},
			LowPriority
		);
	}

	componentWillUnmount() {
		if (this.unregisterEnter) {
			this.unregisterEnter();
		}
	}

	render() {
		// This plugin doesn't render anything
		return null;
	}
}

// Functional component wrapper
function PressEnterPlugin({ onEnter }) {
	const [editor] = useLexicalComposerContext();

	return <PressEnterPluginClass editor={editor} onEnter={onEnter} />;
}

export default PressEnterPlugin;
