import React from "react";

import "../../styles/css/components/commons/card.css";

class DHCardSubheader extends React.PureComponent {
	render() {
		return <div className="dh-card__sub-header">{this.props.children}</div>;
	}
}
export default DHCardSubheader;
