import React, { Component } from "react";
import * as Icon from "react-feather";
import posed from "react-pose";
import SearchInput from "../../../../components/common/SearchInput";
import GifCarousel from "./GifCarousel";
import { withTranslation, Trans } from "react-i18next";

import { KEYS } from "../../../../constants/Messenger";

import "./gifs.css";

const BoxGifs = posed.div({
	visible: {
		y: 40,
		opacity: 1
	},
	hidden: {
		y: 800,
		opacity: 0
	}
});

const BoxOverlay = posed.div({
	visible: {
		y: -125,
		opacity: 1
	},
	hidden: {
		y: 350,
		opacity: 0
	}
});

class Gifs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			file: null,
			previewUrl: null,
			searchKey: ""
		};

		this.giphyFetcher = null;

		this.searchComponent = null;
		this.gifsBox = null;
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		// Listen to key and clicks on the page
		document.addEventListener("mousedown", this.handleClick, false);
		document.addEventListener("keydown", this.onKey);
	}

	componentWillUnmount() {
		// Stop listening to keys and clicks on the page
		document.removeEventListener("mousedown", this.handleClick, false);
		document.removeEventListener("keydown", this.onKey);
	}

	handleClick = e => {
		if (this.gifsBox && this.gifsBox.contains(e.target)) {
			// the click is in the gifs component
			return;
		}
		// We clicked outside the gifs component
		this.onCloseSelection();
	};

	onKey = e => {
		if (e.keyCode === KEYS.esc) {
			e.preventDefault();
			this.onCloseSelection();
		}
	};

	onSearchChange = async value => {
		await this.update({
			searchKey: value
		});
	};

	onCloseSelection = () => {
		let { file } = this.state;

		// Tell the search component to clear search
		this.searchComponent.onClose();

		// Tell parent component to hide the gifs component
		if (this.props.onChange) {
			this.props.onChange(file);
		}
	};

	removeGifUpload = () => {
		this.update({
			file: null,
			previewUrl: ""
		});

		// Tell parent component we no longer point to a file
		if (this.props.onChange) {
			this.props.onChange(null);
		}
	};

	onGifSelected = async ({ url, title, fileType }) => {
		// Create loading spinner
		await this.update({
			fetchingImage: true,
			previewUrl: url
		});

		// Fetch and create a binary file using blob
		let response = await fetch(url);
		let blob = await response.blob();
		let name = title + fileType;

		let file = new File([blob], name);

		// Update our file to be this blob object, stop loading spinner
		await this.update({
			file: file,
			fetchingImage: false
		});

		// Inform the parent component we have changed
		this.props.onChange(file);

		// Tell the search component to clear search
		this.searchComponent.onClose();
	};

	boxStyles = () => {
		let { inputComposeHeight, isRichtext } = this.props;

		if (!inputComposeHeight) {
			return null;
		}

		if (isRichtext) {
			return { marginBottom: `150px` };
		} else {
			return { marginBottom: `${inputComposeHeight - 30}px` };
		}
	};

	render() {
		let { file, previewUrl, searchKey } = this.state;
		let { show, showOverlay, lowResolution } = this.props;
		let boxStyles = this.boxStyles();
		let { t } = this.props;

		// Declare url for giphy attribution
		const giphyAttibutionUrl = "https://cdn.demandhub.co/web-app/assets/giphy.png";

		return (
			<>
				<BoxOverlay className="mb-gif-preview" pose={showOverlay ? "visible" : "hidden"} style={boxStyles}>
					{file && (
						<div className="mb-gif-preview__remove" onClick={this.removeGifUpload}>
							<div>
								<Icon.XCircle size="42" />
							</div>
							{t("Remove")}
						</div>
					)}
					<img className="mb-gif-preview__image" src={previewUrl} alt="A gif attachment" />
				</BoxOverlay>

				<BoxGifs ref={ref => (this.gifsBox = ref)} className="mb-gifs" pose={show ? "visible" : "hidden"}>
					<div className="mb-gifs-wrapper">
						<div className="mb-gifs__header">
							<div className="mb-gifs__header__close" onClick={this.onCloseSelection}>
								<Icon.X size="18" />
							</div>
						</div>
						<div className="mb-gifs__search">
							<SearchInput ref={ref => (this.searchComponent = ref)} placeholder="Search ..." onChange={this.onSearchChange} />
						</div>

						<GifCarousel show={show} className="mb-gifs__carousel" onGifSelected={this.onGifSelected} searchKey={searchKey} lowResolution={lowResolution} />
						<img className="mb-gifs__attribution" src={giphyAttibutionUrl} alt="Giphy Attribution" />
					</div>
				</BoxGifs>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(Gifs);
