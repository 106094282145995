import UserService from "../services/UserService";
import GROUP_PERMISSIONS from "./GroupPermissions";

import i18n from "../i18n/config";

export const LOCATION_FEATURES = {
	reviews: {
		id: "reviews",
		name: i18n.t("Reviews")
	},
	reviews_auto_requests: {
		id: "reviews_auto_requests",
		name: i18n.t("Reviews - Auto Review Requests")
	},
	reviews_auto_reminders: {
		id: "reviews_auto_reminders",
		name: i18n.t("Reviews - Auto Review Reminders")
	},
	reviews_auto_requests_v2: {
		id: "reviews_auto_requests_v2",
		name: i18n.t("Reviews - Auto Review Requests V2 (via Workflows)")
	},
	reviews_managed: {
		id: "reviews_managed",
		name: i18n.t("Reviews - Managed Auto Review Requests")
	},
	reviews_auto_response: {
		id: "reviews_auto_response",
		name: i18n.t("Reviews - Auto Respond Reviews")
	},
	reviews_invites_queue: {
		id: "reviews_invites_queue",
		name: i18n.t("Reviews - Invite Queue")
	},
	reviews_widget: {
		id: "reviews_widget",
		name: i18n.t("Reviews - Reviews Widget")
	},
	reviews_embedded_reviews_widget: {
		id: "reviews_embedded_reviews_widget",
		name: i18n.t("Reviews - Embedded Reviews Widget")
	},
	reviews_invites: {
		id: "reviews_invites",
		name: i18n.t("Reviews - Invites")
	},
	reviews_invites_site_rotation: {
		id: "reviews_invites_site_rotation",
		name: i18n.t("Reviews - Invites - Site Rotation")
	},
	messenger: {
		id: "messenger",
		name: i18n.t("Messenger")
	},
	messenger_facebook: {
		id: "messenger_facebook",
		name: i18n.t("Messenger - Facebook Messages")
	},
	messenger_google: {
		id: "messenger_google",
		name: i18n.t("Messenger - Google Messages")
	},
	messenger_scheduled: {
		id: "messenger_scheduled",
		name: i18n.t("Messenger - Scheduled Messages")
	},
	messenger_scheduled_mms: {
		id: "messenger_scheduled_mms",
		name: i18n.t("Messenger - Scheduled Messages MMS")
	},
	messenger_scheduled_general: {
		id: "messenger_scheduled_general",
		name: i18n.t("Messenger - Scheduled Messages - General")
	},
	messenger_scheduled_nps: {
		id: "messenger_scheduled_nps",
		name: i18n.t("Messenger - Scheduled Messages - Nps")
	},
	messenger_scheduled_review_invites: {
		id: "messenger_scheduled_review_invites",
		name: i18n.t("Messenger - Scheduled Messages - Review Invites")
	},
	messenger_scheduled_reengagement: {
		id: "messenger_scheduled_reengagement",
		name: i18n.t("Messenger - Scheduled Messages - Reengagement")
	},
	messenger_voice: {
		id: "messenger_voice",
		name: i18n.t("Messenger - Voice")
	},
	messenger_voice_voicemail: {
		id: "messenger_voice_voicemail",
		name: i18n.t("Messenger - Voice - Voicemail")
	},
	messenger_voice_voicemail_transcription: {
		id: "messenger_voice_voicemail_transcription",
		name: i18n.t("Messenger - Voice - Voicemail Transcription")
	},
	messenger_voice_enhanced: {
		id: "messenger_voice_enhanced",
		name: i18n.t("Messenger - Voice - Enhanced Voice")
	},
	messenger_voice_call_forwarding: {
		id: "messenger_voice_call_forwarding",
		name: i18n.t("Messenger - Voice - Call Forwarding")
	},
	messenger_voice_missed_call_response: {
		id: "messenger_voice_missed_call_response",
		name: i18n.t("Messenger - Voice - Missed Call Response")
	},
	messenger_video_chat: {
		id: "messenger_video_chat",
		name: i18n.t("Messenger - Video Chat")
	},
	messenger_secure_chat: {
		id: "messenger_secure_chat",
		name: i18n.t("Messenger - Secure Chat")
	},
	chatbots: {
		id: "chatbots",
		name: i18n.t("Chatbots")
	},
	nps: {
		id: "nps",
		name: i18n.t("Net Promoter Score")
	},
	nps_auto_requests: {
		id: "nps_auto_requests",
		name: i18n.t("Net Promoter Score - Auto Requests")
	},
	payments: {
		id: "payments",
		name: i18n.t("Payments")
	},
	messenger_smart_inboxes: {
		id: "messenger_smart_inboxes",
		name: i18n.t("Messenger - Smart Inboxes")
	},
	messenger_smart_inboxes_auto_assignment: {
		id: "messenger_smart_inboxes_auto_assignment",
		name: i18n.t("Messenger - Smart Inboxes - Auto Assignment")
	},
	bookings: {
		id: "bookings",
		name: i18n.t("Bookings")
	},
	workflows: {
		id: "workflows",
		name: i18n.t("Workflows")
	},
	workflow_keywords: {
		id: "workflow_keywords",
		name: i18n.t("Workflows - Keywords")
	},
	appointments: {
		id: "appointments",
		name: i18n.t("Appointments")
	},
	appointment_reminders: {
		id: "appointment_reminders",
		name: i18n.t("Appointments - Appointment Reminders")
	},
	appointment_confirmations: {
		id: "appointment_confirmations",
		name: i18n.t("Appointments - Appointment Confirmations")
	},
	dashboard: {
		id: "dashboard",
		name: i18n.t("Dashboard")
	},
	leaderboard: {
		id: "leaderboard",
		name: i18n.t("Leaderboard")
	},
	micro_site: {
		id: "micro_site",
		name: i18n.t("Micro Site")
	},
	check_in: {
		id: "check_in",
		name: i18n.t("Check In")
	},
	referral_auto_requests: {
		id: "referral_auto_requests",
		name: i18n.t("Auto Referral Requests")
	},
	webchat: {
		id: "webchat",
		name: i18n.t("Webchat")
	},
	custom_fields: {
		id: "custom_fields",
		name: i18n.t("Custom Fields")
	},
	hipaa_compliant: {
		id: "hipaa_compliant",
		name: i18n.t("HIPAA Compliant")
	},
	tasks: {
		id: "tasks",
		name: i18n.t("Tasks")
	},
	secure_chat: {
		id: "secure_chat",
		name: i18n.t("Secure Chat")
	},
	calendar: {
		id: "calendar",
		name: i18n.t("Calendar")
	}
};

export const LOCATION_COLUMNS = {
	id: {
		id: "id",
		value: "Id",
		sortable: true,
		sortField: "id",
		width: () =>
			UserService.get().GroupPermission.type === GROUP_PERMISSIONS.super_administrator.type ||
			UserService.get().GroupPermission.type === GROUP_PERMISSIONS.customer_success.type
				? 1
				: 0
	},
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: true,
		sortField: "name",
		width: 3
	},
	address: {
		id: "address",
		value: i18n.t("Address"),
		sortable: false,
		sortField: "address",
		width: 2
	},
	phone: {
		id: "phone",
		value: i18n.t("Phone"),
		sortable: true,
		sortField: "phone",
		width: 1
	},
	actions: {
		id: "actions",
		value: i18n.t("Actions"),
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const TWILIO_A2P_BRAND_STATUSES = {
	pending: "PENDING",
	failed: "FAILED",
	approved: "APPROVED"
};

export const TWILIO_A2P_CAMPAIGN_STATUSES = {
	inProgress: "IN_PROGRESS",
	failed: "FAILED",
	verified: "VERIFIED"
};

export const TWILIO_A2P_CONTACT_POSITIONS = [
	{ value: "", label: "Select Position" },
	{ value: "Director", label: "Director" },
	{ value: "GM", label: "GM" },
	{ value: "VP", label: "VP" },
	{ value: "CEO", label: "CEO" },
	{ value: "General_Counsel", label: "General Counsel" },
	{ value: "CFO", label: "CFO" },
	{ value: "Other", label: "Other" }
];

export const TWILIO_A2P_REGIONS_OF_OPERATION = [
	{ value: "", label: "Select Operating Region" },
	{ value: "USA_AND_CANADA", label: "USA & Canada" },
	{ value: "LATIN_AMERICA", label: "Latin America" },
	{ value: "EUROPE", label: "Europe" },
	{ value: "ASIA", label: "Asia" },
	{ value: "AFRICA", label: "Africa" }
];

export const TWILIO_A2P_BUSINESS_TYPES = [
	{ value: "", label: "Select Ownership Structure" },
	{ value: "Corporation", label: "Corporation" },
	{ value: "Sole Proprietorship", label: "Sole Proprietorship" },
	{ value: "Limited Liability Corporation", label: "Limited Liability Corporation" },
	{ value: "Partnership", label: "Partnership" },
	{ value: "Co-operative", label: "Co-operative" },
	{ value: "Non-profit Corporation", label: "Non-profit Corporation" }
];

export const TWILIO_A2P_BUSINESS_REGISTRATION_NUMBERS = [
	{ value: "", label: "Select Registration Number Identifier" },
	{ value: "EIN", label: "USA: EIN" },
	{ value: "DUNS", label: "USA: DUNS" },
	{ value: "CCN", label: "Canada: CCN" },
	{ value: "CN", label: "Great Britain: CN" },
	{ value: "ACN", label: "Australia: ACN" },
	{ value: "CIN", label: "India: CIN" },
	{ value: "VAT", label: "Estonia: VAT" },
	{ value: "VATRN", label: "Romania: VATRN" },
	{ value: "RN", label: "Israel: RN" },
	{ value: "Other", label: "Other" }
];

export const TWILIO_A2P_BUSINESS_INDUSTRY = [
	{ value: "", label: "Select Industry" },
	{ value: "AUTOMOTIVE", label: "Automotive" },
	{ value: "AGRICULTURE", label: "Agriculture" },
	{ value: "BANKING", label: "Banking" },
	{ value: "CONSUMER", label: "Consumer" },
	{ value: "EDUCATION", label: "Education" },
	{ value: "ENGINEERING", label: "Engineering" },
	{ value: "ENERGY", label: "Energy" },
	{ value: "OIL_AND_GAS", label: "Oil and Gas" },
	{ value: "FAST_MOVING_CONSUMER_GOODS", label: "Fast Moving Consumer Goods" },
	{ value: "FINANCIAL", label: "Financial" },
	{ value: "FINTECH", label: "Fintech" },
	{ value: "FOOD_AND_BEVERAGE", label: "Food and Beverage" },
	{ value: "GOVERNMENT", label: "Government" },
	{ value: "HEALTHCARE", label: "Healthcare" },
	{ value: "HOSPITALITY", label: "Hospitality" },
	{ value: "INSURANCE", label: "Insurance" },
	{ value: "LEGAL", label: "Legal" },
	{ value: "MANUFACTURING", label: "Manufacturing" },
	{ value: "MEDIA", label: "Media" },
	{ value: "ONLINE", label: "Online" },
	{ value: "RAW_MATERIALS", label: "Raw Materials" },
	{ value: "REAL_ESTATE", label: "Real Estate" },
	{ value: "RELIGION", label: "Religion" },
	{ value: "RETAIL", label: "Retail" },
	{ value: "JEWELRY", label: "Jewelry" },
	{ value: "TECHNOLOGY", label: "Technology" },
	{ value: "TELECOMMUNICATIONS", label: "Telecommunications" },
	{ value: "TRANSPORTATION", label: "Transportation" },
	{ value: "TRAVEL", label: "Travel" },
	{ value: "ELECTRONICS", label: "Electronics" },
	{ value: "NOT_FOR_PROFIT", label: "Not For Profit" }
];

export const TWILIO_A2P_COMPANY_TYPE = [
	{ value: "", label: "Select Company Type" },
	{ value: "private", label: "Private" },
	{ value: "public", label: "Public" },
	{ value: "non-profit", label: "Non-profit" },
	{ value: "government", label: "Government" }
];

export const SCHEDULE = {
	types: {
		regularBusinessHours: "regular_business_hours",
		specialBusinessHours: "special_business_hours",
		bookingRequestSlot: "booking_request_slot",
		messagingHours: "messaging_hours",
		reengagementSendTime: "reengagement_send_time"
	}
};

export const BOOKING_BUCKETS = {
	morning: {
		id: "morning",
		value: i18n.t("Morning")
	},
	afternoon: {
		id: "afternoon",
		value: i18n.t("Afternoon")
	},
	evening: {
		id: "evening",
		value: i18n.t("Evening")
	}
};

export const TASK_COLORS = ["#FFFBE7", "#C6F1E8", "#FF7DCF", "#FFB068"];
export const TASK_COLOR_DEFAULT = "#FFFBE7";
export const TASK_STATUS = {
	todo: "todo",
	inProgress: "in_progress",
	done: "done"
};

export const LOCATIONS_MAP_STATUS_OPTIONS = [
	{
		value: "active",
		label: i18n.t("Active")
	},
	{
		value: "all",
		label: i18n.t("All")
	},
	{
		value: "inactive",
		label: i18n.t("Inactive")
	}
];

// Midnight Commander theme from snazzymaps
// https://snazzymaps.com/style/2/midnight-commander
export const LOCATIONS_MAP_MIDNIGHT_COMMANDER = [
	{
		featureType: "all",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#ffffff"
			}
		]
	},
	{
		featureType: "all",
		elementType: "labels.text.stroke",
		stylers: [
			{
				color: "#000000"
			},
			{
				lightness: 13
			}
		]
	},
	{
		featureType: "administrative",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#000000"
			}
		]
	},
	{
		featureType: "administrative",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#144b53"
			},
			{
				lightness: 14
			},
			{
				weight: 1.4
			}
		]
	},
	{
		featureType: "landscape",
		elementType: "all",
		stylers: [
			{
				color: "#08304b"
			}
		]
	},
	{
		featureType: "poi",
		elementType: "geometry",
		stylers: [
			{
				color: "#0c4152"
			},
			{
				lightness: 5
			}
		]
	},
	{
		featureType: "road.highway",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#000000"
			}
		]
	},
	{
		featureType: "road.highway",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#0b434f"
			},
			{
				lightness: 25
			}
		]
	},
	{
		featureType: "road.arterial",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#000000"
			}
		]
	},
	{
		featureType: "road.arterial",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#0b3d51"
			},
			{
				lightness: 16
			}
		]
	},
	{
		featureType: "road.local",
		elementType: "geometry",
		stylers: [
			{
				color: "#000000"
			}
		]
	},
	{
		featureType: "transit",
		elementType: "all",
		stylers: [
			{
				color: "#146474"
			}
		]
	},
	{
		featureType: "water",
		elementType: "all",
		stylers: [
			{
				color: "#021019"
			}
		]
	}
];
