import React from "react";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import { AnalyticsService } from "../../services/AnalyticsService";
import SupportChatService from "../../services/SupportChatService";
import CrmIntegrationsService from "../../services/CrmIntegrationsService";

import withLocation from "../../components/common/WithLocation";
import DashboardCard from "./DashboardCard";
import ConversionSettingsModal from "../Settings/Reengagements/ConversionSettingsModal";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";

class ReengagementRevenue extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			reengagementConversions: 0,
			reengagementRevenue: 0,
			hasCrmIntegrations: false,
			showConversionSettingsModal: false
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	onLocationChanged = () => {
		this.fetchData();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	fetchData = async () => {
		try {
			let locationId = UserService.getActiveLocation().id;
			let result = await AnalyticsService.getTotalConversionsCount({ locationId });
			let crmIntegrations = await CrmIntegrationsService.fetch({
				locationId,
				params: { limit: 1, status: ["active"] }
			});

			// Get average appointment cost stored in the location metadata
			let location = await UserService.getActiveLocation();
			let averageConversionValue = location.average_conversion_value;
			let reengagementConversions = result.reengagementConversions;
			let reengagementRevenue = Math.floor(reengagementConversions * averageConversionValue);

			await this.update({
				reengagementConversions: reengagementConversions,
				reengagementRevenue: reengagementRevenue,
				hasCrmIntegrations: crmIntegrations && crmIntegrations.length > 0 ? true : false
			});
		} catch (error) {
			console.log(error);
		}
	};

	onReengagementsGetStarted = () => {
		let { t } = this.props;
		SupportChatService.showNewMessage(t("Hi, I would like to enable Reengagement features. Would you be able to help?"));
	};

	onOpenConversionSettingsModal = async id => {
		await this.update({ showConversionSettingsModal: true });
	};

	onHideConversionSettingsModal = async resetComponent => {
		await this.update({ showConversionSettingsModal: false });

		if (resetComponent) {
			this.fetchData();
		}
	};
	renderHeader = () => {
		const { cardName, isEnabled } = this.props;
		let { hasCrmIntegrations } = this.state;
		let { t } = this.props;

		return (
			<div className="dashboard__card__header Common__cursor--pointer" onClick={this.onOpenConversionSettingsModal}>
				<div className="dashboard__card__header__icon">
					<Icon.DollarSign size="42" />
				</div>
				<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">
					{cardName ? cardName : t("Reengagement Revenue")} <Icon.Info size={20} data-tip data-for="dashboard-widget-reengagements-revenue-info" />{" "}
				</div>
				<ReactTooltip
					id="dashboard-widget-reengagements-revenue-info"
					className="mb-react-tooltip mb-react-tooltip--medium"
					arrowColor="#333"
					type="info"
					effect="solid"
					place="right"
				>
					{t(
						"Shows the revenue generated through an automated follow-up message to a past customer or client. All appointments scheduled within 45 days of a reengagement message is considered a reengagement conversion. Shows cumulative data for all time."
					)}
					{isEnabled && !isEnabled() ? t(" Requires the Reengagement module to be enabled.") : null}
					{!hasCrmIntegrations ? t(" Requires a CRM Integration to be active.") : null}
					<br />
					{t("Click to update average contract value.")}
				</ReactTooltip>
			</div>
		);
	};

	renderBody = () => {
		let { reengagementRevenue, hasCrmIntegrations, showConversionSettingsModal } = this.state;
		const { isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		if (isEnabled && !isEnabled()) {
			return (
				<div className="dashboard__widget__conversions">
					<div className="mb-button" onClick={this.onReengagementsGetStarted}>
						{t("Get Started")}
					</div>
				</div>
			);
		}

		let content = t("N/A");

		// Only show if there are active crm integrations and there is at least some revenue
		if (hasCrmIntegrations && reengagementRevenue && reengagementRevenue > 0) {
			content = `$ ${reengagementRevenue.toLocaleString()}`;
		}
		if (isPermissible && !isPermissible()) {
			content = t("N/A");
		}

		return (
			<>
				<div className="dashboard__card__stat">
					<div className="dashboard__card__stat__count Common__cursor--pointer" onClick={this.onOpenConversionSettingsModal}>
						{content}
					</div>
				</div>
				<ConversionSettingsModal show={showConversionSettingsModal} onHide={this.onHideConversionSettingsModal} />
			</>
		);
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.generatedRevenueReengagement.className} height={DASHBOARD_CARDS.generatedRevenueReengagement.h}>
				{this.renderHeader()}
				{this.renderBody()}
			</DashboardCard>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(ReengagementRevenue));
