import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import ContentLoader from "react-content-loader";
import { withRouter } from "react-router-dom";

import UserService from "../../services/UserService";
import CrmIntegrationsService from "../../services/CrmIntegrationsService";
import LocationService from "../../services/LocationService";

import Header from "../../components/common/Header";
import Page from "../../components/common/Page";

import "../../styles/css/scenes/account-setup.css";

class AccountSetup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,

			// GMB Related Flags
			isGmbConnected: false,

			// Crm Integration Related Flags
			isCrmIntegrationExpected: false,
			allCrmsActive: false,

			// Widget Related flags
			isWidgetCodeExpected: false,
			isWidgetCodeInstalled: false,
			isNucleusInstalled: false,
			isWebchatInstalled: false,
			isReviewsWidgetInstalled: false
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	fetchData = async () => {
		try {
			await this.update({ loading: true });

			const locationId = UserService.getActiveLocation().id;

			// Crm Integrations enabled
			let crmStatus = await CrmIntegrationsService.crmIntegrationsSetupStatus();
			let isCrmIntegrationExpected = crmStatus.integrationsExpected;
			let allCrmsActive = crmStatus.integrationsActive;

			const messengerIntegrations = await LocationService.hasIntegrations(locationId);

			let data = await LocationService.isWidgetCodeInstalled({ locationId });

			await this.update({
				loading: false,
				// Gmb states
				isGmbConnected: messengerIntegrations.gmb,
				// Crm states
				isCrmIntegrationExpected,
				allCrmsActive,
				// Widget states
				isWidgetCodeExpected: data.isWidgetCodeExpected,
				isWidgetCodeInstalled: data.isWidgetCodeInstalled,
				isNucleusInstalled: data.isNucleusInstalled,
				isWebchatInstalled: data.isWebchatInstalled,
				isReviewsWidgetInstalled: data.isReviewsWidgetInstalled
			});
		} catch (error) {
			console.log(error);
			this.update({
				loading: false
			});
		}
	};

	showAccountSetup = () => {
		const {
			loading,
			isGmbConnected,

			isCrmIntegrationExpected,
			allCrmsActive,

			isWidgetCodeExpected,
			isWidgetCodeInstalled
		} = this.state;

		if (loading) {
			return false;
		}

		// Flags for Gmb Connection
		let isReviewsEnabled = LocationService.isReviewsEnabled();

		if (!isReviewsEnabled && !isCrmIntegrationExpected && !isWidgetCodeExpected) {
			// If nothing is expected to be enabled
			return false;
		}

		if (isGmbConnected && allCrmsActive && isWidgetCodeInstalled) {
			// If everything is enabled correctly
			// The account is set up
			return false;
		}

		return true;
	};

	renderLoading = () => {
		return (
			<Page>
				<Header title="Complete Account Set Up" center={true} />

				<div className="account-setup__loading">
					<ContentLoader viewBox="0 0 400 120" height={120} width={"400"}>
						<rect x="0" y="0" rx="5" ry="5" width="400" height="120" />
					</ContentLoader>
				</div>
			</Page>
		);
	};

	render() {
		const {
			loading,
			isGmbConnected,

			isCrmIntegrationExpected,
			allCrmsActive,

			isWidgetCodeExpected,
			isWidgetCodeInstalled,
			isNucleusInstalled,
			isWebchatInstalled,
			isReviewsWidgetInstalled
		} = this.state;
		let { t } = this.props;

		if (loading) {
			return this.renderLoading();
		}

		// Flags for Gmb Connection
		let isReviewsEnabled = LocationService.isReviewsEnabled();

		// Flags for widget code
		let isWebchatPermissible = LocationService.isWebchatPermissible();
		let isReviewsWidgetPermissible = LocationService.isReviewsWidgetPermissible();
		let isBookingsPermissible = LocationService.isBookingsPermissible();

		if (!this.showAccountSetup()) {
			return null;
		}

		return (
			<Page>
				<Header title={t("Complete Account Set Up")} center={true} />
				<div className="account-setup">
					<div className="account-setup__items">
						{isReviewsEnabled && (
							<div className="account-setup__items__item" onClick={() => this.props.history.push("/settings/connections")}>
								<div className="account-setup__items__item__icon">{isGmbConnected ? <Icon.Check color="#34DF11" /> : <Icon.X color="#e74c3c" />}</div>
								<div className="account-setup__items__item__text">
									{t("GMB Connection")}
									{!isGmbConnected && (
										<>
											<Icon.Info className="account-setup__items__item__text__info" data-tip data-for="rtt-gmb-connection" size="14" />
											<ReactTooltip
												id="rtt-gmb-connection"
												className="mb-react-tooltip mb-react-tooltip--medium text-left"
												arrowColor="#333"
												type="info"
												effect="solid"
												place="bottom"
											>
												{t("The GMB Connection is not completely set up or there has been an error in connecting to GMB.")}
											</ReactTooltip>
										</>
									)}
								</div>
							</div>
						)}
						{isCrmIntegrationExpected && (
							<div className="account-setup__items__item" onClick={() => this.props.history.push("/settings/connections")}>
								<div className="account-setup__items__item__icon">{allCrmsActive ? <Icon.Check color="#34DF11" /> : <Icon.X color="#e74c3c" />}</div>
								<div className="account-setup__items__item__text">
									{t("CRM Integration")}
									{!allCrmsActive && (
										<>
											<Icon.Info className="account-setup__items__item__text__info" data-tip data-for="rtt-crm-integration" size="14" />
											<ReactTooltip
												id="rtt-crm-integration"
												className="mb-react-tooltip mb-react-tooltip--medium text-left"
												arrowColor="#333"
												type="info"
												effect="solid"
												place="bottom"
											>
												fdhs
											</ReactTooltip>
										</>
									)}
								</div>
							</div>
						)}
						{isWidgetCodeExpected && (
							<div className="account-setup__items__item" onClick={() => this.props.history.push("/settings/widgets")}>
								<div className="account-setup__items__item__icon">{isWidgetCodeInstalled ? <Icon.Check color="#34DF11" /> : <Icon.X color="#e74c3c" />}</div>
								<div className="account-setup__items__item__text">
									{t("Widget Code Installation")}{" "}
									{!isWidgetCodeInstalled && (
										<>
											<Icon.Info className="account-setup__items__item__text__info" data-tip data-for="rtt-widget-code-installed" size="14" />
											<ReactTooltip
												id="rtt-widget-code-installed"
												className="mb-react-tooltip mb-react-tooltip--medium text-left"
												arrowColor="#333"
												type="info"
												effect="solid"
												place="bottom"
											>
												{t("Widget code is either not installed or there is an issue with the widget on your wesbite.")} <br /> <hr />
												{isWebchatPermissible && !isWebchatInstalled ? (
													<>
														{t("Webchat widget not detected on your website.")} <br /> <hr />
													</>
												) : null}
												{isReviewsWidgetPermissible && !isReviewsWidgetInstalled ? (
													<>
														{t("Reviews Widget widget not detected on your website.")} <br /> <hr />
													</>
												) : null}
												{isBookingsPermissible && !isNucleusInstalled ? (
													<>
														{t("Booking widget not detected on your website.")} <br /> <hr />
													</>
												) : null}
											</ReactTooltip>
										</>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</Page>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(AccountSetup));
