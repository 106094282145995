import i18n from "../i18n/config";

export const CHATBOT_CONFIG_COLUMNS = {
	name: {
		id: "name",
		value: i18n.t("Name"),
		sortable: true,
		sortField: "name",
		width: 1
	},
	description: {
		id: "description",
		value: i18n.t("Description"),
		sortable: true,
		sortField: "description",
		width: 1
	},
	isActive: {
		id: "isActive",
		value: i18n.t("Is Active"),
		sortable: false,
		sortField: "isActive",
		width: 1,
		notClickable: true
	}
};

export const CHATBOT_FAQ_COLUMNS = {
	question: {
		id: "question",
		value: i18n.t("Question"),
		sortable: true,
		sortField: "question",
		width: 1
	},
	answer: {
		id: "answer",
		value: i18n.t("Answer"),
		sortable: true,
		sortField: "answer",
		width: 1
	},
	actions: {
		id: "actions",
		value: i18n.t("Actions"),
		sortable: false,
		width: 1,
		notClickable: true
	}
};
