import React, { Component } from "react";
import * as Icon from "react-feather";
import ReactSwitch from "react-switch";
import Select from "react-select";
import { withTranslation } from "react-i18next";

import ChatbotService from "../../services/ChatbotService";
import ToastService from "../../services/ToastService";
import UserService from "../../services/UserService";
import List from "../../components/common/List";

import { SORT_ORDER } from "../../constants/CommonConstants";
import { CHATBOT_CONFIG_COLUMNS } from "../../constants/Chatbots";

import "../../styles/css/scenes/chatbot-config.css";
import LocationService from "../../services/LocationService";

import withLocation from "../../components/common/WithLocation";
class ChatbotConfiguration extends Component {
	constructor(props) {
		super(props);
		let { t } = this.props;

		this.state = {
			loading: false,
			loadedAll: false,
			data: [],
			limit: 25,
			pageSize: 50,
			sortField: CHATBOT_CONFIG_COLUMNS.name.id,
			sortOrder: SORT_ORDER.asc,
			activeChatbotOptions: [],

			emptyDefaultChatbot: { label: t("No Default Chatbot"), value: -1 },
			locationId: UserService.getActiveLocation().id,
			defaultChatbot: null
		};
	}

	componentDidMount() {
		this.fetchLocation();
		this.fetchData();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = location => {
		let locationId = UserService.getActiveLocation().id;

		this.setState(
			{
				locationId
			},
			() => {
				this.fetchLocation();
				this.fetchData();
			}
		);
	};

	async fetchData() {
		try {
			let { locationId, emptyDefaultChatbot, sortField, sortOrder, limit } = this.state;
			this.setState({ loading: true });

			let data = await ChatbotService.fetchChatbotLocations({ locationId, sortField, sortOrder, limit });
			let defaultChatbot = null;

			for (let chatbot of data) {
				if (chatbot.isDefault) defaultChatbot = this.convertChatbotToSelectOption(chatbot);
			}

			let activeChatbotOptions = data.filter(chatbot => chatbot.isActive).map(chatbot => this.convertChatbotToSelectOption(chatbot));
			activeChatbotOptions.push(emptyDefaultChatbot);

			this.setState({ data: data, activeChatbotOptions: activeChatbotOptions, defaultChatbot: defaultChatbot, loading: false, loadedAll: data.length < limit });
		} catch (err) {
			console.error(err);
			this.setState({ loading: false });
		}
	}

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchData();
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ loading: true, sortField, sortOrder });
		await this.fetchData();
		await this.update({ loading: false });
	};

	getHeaders = () => {
		let columns = CHATBOT_CONFIG_COLUMNS;

		let headers = {
			items: columns,
			sortBy: this.sortBy
		};

		return headers;
	};

	onRecordClicked = async item => {
		// nothing for now
	};

	renderRecord = recordData => {
		let user = UserService.get();

		try {
			return [
				recordData.name,
				recordData.description,
				<div className="ChatbotConfig__is-permissible">
					<ReactSwitch
						id={`chatbot_${recordData.id}`}
						onChange={e => this.handleChatbotActivation(e, recordData)}
						checked={recordData.isActive}
						onColor="#4A90E2"
						disabled={!user.GroupPermission.update_chatbots}
					/>
				</div>
			];
		} catch (error) {
			console.log(error.message);
		}
		return null;
	};

	handleChatbotActivation = async (isActive, row) => {
		try {
			let { defaultChatbot, data, locationId, emptyDefaultChatbot } = this.state;
			let { t } = this.props;

			if (isActive) {
				ToastService.info(t("{{chatbotName}} activated successfully!", { chatbotName: row.name }));
			} else {
				ToastService.info(t("{{chatbotName}} deactivated successfully!", { chatbotName: row.name }));
			}

			let chatbotId = row.id;
			if (defaultChatbot && chatbotId === defaultChatbot.value && !isActive) {
				this.setState({
					defaultChatbot: null
				});
			}
			for (let chatbot of data) {
				if (chatbot.id === chatbotId) {
					chatbot.isActive = isActive;
					break;
				}
			}
			let activeChatbotOptions = data.filter(chatbot => chatbot.isActive).map(chatbot => this.convertChatbotToSelectOption(chatbot));
			activeChatbotOptions.push(emptyDefaultChatbot);
			await ChatbotService.updateChatbotLocations(chatbotId, locationId);
			this.setState({
				data: data,
				activeChatbotOptions: activeChatbotOptions
			});
		} catch (err) {
			console.error(err);
		}
	};

	convertChatbotToSelectOption(chatbot) {
		return chatbot ? { label: chatbot.name, value: chatbot.id, isActive: chatbot.isActive } : null;
	}

	handleChangeDefaultChatbot = async option => {
		let { t } = this.props;

		try {
			let { locationId } = this.state;
			ToastService.info(t("Successfully set as default chatbot!"));
			let chatbotId = option.value;
			let clearDefault = chatbotId === -1;

			await ChatbotService.updateDefaultChatbot(chatbotId, locationId, clearDefault);
			this.setState({
				defaultChatbot: option
			});
		} catch (err) {
			console.error(err);
		}
	};

	async fetchLocation() {
		let { locationId } = this.state;
		let location = await LocationService.fetchLocation(locationId);
		this.setState({
			location: location
		});
	}

	render() {
		let { data, activeChatbotOptions, defaultChatbot, loading, loadedAll, sortField, sortOrder } = this.state;
		let { t } = this.props;

		let user = UserService.get();

		return (
			<>
				<div className="ChatbotConfig__box-heading">
					<div className="ChatbotConfig__box-heading-text col-sm-6 col-md-8 col-lg-9">
						<h3>{t("Please configure which of our proprietary chatbots you would like to have access to for customer communication")}</h3>
					</div>
					<div className="ChatbotConfig__default-chatbot-selector col-sm-6 col-md-4 col-lg-3">
						<h4>{t("Default Chatbot:")}</h4>
						<Select
							noOptionsMessage={t("Select a default chatbot...")}
							placeholder={t("Select a default chatbot...")}
							value={defaultChatbot}
							onChange={this.handleChangeDefaultChatbot}
							options={activeChatbotOptions}
							isDisabled={!user.GroupPermission.update_chatbots}
						/>
					</div>
				</div>
				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={t("Looks like there are no chatbots configured yet...")}
					noDataIcon={<Icon.AlertCircle />}
				/>
			</>
		);
	}
}

export default withTranslation(null, { forwardRef: true })(withLocation(ChatbotConfiguration));
