import React, { Component } from "react";
import _ from "lodash";
import { withTranslation, Trans } from "react-i18next";

import MessagesService from "../../../../services/MessagesService";

import ResultList from "./ResultList/ResultList";

import "./search-results.css";

class SearchResults extends Component {
	constructor(props) {
		super(props);

		let { searchTerm, locationId } = this.props;

		this.state = {
			locationId: locationId,
			searchTerm: searchTerm,
			messageResults: [],
			conversationResults: [],
			debounce: typeof this.props.debounce !== "undefined" ? this.props.debounce : true
		};

		this.debouncedSearchConversations = _.debounce(
			() => {
				this.searchConversations();
			},
			500,
			{
				leading: true,
				trailing: true
			}
		);

		this.debouncedSearchMessages = _.debounce(
			() => {
				this.searchMessages();
			},
			500,
			{
				leading: true,
				trailing: true
			}
		);
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { searchTerm, locationId } = this.props;

		if (prevProps.searchTerm !== searchTerm || prevProps.locationId !== locationId) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { searchTerm, locationId, debounce } = this.props;

		await this.update({
			searchTerm: searchTerm,
			locationId: locationId
		});

		if (debounce) {
			this.debouncedSearchConversations();
			this.debouncedSearchMessages();
		} else {
			this.searchMessages();
			this.searchConversations();
		}
	}

	async searchMessages() {
		let { searchTerm, locationId } = this.state;
		let messages = await MessagesService.searchMessages(searchTerm, locationId);

		await this.update({
			messageResults: messages
		});
	}

	async searchConversations() {
		let { searchTerm, locationId } = this.state;
		let conversations = await MessagesService.searchConversations({ searchTerm, locationId });

		await this.update({
			conversationResults: conversations
		});
	}

	render() {
		let { onResultClicked } = this.props;
		let { messageResults, conversationResults } = this.state;
		let { t } = this.props;

		return (
			<div className="mb-search-results">
				<ResultList title={t("Messages")} results={messageResults} onResultClicked={onResultClicked} />
				<ResultList title={t("Conversations")} results={conversationResults} onResultClicked={onResultClicked} />
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(SearchResults);
