import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import { withTranslation, Trans } from "react-i18next";

import Spinners from "../../../components/common/Spinners";
import withLocation from "../../../components/common/WithLocation";
import DashboardCard from "../../Dashboard/DashboardCard";

import UserService from "../../../services/UserService";
import { AnalyticsService } from "../../../services/AnalyticsService";
import GmbService from "../../../services/GmbService";

import { REVIEW_SITES } from "../../../constants/ReviewSitesConstants";
import { DASHBOARD_CARDS } from "../../../constants/Dashboard";

import "../../../styles/css/scenes/analytics.css";
import "../../../App.css";

class ReviewsBySite extends Component {
	state = {
		overallRatingsObject: { reviews: [] },
		loading: true,
		fbActive: false,
		gmbActive: false
	};

	componentDidMount() {
		this.resetComponent();
	}

	async resetComponent() {
		try {
			const locationId = UserService.getActiveLocation().id;

			const ratingsAndReviews = await AnalyticsService.getOverallRatingAndReviews({ locationId });
			this.setState({ overallRatingsObject: ratingsAndReviews });

			let data = await GmbService.checkGmbAndFbStatus(locationId);

			this.setState({
				fbActive: data.fb_active,
				gmbActive: data.gmb_active
			});
		} catch (error) {
			console.log(error);
		}
		this.setState({
			loading: false
		});
	}

	onLocationChanged = () => {
		this.resetComponent();
	};

	renderHeader = () => {
		const { cardName } = this.props;

		return (
			<div className="dashboard__card__header dashboard__card__header--full-width">
				<div className="dashboard__card__header__name">{cardName ? cardName : "Reviews by Site"}</div>
			</div>
		);
	};

	renderBody = () => {
		const { overallRatingsObject, loading } = this.state;
		const { isEnabled, isPermissible } = this.props;
		let { t } = this.props;

		if (isEnabled && !isEnabled()) {
			return <div className="text-center">{t("Reviews is not enabled. Contact support to get started.")}</div>;
		}

		if (isPermissible && !isPermissible()) {
			return <div className="text-center">{t("Review data is restricted.")}</div>;
		}

		if (loading) {
			return (
				<div className="analytics__section__chart__spinner">
					<Spinners type="tail-fade" loading={true} size="60px" />
				</div>
			);
		}

		return (
			<div className="analytics__reviews-by-site">
				{overallRatingsObject.reviews.map((aReview, index) => {
					if (aReview.name === REVIEW_SITES.google.name && !this.state.gmbActive) {
						return (
							<div key={index} style={{ position: "relative" }}>
								<div style={{ position: "relative" }}>
									<h3>Google</h3>
									<small className="pull-right Analytics__counts">90</small>

									<div className="progress progress-large">
										<div style={{ width: "98%" }} className="progress-bar progress-bar-success" />
									</div>
								</div>
								<div className="ReviewsSummary__no-connection" style={{ height: 60 }}>
									<span>
										{" "}
										<span style={{ position: "relative", top: 1 }}>
											<Icon.AlertCircle color="red" size="20" />
										</span>{" "}
										{t("Please connect to GMB now to view this data.")}
									</span>
								</div>
							</div>
						);
					}
					if (aReview.name === REVIEW_SITES.facebook.name && !this.state.fbActive) {
						return (
							<div key={index} style={{ position: "relative" }}>
								<div style={{ position: "relative" }}>
									<h3>Facebook</h3>
									<small className="pull-right Analytics__counts">92</small>
									<div className="progress progress-large">
										<div style={{ width: "94%" }} className="progress-bar progress-bar-success" />
									</div>
								</div>
								<div className="ReviewsSummary__no-connection" style={{ height: 60 }}>
									<span>
										{" "}
										<span style={{ position: "relative", top: 1 }}>
											<Icon.AlertCircle color="red" size="20" />
										</span>{" "}
										{t("Please connect to Facebook now to view this data.")}
									</span>
								</div>
							</div>
						);
					}
					return (
						<div key={aReview.name}>
							<div>
								<small className="pull-right Analytics__counts">{aReview.review_count}</small>
								<h3>{aReview.name}</h3>
							</div>
							<div className="progress progress-large">
								<div style={{ width: `${aReview.review_count_ratio}%` }} className="progress-bar progress-bar-success" />
							</div>
						</div>
					);
				})}
			</div>
		);
	};

	render() {
		return (
			<DashboardCard className={DASHBOARD_CARDS.reviewsBySite.className} height={DASHBOARD_CARDS.reviewsBySite.h}>
				{this.renderHeader()}
				{this.renderBody()}
			</DashboardCard>
		);
	}
}

export default withRouter(withTranslation(null, { forwardRef: true })(withLocation(ReviewsBySite)));
